import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import EchartsTheme from 'eflex/echarts-theme';
import { tracked } from '@glimmer/tracking';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import Levels from 'eflex/constants/level-select-levels';
import generateDataForBiTimeChart from 'eflex/util/generate-data-for-bi-time-chart';
import FormatxAxisTimestamps from 'eflex/util/bi-format-xAxis-Timestamps';
import { waitFor } from '@ember/test-waiters';
import { fn, get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import BiLevelSelector from 'eflex/components/bi/level-selector';
export default class QualityTimeBarChartComponent extends Component {
    @service
    queryRunner;
    @service
    intl;
    @tracked
    tagName = '';
    @tracked
    chartData = null;
    @tracked
    selectedLevel = 'Day';
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    // jscpd:ignore-start
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const qualityChartQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $group: {
                    _id: {
                        $dateToString: {
                            format: Levels[this.selectedLevel],
                            date: '$timestamp'
                        }
                    },
                    totalParts: {
                        $sum: 1
                    },
                    rejectCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$status',
                                                TaskStatuses.REJECT_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$status',
                                                TaskStatuses.REJECT_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    },
                    passCount: {
                        $sum: {
                            $cond: {
                                if: {
                                    $and: [
                                        {
                                            $gte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_START
                                            ]
                                        },
                                        {
                                            $lte: [
                                                '$status',
                                                TaskStatuses.GOOD_RANGE_END
                                            ]
                                        }
                                    ]
                                },
                                then: 1,
                                else: 0
                            }
                        }
                    }
                }
            },
            {
                $addFields: {
                    date: '$_id',
                    quality: {
                        $round: [
                            {
                                $multiply: [
                                    {
                                        $divide: [
                                            '$passCount',
                                            '$totalParts'
                                        ]
                                    },
                                    100
                                ]
                            },
                            1
                        ]
                    }
                }
            },
            {
                $sort: {
                    date: 1
                }
            },
            {
                $project: {
                    _id: 0
                }
            }
        ]);
        const qualityChartData1 = await this.queryRunner.runQuery.perform('BuildStatuses', qualityChartQuery1);
        const chartData1 = generateDataForBiTimeChart(qualityChartData1, this.selectedLevel);
        return {
            chartData: this.getEchartOptions(chartData1),
            exportData: ()=>chartData1
        };
    }));
    // jscpd:ignore-end
    qualityData = trackedTask(this, this.getData, ()=>[
            this.params,
            this.selectedLevel
        ]);
    getEchartOptions(qualityChartData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.qualityTime')
            },
            legend: {
                show: true,
                bottom: 5
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: FormatxAxisTimestamps(qualityChartData1, this.selectedLevel),
            yAxis: [
                {
                    type: 'value',
                    max: 100,
                    interval: 10,
                    axisLabel: {
                        formatter: '{value}%'
                    }
                },
                {
                    type: 'value',
                    show: false
                }
            ],
            series: [
                {
                    name: this.intl.t('bi.chartLegend.timePeriodTotalParts'),
                    data: qualityChartData1.map((m1)=>m1.totalParts ?? 0),
                    type: 'line',
                    yAxisIndex: 1,
                    color: EchartsTheme.colorPalette.biBlue
                },
                {
                    name: this.intl.t('bi.chartLegend.timePeriodRejects'),
                    data: qualityChartData1.map((m1)=>m1.rejectCount ?? 0),
                    type: 'line',
                    yAxisIndex: 1,
                    color: EchartsTheme.colorPalette.danger
                },
                {
                    name: this.intl.t('bi.chartLegend.timePeriodQuality'),
                    data: qualityChartData1.map((m1)=>m1.quality ?? 0),
                    type: 'bar',
                    yAxisIndex: 0,
                    color: EchartsTheme.colorPalette.success
                }
            ]
        };
    }
    static{
        template(`
    <div class='component-bi-chart-with-dropdown' ...attributes>
      {{#if this.qualityData.isRunning}}
        <Spinner />
      {{else if (get this.qualityData.value.chartData.series '0.data.length')}}
        <div class='d-flex flex-row-reverse'>
          <BiLevelSelector
            class='pe-5'
            @selectedLevel={{this.selectedLevel}}
            @onSelectedLevel={{fn (mut this.selectedLevel)}}
          />
        </div>
        <div class='d-flex flex-row h-100 chart-row'>
          <div class='col d-flex justify-content-between'>
            <EChart
              @option={{this.qualityData.value.chartData}}
              @exportData={{this.qualityData.value.exportData}}
              class='h-100 w-100 time-quality-chart'
            />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
