import Service, { service } from '@ember/service';
import moment from 'moment-timezone';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class ConfigurationHistoryRepo extends Service {
  @service store;
  @service systemConfig;
  @service currentUser;

  createNewVersion = task(waitFor(async (location, note) => {
    //will include operations later
    if ((location.type) !== 'station') {
      return;
    }

    const configurationHistory = this.store.createRecord('configurationHistory', {
      location: location.asHistory(),
      versionDateString: moment().format('YYYY-MM-DD'),
      user: this.currentUser.user.toFragment(),
      note,
    });

    await configurationHistory.save();
  }));
}
