import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { toTree } from 'eflex/util/tree-helpers';
import { array, fn } from '@ember/helper';
import { t } from 'ember-intl';
import { not, or } from 'ember-truth-helpers';
import TrashCan from 'eflex/components/trash-can';
import TreeSelect from 'eflex/components/tree-select';
import AddButton from 'eflex/components/add-button';
export default class TaskConfigPushToSchedule extends Component {
    @service
    store;
    @service
    areaRepo;
    get nonDeletedPushToScheduleConfigs() {
        return this.args.taskConfig.pushToScheduleConfigs.filter((item1)=>!item1.isDeleted);
    }
    get treeData() {
        return this.areaRepo.areas.map((area1)=>toTree(area1, {
                lastModel: 'station',
                filter (treeItem1) {
                    if (treeItem1.type === 'station') {
                        return treeItem1.productionScheduleEnabled;
                    }
                    return true;
                }
            }));
    }
    addScheduleConfig = ()=>{
        this.store.createRecord('pushToScheduleConfig', {
            taskConfig: this.args.taskConfig
        });
    };
    deleteScheduleConfig = (scheduleConfig1)=>{
        scheduleConfig1.deleteRecord();
    };
    selectStation = (scheduleConfig1, [station1])=>{
        if (!station1) {
            scheduleConfig1.station = null;
            scheduleConfig1.model = null;
            return;
        }
        scheduleConfig1.station = this.store.peekRecord('station', station1.id);
        if (scheduleConfig1.model?.area != scheduleConfig1.station.area) {
            scheduleConfig1.model = scheduleConfig1.station.models[0];
        }
    };
    static{
        template(`
    <div>
      <label class="form-label">
        {{t "task.pushToSchedule.config"}}
      </label>

      <div class="bootstrap-table">
        <div class="row form-row header-row">
          <div class="col model-specific">
            {{t "station"}}
          </div>
          <div class="col model-specific px-0">
            {{t "model"}}
          </div>
          <div class="col model-specific pe-0">
            {{t "target"}}
          </div>
          <div class="col-icon border-0" />
        </div>

        {{#each this.nonDeletedPushToScheduleConfigs as |scheduleConfig|}}
          <div class="row form-row schedule-config">
            <div class="col">
              <@form.element
                @model={{scheduleConfig}}
                @property="station"
              >
                <TreeSelect
                  class="station-select model-specific"
                  @checkable={{false}}
                  @checkedIds={{if scheduleConfig.station (array scheduleConfig.station.id) (array)}}
                  @sourceTree={{this.treeData}}
                  @minDepth={{2}}
                  @onCheckedChanged={{fn this.selectStation scheduleConfig}}
                  @disabled={{@disabled}}
                />
              </@form.element>
            </div>
            <div class="col px-0">
              <@form.element
                class="model-select model-specific"
                @controlType="power-select"
                @options={{scheduleConfig.station.models}}
                @optionLabelPath="displayName"
                @onChange={{fn (mut scheduleConfig.model)}}
                @model={{scheduleConfig}}
                @property="model"
              as |el|>
                <el.control
                  @triggerClass="form-control"
                  @disabled={{or (not scheduleConfig.station) @disabled}}
                />
              </@form.element>
            </div>
            <div class="col pe-0">
              <@form.element
                @controlType="number"
                @model={{scheduleConfig}}
                @property="scheduleTarget"
                class="model-specific"
              />
            </div>
            <div class="col-icon">
              <TrashCan
                disabled={{@disabled}}
                @onDelete={{fn this.deleteScheduleConfig scheduleConfig}}
              />
            </div>
          </div>
        {{/each}}

        <div class="row form-row no-hover-row">
          <div class="col-auto">
            <AddButton
              class='add-schedule-config'
              @onClick={{this.addScheduleConfig}}
              @disabled={{@disabled}}
            >
              {{t "task.pushToSchedule.add"}}
            </AddButton>
          </div>
        </div>

      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
