import Trigger from 'eflex/models/trigger';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from '@eflexsystems/ember-tracked-validations';
import { checkMqttTopicUnique } from 'eflex/util/validators';

@buildValidations({
  mqttTopic: [
    validator('inline', {
      validate(mqttTopic, options, nodeRedTriggerj) {
        return checkMqttTopicUnique(nodeRedTriggerj);
      },
    }),
    validator('presence', true),
  ],
})
class NodeRedTrigger extends Trigger {
  @attr('string') mqttTopic;

  @hasMany('variableMapping', { async: false, inverse: null, embedded: true }) variableMappings;

  get isDirty() {
    return super.isDirty || this.variableMappings.some(item => item.isDirty);
  }

  get variableMappingsInvalid() {
    return anyInvalid(this.variableMappings);
  }

  get isSelfOrChildInvalid() {
    return this.isInvalid || this.variableMappingsInvalid;
  }

  get validatedRelationships() {
    return ['variableMappings'];
  }

  get copyableOptions() {
    const options = super.copyableOptions;
    options.ignoreAttributes.add('variableMappings');
    return options;
  }
}

export default NodeRedTrigger;
