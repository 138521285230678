import { template } from "@ember/template-compiler";
import { serviceHelper as service } from 'eflex/helpers';
import { filter, pipe, collectBy, sortBy, map } from 'ramda';
import { sortByPaths } from 'ramda-adjunct';
import PowerSelect from 'eflex/components/power-select';
import { t } from 'ember-intl';
const SelectedOption = template(`
<span class="ember-power-select-selected-item" ...attributes>
  {{@option.name}}
</span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const matcher = (item1, term1)=>{
    const pattern1 = new RegExp(`${term1}`, 'i');
    if (pattern1.test(item1.name) || pattern1.test(item1.area.name)) {
        return 1;
    } else {
        return -1;
    }
};
const getOptions = (stations1, user1, stationFilter1)=>{
    if (user1.role === 'User') {
        stations1 = stations1.filter((station1)=>station1.jemAuthorizedTags.some((tag1)=>user1.authorizationTags.includes(tag1)));
    }
    // Prevent annoying errors caused by puppeteer and
    // webdriver browsers being open at the same time.
    // Remove after all webdriver tests are converted to puppeteer
    if (window.isIntegrationTest) {
        stations1 = stations1.filter((station1)=>station1.belongsTo('parent').value());
    }
    if (stationFilter1 != null) {
        stations1 = stations1.filter((item1)=>item1[stationFilter1]);
    }
    return pipe(filter((station1)=>!station1.kineticDeleted), collectBy((station1)=>station1.area?.id), sortBy((areaStations1)=>areaStations1[0].area?.order), map((areaStations1)=>({
            groupName: areaStations1[0].area?.name,
            options: pipe(sortByPaths([
                [
                    'group',
                    'order'
                ],
                [
                    'order'
                ]
            ]), filter((station1)=>user1.isAdmin || !station1.hideFromOperator))(areaStations1)
        })))(stations1);
};
export default template(`
<div class="jem-station-dropdown no-chrome-power-select col-auto">
  {{#let
    (service 'currentUser')
    (service 'stationRepo')
  as |currentUser stationRepo|}}
    <PowerSelect
      @dropdownClass="jem-station-dropdown {{@dropdownClass}}"
      @triggerClass="
        station-dropdown
        bg-transparent
        form-control
        form-control-lg
        border-0
        shadow-none
        fw-bold
        d-flex
        align-items-center
        p-0
      "
      @onChange={{@onChange}}
      @options={{getOptions stationRepo.stations currentUser.user @filter}}
      @selected={{@selected}}
      @placeholder={{t "tree.selectLocation"}}
      @selectedItemComponent={{SelectedOption}}
      @searchEnabled={{true}}
      @matcher={{matcher}}
      ...attributes
    as |opt|>
      <div class="tree-item {{opt.type}}">
        <div class="tree-icon tree-automaticstation-icon"></div>
        <span>
          {{opt.name}}
        </span>
      </div>
    </PowerSelect>
  {{/let}}
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
