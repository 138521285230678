import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { without } from 'ramda';
import MarriageStatuses from 'eflex/constants/part-marriage-statuses';
import TaskStatuses from 'eflex/constants/task-statuses';
import { t } from 'ember-intl';
import { formatDate } from 'eflex/helpers';
import { on } from '@ember/modifier';
import SquareButton from 'eflex/components/square-button';
import StandardModal from 'eflex/components/standard-modal';
import SidePanel from 'eflex/components/side-panel';
import { fn } from '@ember/helper';
export default class RepairPanel extends Component {
    @service
    store;
    @service
    currentUser;
    @service
    intl;
    @tracked
    showConfirmModal = false;
    @tracked
    divorceChild = false;
    @tracked
    containChild = false;
    @tracked
    scrapChild = false;
    get actionsDisabled() {
        return this.args.childStatus.isDivorced;
    }
    get confirmModalText() {
        if (this.divorceChild && !this.containChild && !this.scrap) {
            return this.intl.t('repair.divorceChildConfirm');
        } else if (this.divorceChild && this.containChild && !this.scrap) {
            return this.intl.t('repair.containChildConfirm');
        } else if (this.divorceChild && this.containChild && this.scrap) {
            return this.intl.t('repair.scrapChildConfirm');
        } else {
            return null;
        }
    }
    commitMarriageRepair = task(waitFor(async ()=>{
        const childStatus1 = this.args.childStatus;
        const scannedBarcode1 = childStatus1.scannedBarcode;
        const buildStatus1 = childStatus1.parent;
        const userName1 = this.currentUser.user.userName;
        if (this.divorceChild || this.scrapChild) {
            buildStatus1.marriedSerialNumbers = without([
                scannedBarcode1
            ], buildStatus1.marriedSerialNumbers);
            buildStatus1.status = TaskStatuses.NEEDS_REWORK;
            const newMarriageStatus1 = MarriageStatuses.createStatusRecord(userName1, Date.now(), scannedBarcode1, MarriageStatuses.DIVORCED);
            childStatus1.marriageStatuses = [
                ...childStatus1.marriageStatuses,
                newMarriageStatus1
            ];
            childStatus1.status = TaskStatuses.NEEDS_REWORK;
        }
        if (this.containChild) {
            const jemContainment1 = this.store.createRecord('jemContainment', {
                serialNumber: scannedBarcode1,
                userName: userName1,
                scrap: this.scrapChild
            });
            await jemContainment1.save();
        }
        await buildStatus1.save();
        await this.args.onClose?.();
    }));
    divorceChildAction = ()=>{
        this.divorceChild = true;
        this.showConfirmModal = true;
    };
    containChildAction = ()=>{
        this.divorceChild = true;
        this.containChild = true;
        this.showConfirmModal = true;
    };
    scrapChildAction = ()=>{
        this.divorceChild = true;
        this.containChild = true;
        this.scrapChild = true;
        this.showConfirmModal = true;
    };
    static{
        template(`
    <SidePanel
      @header={{t "repair"}}
      @onClose={{@onClose}}
      class="repair-panel"
      ...attributes
    >
      <:default>
        <div class="h-100 d-flex flex-column p-3">
          <div class="m-3">
            <div class="row mb-2">
              <div class="col">
                <label class="form-label">{{t "station"}} / {{t "task"}}</label>
                <div class="d-flex fw-bold">
                  <div class="station-name me-1">{{@buildStatus.station.name}}</div>
                  /
                  <div class="task-name ms-1 d-flex">
                    <div class="task-icon d-inline-block me-1 {{@childStatus.iconClass}}"></div>
                    {{@childStatus.taskName}}
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-4">
                <label class="form-label">{{t "taskStatus"}}</label>
                <div class="task-status fw-bold {{@childStatus.taskStatusClass}}">
                  {{@childStatus.statusTranslation}}
                </div>
              </div>
              <div class="col-4">
                <label class="form-label">{{t "marriageStatus"}}</label>
                <div
                  class="
                    marriage-status
                    fw-bold
                    {{if @childStatus.formattedMarriage.successful "success" "danger"}}
                  ">
                  {{t @childStatus.formattedMarriage.statusText}}
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-4">
                <label class="form-label">{{t "childSerialNumber"}}</label>
                <div class="child-serial fw-bold">
                  {{@childStatus.scannedBarcode}}
                </div>
              </div>

              <div class="col-4">
                <label class="form-label">{{t "user"}}</label>
                <div class="username fw-bold">
                  {{@childStatus.buildStatus.userName}}
                </div>
              </div>

              <div class="col-4">
                <label class="form-label">{{t "datetime"}}</label>
                <div class="date-time fw-bold">
                  {{formatDate @childStatus.timestamp "MM-DD-YY" "hh:mma"}}
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-4">
                <label class="form-label">{{t "plant.task.barcode.marriageLabel"}}</label>
                <div class="marriage-label fw-bold">
                  {{#if @childStatus.marriageLabel}}
                    {{@childStatus.marriageLabel}}
                  {{else}}
                    {{t "noValue"}}
                  {{/if}}
                </div>
              </div>
            </div>
          </div>

          <div class="marriage-actions p-3 flex-grow-1 background-gray-100">
            <div>
              <label class="form-label">{{t "marriageActions"}}</label>
              <div class="mt-1">
                <button
                  class="divorce-child btn btn-outline-primary btn-sm fw-bold"
                  type="button"
                  disabled={{this.actionsDisabled}}
                  {{on "click" this.divorceChildAction}}
                >
                  {{t "divorceChild"}}
                </button>

                <button
                  class="contain-child btn btn-outline-primary btn-sm fw-bold"
                  type="button"
                  disabled={{this.actionsDisabled}}
                  {{on "click" this.containChildAction}}
                >
                  {{t "containChild"}}
                </button>

                <button
                  class="scrap-child btn btn-outline-primary btn-sm fw-bold"
                  type="button"
                  disabled={{this.actionsDisabled}}
                  {{on "click" this.scrapChildAction}}
                >
                  {{t "scrapChild"}}
                </button>
              </div>
            </div>
          </div>

        </div>
      </:default>

      <:footer>
        <SquareButton @onClick={{@onClose}}>
          {{t "cancel"}}
        </SquareButton>
      </:footer>
    </SidePanel>

    {{#if this.showConfirmModal}}
      <StandardModal
        class="repair-panel-confirm-modal"
        @size="sm"
        @header={{t "areyousure"}}
        @text={{this.confirmModalText}}
        @onCancel={{fn (mut this.showConfirmModal) false}}
        @onConfirm={{this.commitMarriageRepair.perform}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
