import { guidFor } from '@ember/object/internals';
import { getOwner } from '@ember/application';
import { clone } from 'ramda';

const DEFAULT_OPTIONS = {
  ignoreAttributes: new Set(),
  copyByReference: new Set(),
  overwrite: {},
  relationships: {},
};

function copy(obj, deep, options, copies) {
  options = Object.assign({}, DEFAULT_OPTIONS, obj.copyableOptions, options);
  const {
    ignoreAttributes,
    copyByReference,
    overwrite,
  } = options;
  const guid = guidFor(obj);

  // Handle cyclic relationships: If the model has already been copied, just return that model
  if (copies.has(guid)) {
    return copies.get(guid);
  }

  const store = getOwner(obj).lookup('service:store');
  const model = store.createRecord(obj.constructor.modelName, {});
  copies.set(guid, model);

  obj.eachAttribute(name => {
    if (overwrite[name] !== undefined) {
      model[name] = overwrite[name];
      return;
    }

    if (ignoreAttributes.has(name)) {
      return;
    }

    model[name] = clone(obj[name]);
  });


  obj.eachRelationship((name, meta) => {
    if (overwrite[name] !== undefined) {
      model[name] = overwrite[name];
      return;
    }

    if (ignoreAttributes.has(name)) {
      return;
    }

    if (!deep || copyByReference.has(name)) {
      model[name] = obj[name];
      return;
    }

    const value = obj[name];
    const relOptions = options.relationships[name];
    const deepRel =
      relOptions && typeof relOptions.deep === 'boolean'
        ? relOptions.deep
        : deep;

    if (meta.kind === 'belongsTo') {
      if (value && value.__isCopyable) {
        model[name] = copy(value, deepRel, relOptions, copies);
      } else if (model[name] !== value) {
        model[name] = value;
      }
    } else if (meta.kind === 'hasMany') {
      const firstObject = value[0];

      if (firstObject && firstObject.__isCopyable) {
        model[name] = value.map(val => copy(val, deepRel, relOptions, copies));
      } else {
        model[name] = value;
      }
    }
  });

  return model;
}

export default function Copyable(DecoratedClass) {
  DecoratedClass.prototype._copy = function(deep, options) {
    const copies = new Map();

    try {
      return copy(this, deep, options, copies);
    } catch (e) {
      copies.forEach(record => {
        record.unloadRecord();
      });
      throw e;
    }
  };

  DecoratedClass.prototype.copy ??= function(deep, options) {
    return this._copy(deep, options);
  };

  Object.defineProperty(DecoratedClass.prototype, '__isCopyable', {
    configurable: false,
    get() {
      return true;
    },
  });
}
