import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';

export default class KineticEmployeeRepo extends Service {
  @service store;

  getEmployee = task(waitFor(async (employeeId) => {
    return await this.store.queryRecord('kineticEmployee', { employeeId });
  }));

  updateCurrentShift = task(waitFor(async (employee, shift) => {
    if (employee.currentShift === shift) {
      return;
    }

    employee.currentShift = shift;

    if (shift) {
      employee.clockInTime = new Date();
    } else {
      employee.clockInTime = null;
    }

    await employee.save();
  }));

}
