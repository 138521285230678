import { without } from 'ramda';

export const hardwareTypes = {
  adamIo: 'adam-io',
  audio: 'audio',
  cognexCamera: 'cognex-camera',
  datalogic: 'datalogic',
  edhr: 'edhr',
  keyence: 'keyence',
  lightGuide: 'light-guide',
  nodeRed: 'node-red',
  openProtocolTool: 'open-protocol-tool',
  plcHardware: 'plc-hardware',
  turckIo: 'turck-io',
  universalRobot: 'universal-robot',
  webCam: 'web-cam',
  workInstructionHardware: 'work-instruction-hardware',
  zebraPrinter: 'zebra-printer',
};

export const ASSIGNABLE_HARDWARE_TYPES = without(
  [
    hardwareTypes.audio,
    hardwareTypes.workInstructionHardware,
  ],
  Object.values(hardwareTypes),
);

export const BARCODE_HARDWARE = new Set([
  hardwareTypes.plcHardware,
  hardwareTypes.keyence,
  hardwareTypes.nodeRed,
  hardwareTypes.datalogic,
]);

export const ALLOWED_PARALLEL_HARDWARE = new Set([
  hardwareTypes.nodeRed,
]);
