import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { processDataReports } from 'eflex/constants/bi-report-options';
import { isEmpty } from '@ember/utils';

export default class BiProcessDataController extends Controller {
  // jscpd:ignore-start
  @service queryRunner;
  @service intl;

  @tracked drawerCollapsed = false;
  @tracked queryParams = ['searchTerm', 'isNumericSearch', 'beginDate', 'endDate'];
  @tracked searchTerm;
  @tracked beginDate = moment().startOf('day').toISOString();
  @tracked endDate = moment().endOf('day').toISOString();
  @tracked selectedToken;
  @tracked isNumericSearch = false;
  selectedReport = processDataReports.processDataLookup;
  rollingSearchTerm;

  get reportOptions() {
    return Object.values(processDataReports);
  }

  radioOptions = [
    {
      label: 'stringSearch',
      value: false,
    },
    {
      label: 'numericSearch',
      value: true,
    },
  ];

  @action
  applySearchTerm() {
    if (!isEmpty(this.rollingSearchTerm)) {
      this.searchTerm = this.rollingSearchTerm;
    }
  }

  @action
  resetCriteria() {
    Object.assign(this, {
      searchTerm: '',
      isNumericSearch: false,
      beginDate: moment().startOf('day').toISOString(),
      endDate: moment().endOf('day').toISOString(),
    });
  }

  // jscpd:ignore-end
}
