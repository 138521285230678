import Service from '@ember/service';
import config from 'eflex/config/environment';

export default class VersionService extends Service {
  get realVersion() {
    return config.APP.version;
  }

  getVersion() {
    return '8.0.24.2-2347';
  }
}
