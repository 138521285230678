import Service from '@ember/service';
import Evented from '@ember/object/evented';
import { task, waitForEvent } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ResizeListener extends Service.extend(Evented) {
  @tracked navBarCollapsed = false;

  start = task({ onState: null }, async () => {
    if (window.isTesting) {
      return;
    }

    while (true) {
      await waitForEvent(window, 'resize');
      this.trigger('resized');
    }
  });

  triggerResize = () => {
    this.trigger('resized');
  };

  openNavBar = () => {
    this.navBarCollapsed = false;
  };

  collapseNavBar = () => {
    this.navBarCollapsed = true;
  };
}
