import Controller from '@ember/controller';
import { service } from '@ember/service';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { slice, pipe, sort, prop, descend } from 'ramda';

export default class WorkInstructionsIndexController extends Controller {
  @service workInstructionRepo;
  @service router;

  @tracked currentDrawerTab = 'library';
  @tracked showGetStartedModal = false;
  @tracked currentLibraryView = WieSettings.libraryViews.THUMB;
  @tracked downloadQueue = [];
  @tracked editorControlsCollapsed = false;
  @tracked selectedFolder;

  getStartedSizeCards = [
    {
      title: 'workInstructionEditor.ratio.43',
      width: 1024,
      height: 768,
      type: WieSettings.getStartedTypes.FIXED,
    },
    {
      title: 'workInstructionEditor.ratio.169',
      width: 1366,
      height: 768,
      type: WieSettings.getStartedTypes.FIXED,
    },
    {
      title: 'workInstructionEditor.ratio.custom',
      type: WieSettings.getStartedTypes.CUSTOM,
    },
  ];

  get allWorkInstructions() {
    return this.workInstructionRepo.workInstructions;
  }

  get isListView() {
    return this.currentLibraryView === WieSettings.libraryViews.LIST;
  }

  get isApprovalView() {
    return this.currentLibraryView === WieSettings.libraryViews.APPROVAL;
  }

  get recentFiles() {
    return pipe(
      sort(descend(prop('updatedAt'))),
      slice(0, 9),
    )(this.allWorkInstructions);
  }

  get canCreate() {
    return this.workInstructionRepo.userCanCreate(this.selectedFolder);
  }

  get canCreateFolder() {
    return this.workInstructionRepo.userHasRootFolderEditor();
  }

  // jscpd:ignore-start
  @action
  onDeleteWorkInstructions(currentWorkInstructionIsDeleted) {
    if (!currentWorkInstructionIsDeleted) {
      return;
    }

    this.router.transitionTo('workInstructions');
  }

  downloadInstructions = task(waitFor(async fileType => {
    await this.workInstructionRepo.downloadInstructions.perform(this.downloadQueue, fileType);
  }));

  @action
  createWorkInstruction(params) {
    params.folder = this.selectedFolder;
    const workInstruction = this.workInstructionRepo.createWorkInstruction(params);
    this.router.transitionTo('workInstructions.workInstruction', workInstruction);
  }

  @action
  loadWorkInstruction(workInstruction) {
    this.router.transitionTo('workInstructions.workInstruction', workInstruction.id);
  }

  @action
  onPanelTabActive(type) {
    this.currentDrawerTab = type;
    if (type === 'tool-properties' || type === 'files') {
      this.currentLibraryView = WieSettings.libraryViews.THUMB;
    }
  }
  // jscpd:ignore-end
}
