import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { serviceHelper as service } from 'eflex/helpers';
import { not } from 'ember-truth-helpers';
import { concat, get } from '@ember/helper';
import { camelize } from '@ember/string';
export default template(`
<div class="component-licensing-display col-4 col-md-3" ...attributes>
  <div class="fw-semibold mb-3">
    {{@groupName}}
  </div>
  <div class="mb-3">
    <div class="row form-row header-row">
      <div class="col">
        {{t "licensing.productsFeatures"}}
      </div>
    </div>
    {{#let (service "licensing") as |licensing|}}
      {{#each @options as |option|}}
        <div class="row form-row">
          <div class="col">
            <div
              data-feature-key={{option.key}}
              class="
                license-feature
                {{if
                  (not
                    (get
                      licensing.license
                      (concat
                        (camelize option.key)
                        "Licensed")
                      )
                    )
                    "disabled opacity-50"
                }}
              "
            >
              {{option.label}}
            </div>
          </div>
        </div>
      {{/each}}
    {{/let}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
