import AuthenticatedRoute from 'eflex/util/authenticated-route';

export default class HardwareRoute extends AuthenticatedRoute {
  resetController(controller) {
    Object.assign(controller, {
      selectedHardwareId: null,
      hardwareToDelete: null,
      filterBy: null,
      showHardwarePanel: false,
    });
  }
}
