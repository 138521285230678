import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { all, task, timeout } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import OEE_STATES from 'eflex/constants/oee-states';
import moment from 'moment-timezone';
import { formatStateColumnData } from 'eflex/util/bi-format-oee-state-cell';
import { tracked } from '@glimmer/tracking';
import TaskStatuses from 'eflex/constants/task-statuses';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import TabulatorTable from 'eflex/components/tabulator-table';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
const REFRESH_INTERVAL = 15_000;
// jscpd:ignore-start
export default class AndonStationSummary extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    store;
    @service
    stationRepo;
    @service
    licensing;
    @tracked
    timerToken;
    startAutoRefresh = task({
        drop: true
    }, waitFor(async ()=>{
        if (window.isTesting || window.isIntegrationTest) {
            return;
        }
        while(true){
            await timeout(REFRESH_INTERVAL);
            this.timerToken = true;
        }
    }));
    get params() {
        return {
            stationIds: this.args.stationIds
        };
    }
    // jscpd:ignore-end
    get tableConfiguration() {
        return {
            columns: [
                {
                    title: this.intl.t('station'),
                    field: 'station',
                    tooltip: false,
                    headerSort: false,
                    formatter: 'textarea'
                },
                {
                    title: this.intl.t('bi.chartLabel.operator'),
                    field: 'operator',
                    tooltip: false,
                    headerSort: false,
                    formatter: 'textarea'
                },
                {
                    title: this.intl.t('model'),
                    field: 'model',
                    tooltip: false,
                    headerSort: false,
                    formatter: 'textarea'
                },
                {
                    title: this.intl.t('bi.chartLabel.oeeState'),
                    field: 'state',
                    tooltip: false,
                    headerSort: false,
                    formatter: (cell1)=>formatStateColumnData(cell1),
                    width: 100
                },
                {
                    title: this.intl.t('bi.chartLabel.oeeStateDuration'),
                    field: 'oeeStateDuration',
                    tooltip: false,
                    headerSort: false,
                    formatter: (cell1)=>this.formatStateDuration(cell1),
                    maxWidth: 185
                },
                {
                    title: this.intl.t('bi.chartLabel.faults'),
                    field: 'faults',
                    tooltip: false,
                    headerSort: false,
                    formatter: 'textarea'
                },
                {
                    title: this.intl.t('bi.chartLabel.currentStationState'),
                    field: 'stationState',
                    tooltip: false,
                    headerSort: false,
                    minWidth: 100
                },
                {
                    title: this.intl.t('bi.chartLabel.stationCycleTime'),
                    field: 'stationCycleTime',
                    tooltip: false,
                    headerSort: false,
                    formatter: (cell1)=>this.formatCycleTime(cell1),
                    minWidth: 125
                }
            ]
        };
    }
    getOeeStateData = task({
        restartable: true
    }, waitFor(async ()=>{
        const oeeStatesQuery1 = [
            {
                $match: {
                    endDate: null,
                    state: {
                        $in: Object.values(OEE_STATES.STATES)
                    }
                }
            },
            {
                $group: {
                    _id: '$station._id',
                    station: {
                        $first: '$station'
                    },
                    state: {
                        $first: '$state'
                    },
                    customButtonColor: {
                        $first: '$customButtonColor'
                    },
                    faultCodes: {
                        $first: '$faultCodes'
                    },
                    totalTimeInState: {
                        $sum: {
                            $dateDiff: {
                                startDate: '$startDate',
                                endDate: '$$NOW',
                                unit: 'millisecond'
                            }
                        }
                    }
                }
            }
        ];
        const [oeeStates1, liveBuildStatuses1] = await all([
            this.queryRunner.runQuery.perform('OeeStates', oeeStatesQuery1),
            this.queryRunner.runQuery.perform('LiveBuildStatuses', [])
        ]);
        const stations1 = this.params.stationIds.length > 0 ? this.params.stationIds.map((stationId1)=>this.store.peekRecord('station', stationId1)) : this.stationRepo.stations;
        return stations1.map((station1)=>{
            const liveBuildStatus1 = liveBuildStatuses1.filter((item1)=>item1.status !== TaskStatuses.STOPPED).find((item1)=>item1.location?._id === station1.id);
            const oeeState1 = this.licensing.license.oeeEnabled ? oeeStates1?.find((item1)=>item1.station?._id === station1.id) : null;
            const stationCycleTime1 = moment.duration(moment().diff(moment(liveBuildStatus1?.timestamp), 'milliseconds'));
            return {
                station: station1.name,
                operator: liveBuildStatus1?.userName,
                model: liveBuildStatus1?.model?.code,
                state: oeeState1,
                oeeStateDuration: moment.duration(oeeState1?.totalTimeInState),
                faults: oeeState1?.faultCodes?.codes?.map((faultCode1)=>`${faultCode1.code} - ${faultCode1.description}`).join(', '),
                stationState: this.setStationState(liveBuildStatus1),
                stationCycleTime: stationCycleTime1,
                targetCycleTime: liveBuildStatus1?.totalTargetCycleTime * 1000
            };
        }).toSorted((a1, b1)=>a1.station.localeCompare(b1.station));
    }));
    oeeStateAndLiveBuildStatusData = trackedTask(this, this.getOeeStateData, ()=>[
            this.params,
            this.timerToken
        ]);
    formatStateDuration(cell1) {
        const { state: state1, oeeStateDuration: oeeStateDuration1 } = cell1.getData();
        Object.assign(cell1.getElement().style, {
            whiteSpace: 'pre-wrap'
        });
        // render nothing instead of 0.0s
        return state1 ? oeeStateDuration1.format('d[d] h[h] m[m] s[s]', {
            precision: 1,
            trim: 'both mid'
        }) : null;
    }
    formatCycleTime(cell1) {
        const cellData1 = cell1.getData();
        const { stationCycleTime: stationCycleTime1, targetCycleTime: targetCycleTime1 } = cellData1;
        // render nothing instead of 0.0s
        if (!stationCycleTime1._milliseconds) {
            return null;
        }
        if (targetCycleTime1 && stationCycleTime1._milliseconds > targetCycleTime1) {
            Object.assign(cell1.getElement().style, {
                color: echartsTheme.colorPalette.danger,
                fontWeight: 'bolder',
                whiteSpace: 'pre-wrap'
            });
        }
        return stationCycleTime1.format('d[d] h[h] m[m] s[s]', {
            precision: 1,
            trim: 'both mid'
        });
    }
    setStationState(liveBuildStatus1) {
        if (liveBuildStatus1 && liveBuildStatus1.status !== TaskStatuses.STOPPED) {
            return this.intl.t(`status.${liveBuildStatus1.status}`);
        } else {
            return this.intl.t('idle');
        }
    }
    static{
        template(`
    {{#if this.oeeStateAndLiveBuildStatusData.value}}
      <TabulatorTable
        class="bi-tabulator component-bi-andon-station-summary d-flex flex-column h-100"
        @tableConfiguration={{this.tableConfiguration}}
        @data={{this.oeeStateAndLiveBuildStatusData.value}}
        {{didInsert this.startAutoRefresh.perform}}
        ...attributes
      />
    {{else if this.oeeStateAndLiveBuildStatusData.isRunning}}
      <Spinner />
    {{else}}
      <BiNoDataPlaceholder />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
