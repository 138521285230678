import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { append, findBy } from '@eflexsystems/ember-composable-helpers';
import { add } from 'ramda';
import PowerSelectWithCreate from 'eflex/components/power-select-with-create';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { fn } from '@ember/helper';
export default class TaskConfigLightguide extends Component {
    @service
    variableDefRepo;
    @service
    taskRepo;
    get variables() {
        return this.args.task?.variableDefs?.filter((item1)=>!item1.isDeleted);
    }
    get filteredJemProcessDataDefs() {
        return this.allPreviousTasks?.flatMap((_task1)=>_task1.jemProcessDataDefs).filter((item1)=>!item1.isDeleted) ?? [];
    }
    get filteredVariableDefs() {
        return this.allPreviousTasks?.flatMap((_task1)=>_task1.variableDefs).filter((def1)=>!def1.isDeleted && def1.isFromDevice) ?? [];
    }
    get allPreviousTasks() {
        return this.taskRepo.getAllPreviousTasks(this.args.task);
    }
    setVariableValue = (variableDefConfig1, value1)=>{
        this.variableDefRepo.setVariableValue(variableDefConfig1, value1);
    };
    addMultipleVariables = (variableQuantity1)=>{
        for(let i1 = 0; i1 < variableQuantity1; i1++){
            this.variableDefRepo.createVariableDef({
                task: this.args.task
            });
        }
    };
    deleteVariable = (variableDef1)=>{
        this.variableDefRepo.deleteVariable(variableDef1);
    };
    static{
        template(`
    <div class="row form-row model-specific">
      <div class="col">
        <@form.element
          class="program-name"
          @label={{t "program"}}
          @property="programName"
        />
      </div>
      <div class="col">
        <@form.element
          class="light-guide-watch-variable"
          @label={{t "watchVariable"}}
          @property="lightGuideWatchVariable"
        />
      </div>
      <div class="col">
        <@form.element
          class="light-guide-watch-variable-expected"
          @label={{t "watchVariableExpected"}}
          @property="lightGuideWatchVariableExpected"
        />
      </div>
    </div>
    <div class="bootstrap-table">
      <div class="row form-row header-row">
        <div class="col-icon border-0" />
        <div class="col-icon border-0">
          <FaIcon @icon="hashtag" @prefix="fas" />
        </div>
        <div class="col">
          {{t "variableName"}}
        </div>
        <div class="col model-specific">
          {{t "variableValue"}}
        </div>
        <div class="col-icon border-0" />
      </div>
      {{#each this.variables as |variable index|}}
        {{#let
          (findBy
            "variableDef" variable @taskConfig.variableDefConfigs
          ) as |variableDefConfig|
        }}
          <div class="row form-row variable-row">
            <div class="col-icon model-specific">
              <@form.element
                @controlType="checkbox"
                @model={{variableDefConfig}}
                @property="enabled"
              />
            </div>
            <div class="col-icon ms-auto">
              <span>
                {{add index 1}}
              </span>
            </div>
            <div class="col">
              <@form.element
                @controlType="text"
                @model={{variable}}
                @property="name"
                class="variable-name"
              />
            </div>
            <div class="col model-specific">
              <@form.element>
                <PowerSelectWithCreate
                  @triggerClass="form-control"
                  @options={{
                    append
                    this.filteredVariableDefs
                    this.filteredJemProcessDataDefs
                    variableDefConfig.formattedValue
                  }}
                  @selected={{variableDefConfig.selectedValue}}
                  @searchEnabled={{true}}
                  @searchField="name"
                  @disabled={{@disabled}}
                  @onCreate={{fn this.setVariableValue variableDefConfig}}
                  @onChange={{fn this.setVariableValue variableDefConfig}}
                  class="variable-value" as |value|
                >
                  <span title={{value.task.parent.name}} class="w-100">
                    {{value.name}}
                  </span>
                </PowerSelectWithCreate>
              </@form.element>
            </div>
            <div class="col-icon ms-auto">
              <TrashCan
                disabled={{@disabled}}
                @onDelete={{fn this.deleteVariable variable}}
              />
            </div>
          </div>
        {{/let}}
      {{/each}}
      <div class="row form-row no-hover-row">
        <div class="col-auto">
          <QuantitySelect
            class="px-2 text-uppercase"
            @disabled={{@disabled}}
            @onSubmit={{this.addMultipleVariables}}
          >
            {{t "components.addVariable"}}
          </QuantitySelect>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
