import Service, { service } from '@ember/service';
import { task, taskGroup } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { taskTypes } from 'eflex/constants/tasks/task-types';
import { waitFor } from '@ember/test-waiters';
import { clone, flow, head, prop, reject } from 'ramda';
import { sortByProp } from 'ramda-adjunct';
import { isBlank, isEmpty } from '@ember/utils';
import { STATION_LOAD_OPTIONS, usesBomSourceLookup, generatesSerialNumber } from 'eflex/constants/station-options';

export default class JemRepoService extends Service {
  @service store;
  @service currentUser;
  @service eflexAjax;
  @service fileUploader;
  @service systemConfig;
  @service notifier;
  @service taskConfigRepo;

  @taskGroup({ enqueue: true }) liveStatusEvents;

  saveLiveStatus = task({ group: 'liveStatusEvents' }, waitFor(async liveStatus => {
    await liveStatus.save();
  }));

  toggleHold = task(
    { group: 'liveStatusEvents' },
    waitFor(async (buildStatus, taskConfig) => {
      if (!buildStatus || !taskConfig) {
        return;
      }

      await this.eflexAjax.post.perform('jem/holds', {
        liveBuildStatus: buildStatus.id,
        taskConfig: taskConfig.id,
      });
    }),
  );

  pushFromWebSocket = task({ group: 'liveStatusEvents' }, waitFor(async status => {
    // covers auto load cases where we are unable to side load this relationship
    if (status.buildDatum != null && this.store.peekRecord('buildDatum', status.buildDatum) == null) {
      await this.store.findRecord('buildDatum', status.buildDatum);
    }

    if (status.id) {
      const existing = this.store.peekRecord('liveBuildStatus', status.id);
      if (existing) {
        existing.rollbackAttributes();
      }
    }
    return this.store.push(this.store.normalize('liveBuildStatus', status));
  }));

  uploadWebcamImage = task(
    { drop: true },
    waitFor(async (serialNumber, capturedWebcamImage, treeTask, status, stationFacsId) => {
      return await this.fileUploader.post.perform(
        'webcamImages',
        new File(
          [capturedWebcamImage],
          `${this._getVisionFilename(serialNumber, treeTask, status, stationFacsId)}.jpg`,
        ),
        { serialNumber },
      );
    }),
  );

  loadTaskConfigs = task(waitFor(async (station, context) => {
    if (station.usesModels && station.productionScheduleEnabled) {
      context = this.getProductionScheduleForStation(station)?.model;
    }

    await this.taskConfigRepo.loadContextTaskConfigs.perform(station, context);
  }));

  createLiveBuildStatus = task(waitFor(async (
    serialNumber,
    station,
    bomSourceLookupValue,
    model,
    kineticAssemblyOperation,
    employee,
  ) => {
    if (
      isBlank(serialNumber) ||
      (usesBomSourceLookup(station) && isEmpty(bomSourceLookupValue))
    ) {
      return;
    }

    if (station.loadOption === STATION_LOAD_OPTIONS.selectModel && model) {
      model = model.asHistory();
    } else {
      model = null;
    }

    const identifiers = station.area.customIdentifiers.filter(item => item.enabled);

    const previousStatus = await this._getPreviousStatusIfNecessary.perform(identifiers, serialNumber, station);

    return this.store.createRecord('liveBuildStatus', {
      serialNumber,
      status: TaskStatuses.STARTED,
      userId: this.currentUser.user?.id,
      userName: this.currentUser.user?.userName,
      model,
      kineticAssemblyOperation,
      kineticEmployeeId: employee?.employeeId,
      bomSourceLookupValue,
      area: station.area.id,
      location: { id: station.id },
      customIdentifierData: identifiers.map((identifier) => this.store.createRecord('customIdentifierDatum', {
        customIdentifier: identifier.id,
        captions: clone(identifier.captions),
        value: previousStatus?.customIdentifierData.find(item => item.customIdentifier === identifier.id)?.value,
      })),
    });
  }));

  _getPreviousStatusIfNecessary = task(waitFor(async (identifiers, serialNumber, station) => {
    if (isEmpty(identifiers) || generatesSerialNumber(station)) {
      return;
    }

    const searchSerialNumbers = [serialNumber];

    if (station.area.bomSource?.constructor.modelName === 'barcode-bom-source') {
      const source = station.area.bomSource;
      const start = source.serialNumberStart - 1;
      const end = start + source.serialNumberLength;
      const trimmedSerial = serialNumber.slice(start, end);
      if (!isEmpty(trimmedSerial)) {
        searchSerialNumbers.push(trimmedSerial);
      }
    }

    return ((await this.store.query('buildStatus', {
      area: station.area.id,
      take: 1,
      serialNumbers: searchSerialNumbers,
    })))[0];
  }));

  submitBarcode = task({ drop: true }, waitFor(async (childStatus, taskConfig, barcode) => {
    const body = {
      liveBuildStatus: childStatus.parent.id,
      taskConfig: taskConfig.id,
      barcode,
    };

    if (childStatus.repair) {
      body.repair = childStatus.repair;
    }

    await this.eflexAjax.post.perform('jemBarcodes', {
      jemBarcode: body,
    });
  }));

  submitSerialNumberTransfer = task({ drop: true }, waitFor(async (childStatus, taskConfig, transferSerialNumber) => {
    const body = {
      liveBuildStatus: childStatus.parent.id,
      taskConfig: taskConfig.id,
      transferSerialNumber,
    };

    if (childStatus.repair) {
      body.repair = childStatus.repair;
    }

    await this.eflexAjax.post.perform('jemSerialNumberTransfers', {
      jemSerialNumberTransfer: body,
    });
  }));

  completeTask = task({ drop: true }, waitFor(async (taskStatus, childStatus, scannedValue, taskConfig) => {
    if (childStatus.status !== taskStatus) {
      childStatus.status = taskStatus;
    }

    childStatus.processData?.forEach(function (datum) {
      if (datum.value === '') {
        datum.value = null;
      }
    });

    const buildStatus = childStatus.parent;

    const { user } = this.currentUser;
    if (user != null) {
      Object.assign(buildStatus, {
        userId: user.id,
        userName: user.userName,
      });
    }

    if (scannedValue) {
      switch (taskConfig.taskType) {
        case taskTypes.barcode: {
          await this.submitBarcode.perform(childStatus, taskConfig, scannedValue);
          break;
        }
        case taskTypes.serialNumberTransfer: {
          await this.submitSerialNumberTransfer.perform(childStatus, taskConfig, scannedValue);
          break;
        }
      }
    } else {
      await this.saveLiveStatus.perform(buildStatus);
    }
  }));

  _getVisionFilename(serialNumber, treeTask, status, stationFacsId) {
    const config = this.systemConfig.config;
    const fileFormat = config.evision.namingConvention.selectedOptions;
    serialNumber = serialNumber.replace(/[/\\_]/g, '').trim();

    return fileFormat
      .join(config.evision.namingConvention.fieldSeparator)
      .replace('taskId', treeTask.facsId)
      .replace('stationId', stationFacsId)
      .replace('serialNumber', serialNumber)
      .replace('passFail', TaskStatuses.isRejected(status) ? 'fail' : 'pass');
  }

  getProductionScheduleForStation(station) {
    if (station.currentProductionSchedule) {
      return station.currentProductionSchedule;
    }

    return flow(station.productionSchedules ?? [], [
      reject(prop('isCompleted')),
      sortByProp('order'),
      head,
    ]);
  }
}
