import AuthenticatedRoute from 'eflex/util/authenticated-route';
import { typeOf } from '@ember/utils';
import { service } from '@ember/service';

export default class OperationsTasksRoute extends AuthenticatedRoute {
  @service store;
  @service router;

  async afterModel(task) {
    if (typeOf(task) === 'object') {
      task = this.store.peekRecord('task', task.id);
    }

    await this.router.transitionTo('kineticOperations.operation', task.parent.id);
    this.controllerFor('kineticOperations.operation').openTaskPanel.perform(task);
  }
}
