import Service, { service } from '@ember/service';
import { alert } from '@pnotify/core';

export default class NotifierService extends Service {
  @service intl;

  sendError(message, attrs) {
    if (message instanceof Error) {
      attrs = { ...message.contextVariables, ...attrs };
      message = message.message;
    }

    this.#sendNotification(message, attrs, { type: 'error', delay: 5000 });
  }

  sendSuccess(notification, attrs) {
    this.#sendNotification(notification, attrs, { type: 'success' });
  }

  sendWarning(notification, attrs) {
    this.#sendNotification(notification, attrs, { type: 'warning' });
  }

  jemTriggerError(notification, attrs) {
    this.#sendNotification(notification, attrs, { type: 'error', delay: 120_000 });
  }

  #sendNotification(text, attrs, pnotifyParams) {
    if (window.isTesting) {
      return;
    }

    const notice = alert({
      text: this.intl.exists(text) ? this.intl.t(text, attrs ?? {}) : text,
      styling: 'bootstrap4',
      mode: 'light',
      icons: 'fontawesome5',
      ...pnotifyParams,
    });

    notice.on('click', () => {
      notice.close();
    });
  }
}
