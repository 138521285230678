import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import onKey from 'ember-keyboard/modifiers/on-key';
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
import { fn, hash } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import BsButton from 'eflex/components/bs-button';
const ClearButton = template(`
<BsButton
  @type="peripheral"
  class="clear-icon position-absolute end-0 px-2 me-1"
  {{on "click" @onClick}}
  ...attributes
>
  <FaIcon @icon="xmark" @prefix="fas" @size="sm" />
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const SearchButton = template(`
<BsButton
  @type="peripheral"
  class="search-icon position-absolute start-0 px-2 ms-1"
  {{on "click" @onClick}}
  ...attributes
>
  <FaIcon @icon="magnifying-glass" @prefix="fas" @size="sm" />
</BsButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class InputsSearchBox extends Component {
    onInput = (val1)=>{
        if (this.args.disabled) {
            return;
        }
        this.args.onInput?.(val1);
    };
    onSearch = ()=>{
        if (this.args.disabled) {
            return;
        }
        this.args.onSearch?.(this.args.searchValue);
    };
    static{
        template(`
    <div
      class="component-inputs-search-box input-search-box position-relative d-flex"
      {{onKey 'Enter' this.onSearch}}
      ...attributes
    >
      <div
        class="search-box d-flex position-relative align-items-center w-100
        {{if @disabled "disabled"}}"
      >
        <input
          placeholder={{@placeholder}}
          value={{@searchValue}}
          disabled={{@disabled}}
          class="search-term search-input form-control"
          {{on "input" (inputVal this.onInput)}}
        />
        {{#if (has-block)}}
          {{yield
            (hash
              clear=(component ClearButton onClick=(fn this.onInput null))
              icon=(component SearchButton onClick=this.onSearch)
            )
          }}
        {{else}}
          <ClearButton @onClick={{fn this.onInput null}} />
          <SearchButton @onClick={{this.onSearch}} />
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
