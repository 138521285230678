import { template } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import onKey from 'ember-keyboard/modifiers/on-key';
import { on } from '@ember/modifier';
import { inputVal } from 'eflex/helpers';
import BsButton from 'eflex/components/bs-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { fn } from '@ember/helper';
import { optional } from '@eflexsystems/ember-composable-helpers';
import { not } from 'ember-truth-helpers';
const onClear = (onClearAction1, e1)=>{
    if (!isEmpty(e1.target.value)) {
        return;
    }
    return onClearAction1?.();
};
export default template(`
<div class="component-inputs-search d-flex position-relative align-items-center">
  <input
    disabled={{@disabled}}
    class="search-term form-control pe-4"
    type="search"
    value={{@value}}
    {{onKey
      'Enter'
      (fn (optional @onSubmit) @value)
      activated=(not @disabled)
    }}
    {{on "input" (inputVal @onInput)}}
    {{on "search" (fn onClear @onClear)}}
    ...attributes
  />
  <BsButton
    @type="light"
    class="search border-0 bg-transparent position-absolute end-0"
    @size="sm"
    @disabled={{@disabled}}
    @onClick={{fn (optional @onSubmit) @value}}
  >
    <FaIcon @icon="magnifying-glass" @prefix="fas" />
  </BsButton>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
