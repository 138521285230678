import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
// eslint-disable-next-line no-restricted-imports
import $ from 'jquery';
import Component from '@glimmer/component';
import { dequal } from 'dequal';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { task, timeout, waitForQueue } from 'ember-concurrency';
import { waitForPromise, waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';
import { modifier } from 'ember-modifier';
import { onResize, eventBus } from 'eflex/modifiers';
import { eq } from 'ember-truth-helpers';
import TreeSelect from 'eflex/components/tree-select';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import IconButton from 'eflex/components/icon-button';
import { serviceHelper } from 'eflex/helpers';
import { on } from '@ember/modifier';
const getStartAndEndRows = (treeTask1)=>{
    if (treeTask1.sizeY === 1) {
        return treeTask1.row;
    }
    const x1 = Number.parseInt(treeTask1.row);
    const y1 = Number.parseInt(treeTask1.sizeY) - 1;
    return `${treeTask1.row}-${x1 + y1}`;
};
const onMoveTaskToStation = (treeTask1, store1, onMoveTaskToStationFn1, { id: id1 })=>{
    const station1 = store1.peekRecord('station', id1);
    return onMoveTaskToStationFn1(treeTask1, station1);
};
export const TaskCard = template(`
<li
  class="
    gridster-card-holder
    gs-w
    panel
    {{if @isSelected 'is-selected'}}
    {{if @task.validations.isInvalid "is-invalid"}}
  "
  data-row={{@task.row}}
  data-col={{@task.column}}
  data-sizex={{1}}
  data-sizey={{@task.sizeY}}
  data-test-card-name={{@task.name}}
  data-task-id={{@task.id}}
  ...attributes
>
  {{#let
    (serviceHelper 'store')
  as |store|}}
    <div class="card task-card">
      <div class="card-header border-bottom-0 {{if @taskConfigDisabled "disabled"}}">
        <div class="tool-number d-flex align-items-center">
          <div class="task-card-start-end-rows d-flex ms-1">
            {{getStartAndEndRows @task}}
          </div>
          {{#if @hasAuthorization}}
            {{#if @groupedSiblingStations}}
              <TreeSelect
                class="d-flex ms-auto me-2 move-to-station"
                @sourceTree={{@groupedSiblingStations}}
                @maxDepth={{1}}
                @disabled={{@disabled}}
                @onClick={{fn onMoveTaskToStation @task store @moveTaskToStation}}
              >
                <i title={{t 'plant.task.move'}} class="icon icon-drag-drop"></i>
              </TreeSelect>
            {{else}}
              <div class="d-flex ms-auto me-2"></div>
            {{/if}}
            <IconButton
              class='me-2 copy-task'
              title={{t "plant.task.copy"}}
              @icon='copy'
              @prefix='fas'
              @disabled={{@disabled}}
              @onClick={{fn @copyTask @task}}
            />
            <IconButton
              title={{t 'plant.task.delete'}}
              class="d-flex icon-trash me-1 delete-task-card"
              @disabled={{@disabled}}
              @onClick={{fn @deleteTask @task}}
            />
          {{/if}}
        </div>
      </div>
      <div
        class="card-body d-inline-flex align-items-center"
        {{on "click" (fn @openTaskPanel @task)}}
      >
        <div class="task-icon {{@task.iconClass}} ms-0 me-2"></div>
        <div title={{@task.name}} class="task-name text-truncate pe-1">
          {{@task.name}}
        </div>
      </div>
    </div>
  {{/let}}
</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const destroyGridster = (element1, isFullDestroy1)=>{
    /* eslint-disable-next-line ember/no-jquery */ $(element1.querySelector('.gridster-cards')).data('gridster')?.destroy(isFullDestroy1);
};
const getTaskConfig = (treeTask1, selectedContext1, taskRepo1)=>{
    if (treeTask1.usesComponents) {
        return taskRepo1.getConfig(treeTask1, treeTask1.component?.options[0]);
    } else {
        return taskRepo1.getConfig(treeTask1, selectedContext1);
    }
};
export default class TaskCardGrid extends Component {
    @service
    store;
    @service
    taskRepo;
    #createdDestructor = false;
    previousCardIds = this.args.taskParent.children.map((item1)=>item1.id);
    reloadGrid = task(waitFor(async (element1)=>{
        await waitForQueue('afterRender');
        destroyGridster(element1, false);
        this.loadGridster(element1);
    }));
    _updateAllCoordinates = task(waitFor(async (ui1)=>{
        await this._scrollGrid.cancelAll({
            resetState: true
        });
        ui1.$player[0].closest('.task-card-grid').querySelectorAll('.gridster-card-holder').forEach((taskCard1)=>{
            const dimensions1 = {
                column: Number.parseInt(taskCard1.dataset.col),
                row: Number.parseInt(taskCard1.dataset.row),
                sizeY: Number.parseInt(taskCard1.dataset.sizey)
            };
            const treeTask1 = this.store.peekRecord('task', taskCard1.dataset.taskId);
            const update1 = {};
            for(const prop1 in dimensions1){
                if (treeTask1[prop1] != dimensions1[prop1]) {
                    update1[prop1] = dimensions1[prop1];
                }
            }
            if (isNotNilOrEmpty(update1)) {
                Object.assign(treeTask1, update1);
            }
        });
    }));
    _scrollGrid = task(waitFor(async (ui1)=>{
        const parent1 = ui1.$player[0].closest('.task-grid-wrapper');
        const top1 = ui1.pointer?.top ?? 0;
        if (top1 > window.innerHeight - 100) {
            await this._scrollLoop.perform(parent1, 5);
        } else if (top1 < parent1.getBoundingClientRect().top + 100) {
            await this._scrollLoop.perform(parent1, -5);
        } else {
            await this._scrollLoop.cancelAll({
                resetState: true
            });
        }
    }));
    _scrollLoop = task({
        drop: true
    }, waitFor(async (parent1, scrollAmount1)=>{
        while(true){
            await timeout(10);
            parent1.scrollTop += scrollAmount1;
        }
    }));
    initializeGridster = modifier(async (element1)=>{
        await waitForPromise(import('@eflexsystems/gridster/src/jquery.gridster.js'));
        if (this.isDestroying || this.isDestroyed) {
            return;
        }
        this.loadGridster(element1);
    });
    updateGridster = modifier(async (element1, [tasks1])=>{
        const cardIds1 = tasks1.map((item1)=>item1.id);
        if (!this.previousCardIds) {
            this.previousCardIds = cardIds1;
            return;
        }
        if (!dequal(this.previousCardIds, cardIds1)) {
            this.previousCardIds = cardIds1;
            await this.reloadGrid.perform(element1);
        }
    });
    loadGridster(element1) {
        /* eslint-disable-next-line ember/no-jquery */ const gridsterElem1 = $(element1.querySelector('.gridster-cards'));
        const gridster1 = gridsterElem1?.gridster({
            min_rows: 256,
            max_rows: 600,
            max_cols: 256,
            autogrow_cols: true,
            shift_widgets_up: false,
            move_widgets_down_only: true,
            shift_larger_widgets_down: false,
            widget_margins: [
                6,
                6
            ],
            widget_base_dimensions: [
                225,
                62
            ],
            ignore_self_occupied: true,
            resize: {
                enabled: true,
                axes: [
                    'y'
                ],
                remove_holes: false,
                handle_append_to: '.task-card',
                handle_class: 'task-resize-handle',
                stop: (e1, ui1)=>{
                    this._updateAllCoordinates.perform(ui1);
                }
            },
            draggable: {
                /* eslint-disable-next-line ember/no-jquery */ scroll_target: $(element1),
                autoscroll: true,
                stop: (e1, ui1)=>{
                    this._updateAllCoordinates.perform(ui1);
                },
                drag: (e1, ui1)=>{
                    this._scrollGrid.perform(ui1);
                },
                ignore_dragging: [
                    '.task-card-dropdown-caret',
                    'input',
                    'textarea',
                    'select',
                    'button',
                    '.ember-power-select-option',
                    '.ember-power-select-options',
                    '.ember-basic-dropdown-trigger',
                    'i',
                    'svg',
                    'path'
                ]
            },
            collision: {
                wait_for_mouseup: true
            }
        });
        if (!this.#createdDestructor) {
            this.#createdDestructor = true;
            registerDestructor(this, ()=>{
                destroyGridster(element1, true);
            });
        }
        const gridsterData1 = gridster1?.data('gridster');
        if (this.args.disabled) {
            gridsterData1.disable();
            gridsterData1.disable_resize();
        } else {
            gridsterData1.enable_resize();
        }
    }
    static{
        template(`
    <div
      class="task-card-grid"
      {{this.initializeGridster}}
      {{this.updateGridster @taskParent.children}}
      {{onResize this.reloadGrid.perform}}
      {{eventBus taskCardsRolledBack=this.reloadGrid.perform}}
      ...attributes
    >
      <div class="gridster-wrapper">
        <div class="gridster">
          <ul class="gridster-cards">
            {{#each @taskParent.tasks as |treeTask|}}
              {{#unless treeTask.isDeleted}}
                {{#let (getTaskConfig treeTask @selectedContext this.taskRepo) as |taskConfig|}}
                  <TaskCard
                    isSelected={{eq treeTask.id @selectedTask.id}}
                    @task={{treeTask}}
                    @taskConfigDisabled={{taskConfig.disabled}}
                    @disabled={{@disabled}}
                    @groupedSiblingStations={{@groupedSiblingStations}}
                    @openTaskPanel={{@openTaskPanel}}
                    @moveTaskToStation={{@moveTaskToStation}}
                    @copyTask={{@copyTask}}
                    @deleteTask={{@deleteTask}}
                    @hasAuthorization={{@hasAuthorization}}
                  />
                {{/let}}
              {{/unless}}
            {{/each}}
          </ul>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
