import LocaleMap from 'eflex/util/locale-map';
import Service, { service } from '@ember/service';
import { didCancel, task } from 'ember-concurrency';
import moment from 'moment-timezone';
import config from 'eflex/config/environment';
import { ObjectId } from 'bson';
import { tracked } from '@glimmer/tracking';
import { userRoles } from 'eflex/constants/user-roles';

// needs to explicity required so webpack can fingerprint
import deTranslationsUrl from '/translations/de.json';
import enUsTranslationsUrl from '/translations/en-us.json';
import esTranslationsUrl from '/translations/es.json';
import frTranslationsUrl from '/translations/fr.json';
import hiTranslationsUrl from '/translations/hi.json';
import huTranslationsUrl from '/translations/hu.json';
import jaTranslationsUrl from '/translations/ja.json';
import koTranslationsUrl from '/translations/ko.json';
import plTranslationsUrl from '/translations/pl.json';
import ptTranslationsUrl from '/translations/pt.json';
import zhTranslationsUrl from '/translations/zh.json';
import { waitFor } from '@ember/test-waiters';

const TRANSLATION_MAP = {
  de: deTranslationsUrl,
  'en-us': enUsTranslationsUrl,
  es: esTranslationsUrl,
  fr: frTranslationsUrl,
  hi: hiTranslationsUrl,
  hu: huTranslationsUrl,
  ja: jaTranslationsUrl,
  ko: koTranslationsUrl,
  pl: plTranslationsUrl,
  pt: ptTranslationsUrl,
  zh: zhTranslationsUrl,
};

export default class CurrentUser extends Service {
  @service session;
  @service store;
  @service intl;
  @service userRepo;

  @tracked user;

  tabSession = new ObjectId().toString();

  get isAdmin() {
    return this.user?.isAdmin;
  }

  get isUser() {
    return this.user?.isUser;
  }

  get language() {
    return this.user?.language;
  }

  get isNotUser() {
    return !this.isUser;
  }

  get isNotAdmin() {
    return this.user?.isNotAdmin;
  }

  setupUser = task(waitFor(async () => {
    const idToken = this.session.data?.authenticated?.id_token;

    if (!this.session.data?.authenticated?.userId && !idToken) {
      await this._setLanguage.perform();
      return;
    }

    try {
      if (idToken) {
        this.user = await this._setupSsoUser.perform();
      } else {
        this.user = this.store.peekRecord('user', this.session.data?.authenticated?.userId);
      }
      await this._setLanguage.perform(this.user?.language);
    } catch (e) {
      if (!didCancel(e)) {
        console.error(e);
        await this.session.invalidate();
      }
    }
  }));

  _setupSsoUser = task(waitFor(async () => {
    const userName = this.session.data?.authenticated?.userinfo?.[config['ember-simple-auth-oidc'].userNameProperty];

    if (!userName) {
      await this.session.invalidate();
      return;
    }

    let user = this.userRepo.users.find(item => item.userName === userName);

    if (!user) {
      user = this.store.createRecord('user', {
        userName,
        isSso: true,
        role: userRoles.User.label,
      });

      await user.save();
    }

    return user;
  }));

  changeLanguage = task(waitFor(async language => {
    if (!this.user) {
      await this._setLanguage.perform(language);
      return;
    }

    await this._setLanguage.perform(language);
    this.user.language = language;
    await this.user.save();
  }));

  loadLocale = task(waitFor(async (locale, force = false) => {
    if (window.isTesting) {
      return;
    }

    if (!force && this.intl.locales.includes(locale)) {
      return;
    }

    const translations = await fetch(TRANSLATION_MAP[locale]);
    this.intl.addTranslations(locale, (await translations.json()));
  }));

  _setLanguage = task(waitFor(async (language = 'english') => {
    const locale = LocaleMap.find(item => item.lang === language)?.code;

    let momentLocale = locale === 'zh' ? 'zh-cn' : locale;
    momentLocale = locale === 'en-us' ? 'en' : locale;
    moment.locale(momentLocale);

    await this.loadLocale.perform(locale);
    this.intl.setLocale(locale);
  }));

  getUserTokenUrl(url) {
    if (url == null) {
      return null;
    }

    const token = this.session.data?.authenticated?.token;
    const delimiter = url?.includes('?') ? '&' : '?';

    return `${url}${delimiter}jwt=${token}`;
  }
}
