import Service, { service } from '@ember/service';

export default class VariableDefRepoService extends Service {
  @service store;

  createVariableDef(defParams) {
    const def = this.store.createRecord('variableDef', defParams);
    def.task.taskConfigs.forEach((taskConfig) => {
      this.store.createRecord('variableDefConfig', {
        variableDef: def,
        taskConfig,
      });
    });
  }

  setVariableValue(variableDefConfig, value) {
    if (value == null) {
      Object.assign(variableDefConfig, {
        variableDefForValue: null,
        jemProcessDataDefForValue: null,
        value: null,
      });
      return;
    }

    switch (value.constructor?.modelName) {
      case 'variable-def': {
        Object.assign(variableDefConfig, {
          variableDefForValue: value,
          jemProcessDataDefForValue: null,
          value: null,
          type: null,
        });
        break;
      }
      case 'jem-process-data-def': {
        Object.assign(variableDefConfig, {
          variableDefForValue: null,
          jemProcessDataDefForValue: value,
          value: null,
          type: null,
        });
        break;
      }
      default: {
        Object.assign(variableDefConfig, {
          variableDefForValue: null,
          jemProcessDataDefForValue: null,
          value,
        });
      }
    }
  }

  deleteVariable(variableDef) {
    variableDef.variableDefConfigs.forEach(config => { config.deleteRecord(); });
    variableDef.deleteRecord();
  }
}
