import EmberRouter from '@embroider/router';
import config from 'eflex/config/environment';

class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

// eslint-disable-next-line array-callback-return
Router.map(function() {
  this.route('login');
  this.route('loginOidc');
  this.route('landingPage', { path: '/' });
  this.route('logs');
  this.route('repair');
  this.route('hardware');
  this.route('schedules');
  this.route('productionScheduler');

  this.route('containParts', function() {
    this.route('containment', { path: '/' });
    this.route('scrapped');
  });

  this.route('jem', function() {
    this.route('stations', { path: '/stations/:id' });
  });

  this.route('oee', function() {
    this.route('station', { path: ':id' });
  });

  this.route('codes', function() {
    this.route('reject');
    this.route('repair');
    this.route('fault');
    this.route('scrap');
  });

  this.route('bi', function() {
    this.route('andon', function() {
      this.route('stations', { path: '/stations/' });
    });
    this.route('quality', function() {
      this.route('stations', { path: '/stations/' });
      this.route('rejects', { path: '/rejects/' });
    });
    this.route('production');
    this.route('cycleTime', function () {
      this.route('serialNumbers', { path: '/serialNumbers/' });
      this.route('models', { path: '/models/' });
      this.route('stations', { path: '/stations/' });
    });
    this.route('processData', function() {
      this.route('processDatum', { path: '/processDatum/' });
      this.route('lookup', { path: '/lookup/' });
    });
    this.route('torqueData', function () {
      this.route('bolts', { path: '/bolts/' });
      this.route('singleBoltsChart', { path: '/boltsChart/' });
    });
    this.route('serialNumberReport');
    this.route('oee', function () {
      this.route('faults', { path: '/faults/' });
      this.route('states', { path: '/states/' });
    });
  });

  this.route('settings', function() {
    this.route('users', { path: '/' });
    this.route('licensing');
    this.route('backup');
    this.route('evision');
    this.route('jem');
    this.route('server');
    this.route('singleSignOn');
    this.route('kinetic');
  });

  this.route('kineticJobs');
  this.route('kineticOperations', function() {
    this.route('operation', { path: ':id' });
    this.route('tasks', { path: '/tasks/:id' });
  });

  this.route('plant', function() {
    this.route('stations', { path: '/stations/:id' });
    this.route('tasks', { path: '/tasks/:id' });

    this.route('areas', { path: '/areas/:id' }, function() {
      this.route('tasks', { path: '/' });
      this.route('options');
      this.route('models');
    });
  });

  this.route('workInstructions', function() {
    this.route('workInstruction', { path: ':id' });
  });

  this.route('bomSources', function() {
    this.route('bomSource', { path: ':id' });
  });

  this.route('bomManager', function() {
    this.route('bomSource', { path: '/bomSource/:id' });
  });

  this.route('parts', function() {
    this.route('buildHistory', function() {
      this.route('processData');
      this.route('marriages');
      this.route('images');
    });
    this.route('genealogy');
  });

  this.route('evision', function() {
    this.route('imageRetrieval');
    this.route('nonConforming');

    this.route('camera', function() {
      this.route('status', { path: '/' });
      this.route('configuration');
      this.route('correctFileNaming');
    });
  });

  this.route('status');
  this.route('missingConfigs');

  this.route('debug', function() {
    this.route('systemLogs');
    this.route('packets');
    this.route('liveBuildStatusLogs');
    this.route('audits');
    this.route('hardwareMessages');
    this.route('kineticIntegrationLogs', function() {
      this.route('integrationInbox', { path: '/' });
      this.route('integrationInboxLog');
      this.route('integrationOutbox');
      this.route('integrationOutboxLog');
    });
  });

  if (config.APP.development) {
    this.route('styleguide');
    this.route('schema');
    this.route('dataGenerator');
    this.route('integrationTester');
  }
});

export default Router;
