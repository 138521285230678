import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import { t } from 'ember-intl';
import { hash } from '@ember/helper';
const IoIndex = template(`
<@form.element
  class='test-io-index'
  @controlType='number'
  @model={{@model}}
  @label={{t 'ioIndex'}}
  @property={{withDefault @property 'ioIndex'}}
  @invisibleLabel={{@invisibleLabel}}
  ...attributes
as |elem|>
  <elem.control min='1' />
</@form.element>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const HardwareIndex = template(`
{{#if @usesOperations}}
  <@form.element
    class='test-hardware-index'
    @model={{@model}}
    @controlType='number'
    @property={{withDefault @property 'hardwareIndex'}}
    @label={{t 'number'}}
    @invisibleLabel={{@invisibleLabel}}
    @size={{@size}}
    ...attributes
  as |elem|>
    <elem.control min='1' />
  </@form.element>
{{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const HardwarePowerSelect = template(`
<@form.element
  @controlType={{withDefault @controlType 'power-select'}}
  @model={{@model}}
  @property={{withDefault @property (if @usesOperations 'hardwareAssignedType' 'hardware')}}
  @label={{t 'hardware'}}
  @invisibleLabel={{@invisibleLabel}}
  @options={{@options}}
  ...attributes
as |el|>
  <el.control
    class='test-hardware-select'
    @placeholder={{@placeholder}}
    @searchEnabled={{true}}
    @searchField={{unless @usesOperations 'name'}}
    @onChange={{withDefault @onChange el.setValue}}
  as |hardware|>
    {{#if hardware}}
      {{#if @usesOperations}}
        {{t hardware}}
      {{else}}
        {{hardware.name}}
      {{/if}}
    {{else}}
      {{@placeholder}}
    {{/if}}
  </el.control>
</@form.element>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class HardwareSelect extends Component {
    @service
    hardwareRepo;
    get hardwareTypes() {
        return this.hardwareRepo.getAllowedHardwareTypes(this.args.types).map((type1)=>camelize(type1));
    }
    get hardwares() {
        const hardwares1 = this.hardwareRepo.hardwareByType(this.args.types);
        return this.hardwareRepo.groupHardware(hardwares1);
    }
    get placeholder() {
        return this.args.default ?? '---';
    }
    get usesOperations() {
        return this.args.usesOperations ?? this.args.model.usesOperations;
    }
    get options() {
        let options1;
        if (this.usesOperations) {
            options1 = this.hardwareTypes;
        } else {
            options1 = this.hardwares;
        }
        if (!this.args.required) {
            options1 = [
                null,
                ...options1
            ];
        }
        return options1;
    }
    static{
        template(`
    {{#if (has-block)}}
      {{yield
        (hash
          select=(component HardwarePowerSelect
            controlType=@controlType
            model=@model
            usesOperations=this.usesOperations
            options=this.options
            placeholder=this.placeholder
            invisibleLabel=@invisibleLabel
            form=@form
          )
          index=(component HardwareIndex
            form=@form
            model=@model
            usesOperations=this.usesOperations
            invisibleLabel=@invisibleLabel
          )
          ioIndex=(component IoIndex
            form=@form
            model=@model
            usesOperations=this.usesOperations
            invisibleLabel=@invisibleLabel
          )
        )
      }}
    {{else}}
      <div class='row' ...attributes>
        <div class='col'>
          <HardwarePowerSelect
            @controlType={{@controlType}}
            @model={{@model}}
            @usesOperations={{this.usesOperations}}
            @options={{this.options}}
            @placeholder={{this.placeholder}}
            @invisibleLabel={{@invisibleLabel}}
            @form={{@form}}
          />
        </div>

        {{#if this.usesOperations}}
          <div class='col-2'>
            <HardwareIndex
              @form={{@form}}
              @model={{@model}}
              @usesOperations={{true}}
              @invisibleLabel={{@invisibleLabel}}
            />
          </div>
        {{/if}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
