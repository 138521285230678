import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import BlobImage from 'eflex/components/blob-image';
import { and } from 'ember-truth-helpers';
import { task, timeout } from 'ember-concurrency';
import { CameraTriggerValues } from 'eflex/constants/tasks/trigger-types';
import { waitFor } from '@ember/test-waiters';
import { registerDestructor } from '@ember/destroyable';
import { eventBus } from 'eflex/modifiers';
import { on } from '@ember/modifier';
import { triggerEventBus, eventBus as eventBusHelper, getHardware } from 'eflex/helpers';
import Modifier, { modifier } from 'ember-modifier';
const CAPTURE_TRIGGER_TYPES = new Set([
    CameraTriggerValues.captureAdvance,
    CameraTriggerValues.taskActive
]);
const AUTO_TRIGGER_TYPES = new Set([
    'captureAdvance',
    'taskActive'
]);
const getTestBarcodeReader = ()=>{
    return {
        isCancelled: false,
        stopContinuousDecode () {},
        reset () {},
        decodeFromVideoDevice () {
            return Promise.resolve();
        }
    };
};
const getCanvasBlob = async (videoElement1, imageWidth1, imageHeight1)=>{
    if (window.isTesting) {
        return new Blob([
            ''
        ], {
            type: 'image/jpeg'
        });
    }
    const canvas1 = document.createElement('canvas');
    canvas1.width = imageWidth1;
    canvas1.height = imageHeight1;
    const context1 = canvas1.getContext('2d');
    context1.drawImage(videoElement1, 0, 0, imageWidth1, imageHeight1);
    const { canvasToBlob: canvasToBlob1 } = await import('blob-util');
    const blob1 = await canvasToBlob1(canvas1, 'image/jpeg');
    canvas1.remove();
    return blob1;
};
const cleanupBarcodeScanner = (barcodeScanner1)=>{
    if (!barcodeScanner1) {
        return;
    }
    barcodeScanner1.isCancelled = true;
    barcodeScanner1.stopContinuousDecode();
    barcodeScanner1.videoCanPlayListener = null;
    barcodeScanner1.videoEndedListener = null;
    barcodeScanner1.videoPlayingEventListener = null;
    barcodeScanner1.reset();
};
const getReader = async (barcodeSymbology1)=>{
    if (window.isTesting) {
        return getTestBarcodeReader();
    }
    const { BrowserMultiFormatReader: BrowserMultiFormatReader1, DecodeHintType: DecodeHintType1 } = await import('@zxing/library');
    if (barcodeSymbology1 != null) {
        const hints1 = new Map([
            [
                DecodeHintType1.POSSIBLE_FORMATS,
                [
                    barcodeSymbology1
                ]
            ]
        ]);
        return new BrowserMultiFormatReader1(hints1);
    } else {
        return new BrowserMultiFormatReader1();
    }
};
let WebCamScanner = class WebCamScanner extends Modifier {
    #previousCameraId;
    #previousBarcodeSymbology;
    #barcodeScanner;
    #didSetup = false;
    constructor(){
        super(...arguments);
        registerDestructor(this, ()=>{
            cleanupBarcodeScanner(this.#barcodeScanner);
        });
    }
    modify(element1, _positional1, { cameraId: cameraId1, barcodeSymbology: barcodeSymbology1, onScan: onScan1 }) {
        if (this.#didSetup) {
            const cameraIdChanged1 = this.#previousCameraId !== cameraId1;
            const barcodeSymbologyChanged1 = this.#previousBarcodeSymbology !== barcodeSymbology1;
            this.#previousCameraId = cameraId1;
            this.#previousBarcodeSymbology = barcodeSymbology1;
            if (!cameraIdChanged1 && !barcodeSymbologyChanged1) {
                return;
            }
        }
        this.#didSetup = true;
        this.start.perform(element1, cameraId1, barcodeSymbology1, onScan1);
    }
    start = task({
        keepLatest: true
    }, waitFor(async (element1, cameraId1, barcodeSymbology1, onScan1)=>{
        cleanupBarcodeScanner(this.#barcodeScanner);
        const barcodeScanner1 = this.#barcodeScanner = await getReader(barcodeSymbology1);
        const seen1 = new Set();
        await barcodeScanner1.decodeFromVideoDevice(cameraId1, element1, (value1, err1)=>{
            if (barcodeScanner1.isCancelled) {
                cleanupBarcodeScanner(barcodeScanner1);
                return;
            }
            if (err1 != null) {
                return;
            }
            const { text: text1 } = value1;
            if (seen1.has(text1)) {
                return;
            }
            seen1.add(text1);
            onScan1(text1);
        });
    }));
};
export default class JemWebCam extends Component {
    @service
    eventBus;
    @service
    webCam;
    @service
    notifier;
    @tracked
    capturedWebCamImage;
    #previousTask = this.args.task;
    get cameraId() {
        let cameraId1;
        if (this.args.isWebCamLoad) {
            cameraId1 = getHardware(this.args.station, this.args.task)?.cameraId;
        } else {
            cameraId1 = this.args.station?.webCam?.cameraId;
        }
        if (!this.webCam.devices.some((item1)=>item1.deviceId === cameraId1)) {
            // eslint-disable-next-line unicorn/no-useless-undefined
            return undefined;
        }
        return cameraId1;
    }
    onPlaying = task({
        restartable: true
    }, waitFor(async ({ target: target1 })=>{
        this.webCam.cameraStreamPlaying = true;
        const triggerType1 = this.args.task?.triggerType;
        if (CAPTURE_TRIGGER_TYPES.has(triggerType1)) {
            await this.onCaptureImage.perform(target1);
        }
    }));
    onCaptureImage = task({
        restartable: true
    }, waitFor(async (element1)=>{
        const webCam1 = this.webCam;
        if (!webCam1.cameraStreamPlaying) {
            this.notifier.sendWarning('cameraNotLoaded');
            return;
        }
        const triggerType1 = this.args.task.triggerType;
        const webcamDelay1 = this.args.task.webcamDelay;
        const isAuto1 = this.args.task.isAuto;
        const imageWidth1 = element1.videoWidth;
        const imageHeight1 = element1.videoHeight;
        let image1;
        if (triggerType1 !== CameraTriggerValues.captureButton && window.ImageCapture && webCam1.cameraStream) {
            const imageCapture1 = new ImageCapture(webCam1.cameraStream.getVideoTracks()[0]);
            if (isAuto1) {
                await timeout(webcamDelay1 * 1000);
            }
            image1 = await imageCapture1.takePhoto({
                imageWidth: imageWidth1,
                imageHeight: imageHeight1
            });
        } else {
            image1 = await getCanvasBlob(element1, imageWidth1, imageHeight1);
        }
        this.capturedWebCamImage = image1;
        this.eventBus.trigger('imageCaptured', {
            treeTask: this.args.task,
            image: image1
        });
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        this.eventBus.trigger('clearCapturedImage');
    }
    onDidUpdate = task(waitFor(async (element1, [treeTask1])=>{
        const previousTask1 = this.#previousTask;
        this.#previousTask = treeTask1;
        if (!treeTask1 || !treeTask1.usesWebCam || previousTask1 === treeTask1) {
            return;
        }
        const isStarted1 = this.args.childStatus?.isStarted;
        if (isStarted1 && AUTO_TRIGGER_TYPES.has(treeTask1.triggerType)) {
            await this.onCaptureImage.perform(element1);
        } else {
            this.eventBus.trigger('clearCapturedImage');
        }
    }));
    clearCapturedImage = ()=>{
        if (this.capturedWebCamImage) {
            this.capturedWebCamImage = null;
        }
    };
    startWebCam = modifier((element1, [cameraId1])=>{
        this.webCam.initStream.perform(cameraId1);
        return ()=>{
            this.webCam.destroyStreamTracks();
        };
    });
    static{
        template(`
    {{eventBusHelper clearCapturedImage=this.clearCapturedImage}}

    {{#if @isWebCamLoad}}
      <video
        class="h-100 w-100 position-absolute test-web-cam-scanner"
        {{WebCamScanner
          cameraId=this.cameraId
          barcodeSymbology=@station.barcodeSymbology
          onScan=@onScan
        }}
        ...attributes
      />
    {{else if @childStatus.visionUrl}}
      <img
        class="h-100 w-100 position-absolute object-fit-contain vision-image"
        src={{@childStatus.fullVisionUrl}}
        {{didUpdate this.onDidUpdate.perform @task}}
      />
    {{else if this.capturedWebCamImage}}
      <BlobImage
        class="h-100 w-100 position-absolute object-fit-contain captured-webcam-image"
        @blob={{this.capturedWebCamImage}}
        {{didUpdate this.onDidUpdate.perform @task}}
      />
    {{else if (and @task.usesWebCam @childStatus.isStarted)}}
      {{#if @task.isBarcode}}
        <video
          class="h-100 w-100 position-absolute test-web-cam-scanner"
          {{WebCamScanner
            cameraId=this.cameraId
            barcodeSymbology=@task.barcodeSymbology
            onScan=(triggerEventBus 'barcodeScanned')
          }}
          ...attributes
        ></video>
      {{else}}
        <video
          class="h-100 w-100 position-absolute"
          srcObject={{this.webCam.cameraStream}}
          autoplay
          {{on "playing" this.onPlaying.perform}}
          {{this.startWebCam this.cameraId}}
          {{didUpdate this.onDidUpdate.perform @task}}
          {{eventBus captureWebcamImage=this.onCaptureImage.perform}}
          ...attributes
        ></video>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
