import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { isAncestorOf } from 'eflex/util/tree-helpers';
import SidePanel from 'eflex/components/side-panel';
import BsForm from 'eflex/components/bs-form';
import { t } from 'ember-intl';
import { notEq, and, not, or } from 'ember-truth-helpers';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { get, hash, fn } from '@ember/helper';
import { scrollIntoView } from 'eflex/modifiers';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import Spinner from 'eflex/components/spinner';
import { eventBus } from 'eflex/helpers';
import BsButton from 'eflex/components/bs-button';
import ContextSelector from 'eflex/components/context-selector';
import TaskDeviceConfig from 'eflex/components/task-device-config';
import TaskConfig from 'eflex/components/task-config';
import DecisionConfig from 'eflex/components/decision-config';
import TriggerConfigs from 'eflex/components/trigger-configs';
import JemInstructionsConfiguration from 'eflex/components/jem/instructions/configuration';
import VerticalCollection from '@html-next/vertical-collection/components/vertical-collection/component';
const TASK_CONFIGS_CHUNK_SIZE = 15;
export default class TaskPanel extends Component {
    @service
    taskRepo;
    @service
    taskConfigRepo;
    @service
    workInstructionRepo;
    @service
    workInstructionCache;
    @service
    defaultTaskConfigContext;
    @tracked
    taskConfigsCurrentChunks = 15;
    @tracked
    selectedContext = this.defaultTaskConfigContext.getContext(this.args.taskParent);
    @tracked
    scrollToTop = false;
    previousTask = this.args.task;
    get isTesting() {
        return window.isTesting ?? false;
    }
    @cached
    get taskConfigs() {
        const treeTask1 = this.args.task;
        if (treeTask1 == null) {
            return [];
        }
        const context1 = this.selectedContext;
        const usesComponents1 = this.args.taskParent.usesComponents;
        if (!treeTask1) {
            return [];
        } else if (context1?.allSelected && usesComponents1) {
            return treeTask1.children.filter((item1)=>item1.configOption?.component === treeTask1.component);
        } else if (treeTask1.usesOperations || context1.allSelected) {
            return treeTask1.children;
        } else if (usesComponents1) {
            return treeTask1.children.filter((item1)=>item1.configOption === context1);
        } else {
            return treeTask1.children.filter((item1)=>item1.configModel === context1);
        }
    }
    get taskConfigsChunked() {
        return this.taskConfigs.slice(0, this.taskConfigsCurrentChunks);
    }
    constructor(){
        super(...arguments);
        this.workInstructionCache.setupAsync.perform();
    }
    copyWieToAll = (triggerConfig1, event1)=>{
        this.workInstructionRepo.copyToAllContexts(this.args.task, triggerConfig1, event1);
    };
    onTreeItemDeleted = (treeItem1)=>{
        const treeTask1 = this.args.task;
        if (treeTask1 != null && (treeItem1 === treeTask1 || isAncestorOf(treeItem1, treeTask1))) {
            this.args.onCancel();
        }
    };
    onDidUpdate = (elem1, [treeTask1])=>{
        if (treeTask1 != null && treeTask1 !== this.previousTask) {
            this.scrollToTop = true;
        }
        this.previousTask = treeTask1;
    };
    // jscpd:ignore-start
    selectedTaskType = (value1)=>{
        if (value1 === this.args.task?.taskType) {
            return;
        }
        this.taskRepo.changeType(this.args.task, value1);
    };
    contextWasSelected = (context1)=>{
        this.selectedContext = context1;
        if (context1.allSelected) {
            this.scrollToTop = true;
        }
    };
    // jscpd:ignore-end
    tabBecameActive = (tabName1)=>{
        this.scrollToTop = true;
        this.args.tabBecameActive(tabName1);
    };
    copyEventToAll = (taskConfig1)=>{
        this.taskConfigRepo.copyEventToAllContexts(taskConfig1);
    };
    loadMoreInstructions = ()=>{
        if (this.taskConfigsChunked.length < this.taskConfigs.length) {
            this.taskConfigsCurrentChunks += TASK_CONFIGS_CHUNK_SIZE;
        }
    };
    copyDecisionToAll = (taskConfig1)=>{
        this.taskConfigRepo.copyDecisionToAllContexts(taskConfig1);
    };
    static{
        template(`
    <SidePanel
      @header="{{t "taskConfig"}} - {{@taskParent.name}} - {{@task.name}}"
      @onClose={{@onConfirm}}
      {{didUpdate this.onDidUpdate @task}}
    >
      {{eventBus deletedTreeItem=this.onTreeItemDeleted}}

      {{#if @isLoading}}
        <Spinner />
      {{else}}
        <div class="task-panel h-100 d-flex flex-column">
          {{#let this.taskConfigs as |taskConfigs|}}
            <BsForm
              class="task-info p-3"
              @showValidations={{@showValidations}}
              @model={{@task}}
            >
              {{#if (and @task (not @task.usesOperations))}}
                <div class="row form-row align-items-end">
                  <div class="col">
                    <ContextSelector
                      @treeItem={{@task}}
                      @renderInPlace={{true}}
                      @disableComponentSelect={{@disabled}}
                      @selectedContext={{this.selectedContext}}
                      @contextWasSelected={{this.contextWasSelected}}
                      @allowAll={{true}}
                    />
                  </div>
                  <div class="col-auto">
                    <label class="form-label"></label>
                    <div>
                      <BsButton
                        @type="secondary"
                        @onClick={{
                          fn
                          this.taskConfigRepo.copyAllToAllContexts
                          (get taskConfigs 0)
                        }}
                        @disabled={{
                          or
                          this.selectedContext.allSelected
                          @task.component.isAlwaysRun
                          @disabled
                        }}
                        class="copy-to-all"
                      >
                        {{#if @task.usesComponents}}
                          {{t "copyToAllOptions"}}
                        {{else}}
                          {{t "copyToAllModels"}}
                        {{/if}}
                      </BsButton>
                    </div>
                  </div>
                </div>
              {{/if}}
            </BsForm>
            {{#if @task}}
              <div class="task-panel-tabs d-flex flex-column">
                <NoRouteTabs
                  @activeTab={{@activeTab}}
                  @tabBecameActive={{this.tabBecameActive}}
                as |tabs|>
                  <div class="task-tabs-wrapper">
                    <tabs.navigation class="mb-0" as |nav|>
                      <nav.tab @href="task-device">
                        {{t "plant.task.device"}}
                      </nav.tab>
                      <nav.tab @href="task-context">
                        {{#if @task.usesComponents}}
                          {{t "plant.task.componentSpecificConfiguration"}}
                        {{else if @task.usesOperations}}
                          {{t "plant.task.operationSpecificConfiguration"}}
                        {{else}}
                          {{t "plant.task.modelSpecificConfiguration"}}
                        {{/if}}
                      </nav.tab>
                      {{#unless @task.isDecision}}
                        <nav.tab @href="task-decision">
                          {{t "decisions"}}
                        </nav.tab>
                      {{/unless}}
                      <nav.tab @href="task-event">
                        {{t "events"}}
                      </nav.tab>
                      <nav.tab @href="task-wie">
                        {{t "navigation.plant.editor.workInstructions"}}
                      </nav.tab>
                    </tabs.navigation>
                  </div>
                  <tabs.panes class="bg-gray-100 p-3 pt-4 overflow-y-auto overflow-x-hidden" as |panes|>
                    <panes.pane @id="task-device">
                      <div
                        class="eflex-scroll task-panes-wrapper flex-column"
                        {{scrollIntoView
                          shouldScroll=this.scrollToTop
                          options=(hash behavior="smooth" top=0)
                        }}
                      >
                        <TaskDeviceConfig
                          @task={{@task}}
                          @taskParent={{@taskParent}}
                          @showValidations={{@showValidations}}
                          @disabled={{@disabled}}
                          @selectedTaskType={{this.selectedTaskType}}
                        />
                      </div>
                    </panes.pane>
                    <panes.pane @id="task-context">
                      <div class="eflex-scroll task-panes-wrapper flex-column">
                        {{#each taskConfigs as |taskConfig i|}}
                          <div class="task-context-config">
                            {{#if (notEq i 0)}}
                              <hr />
                            {{/if}}

                            <TaskConfig
                              class='pb-2'
                              @task={{@task}}
                              @taskConfig={{taskConfig}}
                              @disabled={{@disabled}}
                              @showValidations={{@showValidations}}
                            />
                          </div>
                        {{/each}}
                      </div>
                    </panes.pane>
                    {{#unless @task.isDecision}}
                      <panes.pane @id="task-decision">
                        <div class="eflex-scroll task-panes-wrapper flex-column">
                          {{#each taskConfigs as |taskConfig i|}}
                            <div class="task-decision-config">
                              {{#if (notEq i 0)}}
                                <hr />
                              {{/if}}
                              <div class="context-name model-specific label fw-semibold pb-3 fs-4">
                                {{taskConfig.configName}}
                              </div>
                              <DecisionConfig
                                @taskConfig={{taskConfig}}
                                @task={{@task}}
                                @disabled={{@disabled}}
                                @showValidations={{@showValidations}}
                              />
                              {{#unless @task.usesOperations}}
                                <div class="d-flex justify-content-end mt-3">
                                  <BsButton
                                    class="copy-tab"
                                    @type="secondary"
                                    @onClick={{fn this.copyDecisionToAll @taskConfig}}
                                    @disabled={{@task.component.isAlwaysRun}}
                                  >
                                    {{#if @task.usesComponents}}
                                      {{t "copyOptionSpecific"}}
                                    {{else}}
                                      {{t "copyModelSpecific"}}
                                    {{/if}}
                                  </BsButton>
                                </div>
                              {{/unless}}
                            </div>
                          {{/each}}
                        </div>
                      </panes.pane>
                    {{/unless}}
                    <panes.pane @id="task-event">
                      <div class="eflex-scroll task-panes-wrapper flex-column">
                        {{#each taskConfigs as |taskConfig i|}}
                          <div class="task-event-config">
                            {{#if (notEq i 0)}}
                              <hr />
                            {{/if}}
                            <div class="context-name model-specific label fw-semibold pb-3 fs-4">
                              {{taskConfig.configName}}
                            </div>
                            <TriggerConfigs
                              @location={{@task}}
                              @triggerConfigs={{taskConfig.triggerConfigs}}
                              @showValidations={{@showValidations}}
                              @disabled={{@disabled}}
                            />
                            {{#unless @task.usesOperations}}
                              <div class="d-flex justify-content-end mt-3">
                                <BsButton
                                  @type="secondary"
                                  @onClick={{fn this.copyEventToAll taskConfig}}
                                  @disabled={{@task.component.isAlwaysRun}}
                                  class="copy-tab"
                                >
                                  {{#if @task.usesComponents}}
                                    {{t "copyOptionSpecific"}}
                                  {{else}}
                                    {{t "copyModelSpecific"}}
                                  {{/if}}
                                </BsButton>
                              </div>
                            {{/unless}}
                          </div>
                        {{/each}}
                      </div>
                    </panes.pane>
                    <panes.pane @id="task-wie" class="mh-100">
                      <VerticalCollection
                        @items={{this.taskConfigsChunked}}
                        @estimateHeight="500px"
                        @lastReached={{this.loadMoreInstructions}}
                        @renderAll={{this.isTesting}}
                      as |taskConfig i|>
                        <div class="task-instruction-config">
                          {{#if (notEq i 0)}}
                            <hr />
                          {{/if}}
                          <div class="context-name model-specific label fw-semibold fs-4 pb-3">
                            {{taskConfig.configName}}
                          </div>
                          <JemInstructionsConfiguration
                            @taskConfig={{taskConfig}}
                            @usesComponents={{taskConfig.usesComponents}}
                            @disabled={{@disabled}}
                            @useEvent={{true}}
                            @showCopyToAll={{not @task.usesOperations}}
                            @copyToAll={{this.copyWieToAll}}
                          />
                        </div>
                      </VerticalCollection>
                    </panes.pane>
                  </tabs.panes>
                </NoRouteTabs>
              </div>
            {{/if}}
          {{/let}}
        </div>
      {{/if}}
    </SidePanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
