import { io } from 'socket.io-client';
import Service, { service } from '@ember/service';
import { task, all } from 'ember-concurrency';
import config from 'eflex/config/environment';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

export default class WebSocketService extends Service {
  _webSocket;
  #joinedRooms = new Set();
  @tracked _isConnected = true;

  @service currentUser;

  get isConnected() {
    return this._isConnected;
  }

  set isConnected(value) {
    if (this._isConnected === value) {
      return;
    }

    this._isConnected = value;
  }

  constructor() {
    super(...arguments);

    if (window.isTesting) {
      return;
    }

    this._webSocket = io(config.webSocket, {
      transports: ['websocket', 'polling'],
      secure: window.location.protocol === 'https',
    });

    this._webSocket
      ?.on('connect', () => {
        this.isConnected = true;
      })
      .on('connect_error', () => {
        this.isConnected = false;
      })
      .on('disconnect', () => {
        this.isConnected = false;
      });

    this._webSocket.io.on('reconnect', () => {
      this._reconnectToRooms.perform();
    });
  }

  connectToRoom = task(waitFor(async room => {
    if (window.isTesting || this.#joinedRooms.has(room)) {
      return;
    }

    this.#joinedRooms.add(room);

    await this._webSocket.emitWithAck('join', room);
  }));

  _reconnectToRooms = task(waitFor(async () => {
    await all(
      Array.from(this.#joinedRooms, async (room) => {
        await this._webSocket.emitWithAck('join', room);
      }),
    );
  }));

  disconnectFromRoom(room) {
    if (window.isTesting) {
      return;
    }

    this.#joinedRooms.delete(room);
    this._webSocket.emit('leave', room);
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (this._webSocket != null) {
      this._webSocket.io?.removeAllListeners();
      this._webSocket.removeAllListeners();
      this._webSocket.close();
      this._webSocket = null;
    }

    this.#joinedRooms = null;
  }

  addListener(channel, func) {
    this._webSocket?.on(channel, (message) => {
      this._triggerWebSocketAction(func, message);
    });
  }

  removeListener(channel) {
    this._webSocket?.off(channel);
  }

  removeListeners(channels) {
    channels.forEach((channel) => {
      this.removeListener(channel);
    });
  }

  _triggerWebSocketAction(func, message) {
    if (message.session != null && (this.currentUser.tabSession === message.session || message.session === 'tests')) {
      return;
    }

    func(message.message, message.userName);
  }
}
