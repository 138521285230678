import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import ClickableLogo from 'eflex/components/clickable-logo';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { on } from '@ember/modifier';
import Link from 'eflex/components/link';
import PortalTarget from 'ember-stargate/components/portal-target';
import { t } from 'ember-intl';
import UserDropdown from 'eflex/components/user-dropdown';
let MainHeader = class MainHeader extends Component {
    @service
    resizeListener;
    @service
    licensing;
    @service
    session;
    @service
    currentUser;
    toggleNav = ()=>{
        if (this.resizeListener.navBarCollapsed) {
            this.resizeListener.openNavBar();
            this.resizeListener.triggerResize();
        } else {
            this.resizeListener.collapseNavBar();
            this.resizeListener.triggerResize();
        }
    };
    static{
        template(`
    <div class="main-header bg-gray-900 hidden-print d-flex align-items-center">
      <div class="logo-wrapper d-flex align-items-center">
        <ClickableLogo />
        {{#if this.session.isActuallyAuthenticated}}
          <div class="toggle-nav" {{on "click" this.toggleNav}}>
            {{#unless this.resizeListener.navBarCollapsed}}
              <FaIcon
                @icon="chevron-left"
                @size="xs"
                @prefix="fas"
              />
            {{/unless}}
            <FaIcon
              @icon="bars"
              @size="lg"
              @prefix="fas"
            />
            {{#if this.resizeListener.navBarCollapsed}}
              <FaIcon
                @icon="chevron-right"
                @size="xs"
                @prefix="fas"
              />
            {{/if}}
          </div>
        {{/if}}
      </div>
      <div class="header-menu flex-fill d-flex flex-column m-2">
        <div class="navbar-right row align-items-center justify-content-end g-2">
          <div class="col-auto">
          </div>
          {{#if this.licensing.license.licenseWarningEnabled}}
            <div class="header-item col-auto">
              <Link
                @route="settings.licensing"
                class="btn btn-sm btn-warning license-warning"
              >
                <FaIcon
                  @icon="triangle-exclamation"
                  @prefix="fas"
                  class="text-danger me-1"
                />{{t "licenseWarning"}}
              </Link>
            </div>
          {{/if}}
          <div class="header-item col-auto">
            <PortalTarget @name='save-buttons' />
          </div>
          {{#if this.session.isActuallyAuthenticated}}
            <div class="col-auto">
              <Link
                class="home-page btn w-auto btn-secondary-dark"
                @route="landingPage"
              >
                <FaIcon @icon="house" @prefix="fas" />
              </Link>
            </div>
            <div class="col-auto">
              <UserDropdown
                @showHeaderButtons={{true}}
                class='z-top-menu'
              />
            </div>
          {{/if}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default MainHeader;
