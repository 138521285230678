import BaseOidcSession from '@eflexsystems/ember-simple-auth-oidc/services/session';
import { service } from '@ember/service';
import config from 'eflex/config/environment';
import { isBlank } from '@ember/utils';

// ------ example configs --------
// auth0
//   discovery https://dev-vqyews6b.us.auth0.com/.well-known/openid-configuration
//   clientId: yizu58OB6VDGGgpNKU9xi8dBfYK0SgK7
//   userNameProperty: nickname
//   usePkce: true
//   tokenProperty: id_token
//
// azure
//   discovery https://login.microsoftonline.com/160f60e9-c3e1-483b-9178-bd2f23cf9ef5/v2.0/.well-known/openid-configuration?appid=aeb8cc48-300c-4d3c-b248-5fa27a7f5a95
//   clientId: aeb8cc48-300c-4d3c-b248-5fa27a7f5a95
//   additional scope: api://ee1a9df7-cbcb-423c-bc45-587bac1354ed/admin
//   userNameProperty: preferred_username
//   usePkce: true
//   useIdTokenForUserInfo: true

export default class OidcSession extends BaseOidcSession {
  @service dataPreloader;
  @service systemConfig;

  get isOidc() {
    return true;
  }

  get loginRoute() {
    return 'loginOidc';
  }

  get isActuallyAuthenticated() {
    return this.isAuthenticated;
  }

  async setup() {
    const oidcConfig = await this.systemConfig.getOidcConfig.perform();
    const discoveryRequest = await fetch(oidcConfig.configUri);
    const discovery = await discoveryRequest.json();

    const simpleAuthConfig = {};

    let scope = 'openid profile';

    if (!isBlank(oidcConfig.additionalScopes)) {
      scope = `${scope} ${oidcConfig.additionalScopes}`;
    }

    if (!isBlank(oidcConfig.audience)) {
      simpleAuthConfig.audience = oidcConfig.audience;
    }

    if (!isBlank(oidcConfig.clientSecret)) {
      simpleAuthConfig.clientSecret = oidcConfig.clientSecret;
    }

    if (!isBlank(oidcConfig.tokenProperty)) {
      simpleAuthConfig.tokenPropertyName = oidcConfig.tokenProperty;
    }

    if (!oidcConfig.useIdTokenForUserInfo) {
      simpleAuthConfig.userinfoEndpoint = discovery.userinfo_endpoint;
    }

    Object.assign(simpleAuthConfig, {
      host: discovery.issuer,
      authEndpoint: discovery.authorization_endpoint,
      tokenEndpoint: discovery.token_endpoint,
      enablePkce: oidcConfig.usePkce,
      clientId: oidcConfig.clientId,
      userNameProperty: oidcConfig.userNameProperty,
      scope,
    });

    config['ember-simple-auth-oidc'] = simpleAuthConfig;

    await super.setup(...arguments);
  }

  async handleAuthentication(routeAfterAuthentication) {
    await this.dataPreloader.loadCurrentUserAndData.perform();
    super.handleAuthentication(routeAfterAuthentication);
  }

  handleInvalidation(routeAfterInvalidation) {
    window.location.replace(routeAfterInvalidation);
  }
}
