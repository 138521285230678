/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { all, task, taskGroup, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import getDelayTime from 'eflex/util/get-delay-time';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';
import { addObjectsIfNotPresent } from 'eflex/util/array-helpers';
import { sortByProp } from 'ramda-adjunct';

const TASK_CARD_WIDTH = 124;

export default class WorkInstructionEditorTaskLibrary extends Component {
  @service workInstructionRepo;
  @service workInstructionCache;

  @tracked pageNumber = 1;
  @tracked recordCount = 0;
  @tracked filteredFolders = new TrackedArray();
  @tracked filteredWorkInstructions = new TrackedArray();
  @tracked searchTerm;
  @tracked currentIndex = 0;
  @tracked maxCardsDisplayed = 5;
  @tracked selectedFolder = this.args.currentWorkInstruction?.folder;

  previousConfig = this.args.config;
  previousCurrentWorkInstruction = this.args.currentWorkInstruction;

  @taskGroup({ restartable: true }) loadTasks;

  get allCards() {
    const selectableFolders = this._selectableFolders ?? [];
    const selectableWorkInstructions = this._selectableWorkInstructions ?? [];
    return selectableFolders.concat(selectableWorkInstructions);
  }

  get visibleCards() {
    return this.allCards.slice(this.currentIndex, this.currentIndex + this._numCards);
  }

  get disableNext() {
    if (this._showLoadButton) {
      return false;
    } else {
      return this.allCards.length <= this.currentIndex + this._numCards;
    }
  }

  get showSpinner() {
    return this._search.isRunning ||
      this.loadFolder.isRunning ||
      this._resetList.isRunning ||
      this._loadMore.isRunning;
  }

  get _numCards() {
    if (this.selectedFolder != null) {
      return this.maxCardsDisplayed - 1;
    } else {
      return this.maxCardsDisplayed;
    }
  }

  get _selectableFolders() {
    if (this.selectedFolder != null) {
      return [];
    }

    return sortByProp('name', this.filteredFolders ?? []);
  }

  get _selectableWorkInstructions() {
    let instructions = this.filteredWorkInstructions.filter(workInstruction => workInstruction.deployed);

    if (this.selectedFolder) {
      instructions = instructions.filter(workInstruction => isPresent(workInstruction.folder));
    } else if (isEmpty(this.searchTerm)) {
      instructions = instructions.filter(workInstruction => workInstruction.folder == null);
    }

    return sortByProp('name', instructions);
  }

  get _showLoadButton() {
    return this._workInstructionCount > 0 && this._workInstructionCount < this.recordCount;
  }

  get _workInstructionCount() {
    return this._selectableWorkInstructions.length + this._selectableFolders.length;
  }

  onDidInsert = task({ group: 'loadTasks' }, waitFor(async element => {
    await this._resetList.perform();
    this.onResize(element);
    this._jumpCarousel();
  }));

  onDidUpdate = task(
    { group: 'loadTasks' },
    waitFor(async (element, [currentWorkInstruction, config]) => {
      const configChanged = this.previousConfig !== config || config?.isDirty;
      const currentWorkInstructionChanged = currentWorkInstruction !== this.previousCurrentWorkInstruction;

      Object.assign(this, {
        previousConfig: config,
        previousCurrentWorkInstruction: currentWorkInstruction,
        selectedFolder: currentWorkInstruction?.folder,
      });

      if (configChanged) {
        await this._resetList.perform();
        this._jumpCarousel();
      } else if (currentWorkInstruction && currentWorkInstructionChanged) {
        this._addRecordToFilteredInstructions();
      }
    }),
  );

  clickNext = task(waitFor(async () => {
    const cardLength = this.visibleCards.length;

    if (cardLength === this._numCards) {
      this.currentIndex = this.currentIndex + this._numCards;
    }

    if (this.currentIndex + cardLength >= this._workInstructionCount && this._showLoadButton) {
      this.pageNumber += 1;
      await this._loadMore.perform();
    }
  }));

  onSearchKeyUp = task({ restartable: true }, waitFor(async ({ target }) => {
    Object.assign(this, {
      searchTerm: target.value,
      currentIndex: 0,
    });

    if (!isEmpty(this.searchTerm)) {
      await timeout(getDelayTime(300));
      await this._search.perform();
    } else {
      await this._resetList.perform();
    }
  }));

  onClearSearch = task(waitFor(async () => {
    this.currentIndex = 0;
    await this._resetList.perform();
  }));

  onSearchClicked = task(waitFor(async () => {
    this.currentIndex = 0;
    await this._search.perform();
  }));

  loadFolder = task(waitFor(async folder => {
    this.currentIndex = 0;
    if (folder == null && this.selectedFolder.isInvalid) {
      // Reset the folder to have its previous (i.e. non-blank) name.
      this.selectedFolder.rollbackAttributes();
    }

    Object.assign(this, {
      searchTerm: null,
      pageNumber: 1,
      selectedFolder: folder,
    });

    if (folder == null) {
      await this._resetList.perform();
      return;
    }

    const { instructions, instructionCount } = await this._queryInstructions.perform();

    Object.assign(this, {
      recordCount: instructionCount,
      filteredWorkInstructions: new TrackedArray(instructions),
      filteredFolders: new TrackedArray(),
    });
  }));

  _search = task({ restartable: true }, waitFor(async () => {
    this.pageNumber = 1;

    if (isEmpty(this.searchTerm)) {
      return;
    }

    const { folders, instructions, count } = await this._queryFoldersAndInstructions.perform();

    Object.assign(this, {
      recordCount: count,
      filteredFolders: new TrackedArray(folders),
      filteredWorkInstructions: new TrackedArray(instructions),
    });
  }));

  _loadMore = task(waitFor(async () => {
    let count, folders, instructions;
    if (this.selectedFolder == null) {
      ({ folders, instructions, count } = await this._queryFoldersAndInstructions.perform());
    } else {
      folders = [];
      ({ instructions, instructionCount: count } = await this._queryInstructions.perform());
    }

    addObjectsIfNotPresent(this.filteredWorkInstructions, instructions);
    addObjectsIfNotPresent(this.filteredFolders, folders);
    this.recordCount = count;
  }));

  _resetList = task({ restartable: true }, waitFor(async () => {
    let count, folders, instructions;
    Object.assign(this, {
      searchTerm: null,
      pageNumber: 1,
    });

    if (this.selectedFolder == null) {
      ({ folders, instructions, count } = await this._queryFoldersAndInstructions.perform());
    } else {
      folders = [];
      ({ instructions, instructionCount: count } = await this._queryInstructions.perform());
    }

    Object.assign(this, {
      recordCount: count,
      filteredFolders: new TrackedArray(folders),
      filteredWorkInstructions: new TrackedArray(instructions),
    });

    this._addRecordToFilteredInstructions();
  }));

  _queryInstructions = task(waitFor(async () => {
    const query = {
      searchTerm: this.searchTerm,
      pageNumber: this.pageNumber,
      folder: this.selectedFolder?.id,
      deployed: true,
    };

    return await this.workInstructionCache.queryInstructions.perform(query);
  }));

  _queryFoldersAndInstructions = task(waitFor(async () => {
    const [{ instructions, instructionCount }, { folders, folderCount }] = await all([
      this._queryInstructions.perform(),
      this.workInstructionCache.queryFolders.perform({
        searchTerm: this.searchTerm,
        pageNumber: this.pageNumber,
      }),
    ]);

    return {
      folders,
      instructions,
      count: folderCount + instructionCount,
    };
  }));

  @action
  onCardDoubleClick(workInstruction) {
    if (workInstruction.id === this.args.currentWorkInstruction?.id) {
      return;
    }

    this.args.loadWorkInstruction?.(workInstruction);
  }

  @action
  onResize(element) {
    const { width } = element.getBoundingClientRect();
    const _numCardsToFit = Math.floor(width / TASK_CARD_WIDTH);

    if (_numCardsToFit === this.maxCardsDisplayed) {
      return;
    }

    this.maxCardsDisplayed = _numCardsToFit;
  }

  @action
  onClickPrevious() {
    this.currentIndex = this.currentIndex - this._numCards;
  }

  _addRecordToFilteredInstructions() {
    if (
      this.args.currentWorkInstruction == null ||
      this.args.currentWorkInstruction.isDestroyed ||
      this.filteredWorkInstructions.includes(this.args.currentWorkInstruction)
    ) {
      return;
    }

    this.filteredWorkInstructions.push(this.args.currentWorkInstruction);

    if (this._showLoadButton) {
      this.recordCount += 1;
    }
  }

  _jumpCarousel() {
    if (this.args.currentWorkInstruction == null) {
      return;
    }

    let index = this.allCards.indexOf(this.args.currentWorkInstruction);

    if (index === -1) {
      return;
    }

    while (index % this._numCards !== 0) {
      index -= 1;
    }

    this.currentIndex = index;
  }
}
