import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { task, all, waitForProperty } from 'ember-concurrency';
import { anyInvalid } from 'eflex/util/getter-helpers';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { PERMISSION_NAMES } from 'eflex/constants/user-permissions';

export default class HardwareController extends Controller {
  @service validationErrorNotifier;
  @service hardwareRepo;
  @service licensing;
  @service currentUser;
  @service configurationHistoryRepo;
  @service systemConfig;

  @tracked hardwareToDelete;
  @tracked filterBy;
  @tracked selectedHardware;
  @tracked locationsToUpdateVersion = [];
  @tracked hideTransitionModal = false;
  @tracked showConfigurationHistoryNotesModal = false;

  @tracked showHardwarePanel = false;

  permission = PERMISSION_NAMES.hardware;

  get nonDeletedHardwares() {
    return this.hardwares.filter(item => !item.isDeleted);
  }

  get hardwareIos() {
    return this.hardwares.flatMap(hardware => hardware.hardwareIos);
  }

  get nonDeletedHardwareIos() {
    return this.hardwareIos.filter(item => !item.isDeleted);
  }

  get hasPermission() {
    return this.currentUser.user.hasPermission(this.permission);
  }

  get isDirty() {
    return this.hasDirtyHardware || this.hasDirtyHardwareIo;
  }

  get isInvalid() {
    return this.hardwaresInvalid || this.hardwareIosInvalid;
  }

  get hasDirtyHardware() {
    return this.hardwares.some(item => item.isDirty);
  }

  get hasDirtyHardwareIo() {
    return this.hardwareIos.some(item => item.isDirty);
  }

  get hardwaresInvalid() {
    return anyInvalid(this.hardwares);
  }

  get hardwareIosInvalid() {
    return anyInvalid(this.hardwareIos);
  }

  get hardwares() {
    return this.hardwareRepo.getAllAssignableHardware();
  }

  get displayedHardware() {
    return this.nonDeletedHardwares.filter(hardware => {
      const substring = this.filterBy ?? '';
      return hardware.displayName.toLowerCase().includes(substring.toLowerCase());
    });
  }

  get hardwareOptions() {
    return this.hardwareRepo.getAllAssignableHardwareTypes();
  }

  save = task({ drop: true }, waitFor(async () => {
    if (this.isInvalid) {
      this.validationErrorNotifier.sendErrors(this.nonDeletedHardwares);
      this.validationErrorNotifier.sendErrors(this.nonDeletedHardwareIos);
      return;
    }

    let updateLocations = [];

    await all(this.hardwareIos.filter(item => item.isDirty).map(async hardwareIo => {
      await hardwareIo.save();
      updateLocations = updateLocations.concat(hardwareIo.hardware.allStationOptions);
    }));
    await all(this.hardwares.filter(item => item.isDirty).map(async hardware => {
      await hardware.save();
      updateLocations = updateLocations.concat(hardware.allStationOptions);
    }));

    if (this.systemConfig.jem?.configurationHistory && updateLocations.length > 0) {
      Object.assign(this, {
        showConfigurationHistoryNotesModal: true,
        hideTransitionModal: true,
        locationsToUpdateVersion: updateLocations,
      });
      await waitForProperty(this, 'showConfigurationHistoryNotesModal', false);
      Object.assign(this, {
        showConfigurationHistoryNotesModal: false,
        hideTransitionModal: false,
        locationsToUpdateVersion: [],
      });
    }
  }));

  @action
  rollback() {
    if (this.selectedHardware?.isDeleted || this.selectedHardware?.isNew) {
      this.closeHardwarePanel();
    }

    this.hardwares.forEach(hardware => { hardware.rollbackAttributes(); });
    this.hardwareIos.forEach(hardwareIo => { hardwareIo.rollbackAttributes(); });
  }

  @action
  addHardware(hardwareType) {
    const hardware = this.hardwareRepo.createHardware(hardwareType);
    this.openHardwarePanel(hardware);
  }

  @action
  deleteHardware(hardware) {
    this.hardwareToDelete = null;
    hardware.hardwareIos.forEach(hardwareIo => { hardwareIo.deleteRecord(); });
    hardware.deleteRecord();
  }

  @action
  toggleDeleteWarning(hardware, event) {
    if (hardware.isInUse) {
      event.preventDefault();
      event.stopPropagation();
      this.hardwareToDelete = hardware;
    } else {
      this.deleteHardware(hardware);
    }
  }

  @action
  openHardwarePanel(hardware) {
    this.selectedHardware = hardware;
    this.showHardwarePanel = true;
  }

  @action
  closeHardwarePanel() {
    this.showHardwarePanel = false;
    this.selectedHardware = null;
  }
}
