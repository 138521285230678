import PowerSelectWithCreate from './power-select-with-create';
import { ensureSafeComponent } from '@embroider/util';
import PowerSelectMultipleComponent from 'ember-power-select/components/power-select-multiple';

export default class PowerSelectMultipleWithCreate extends PowerSelectWithCreate {
  get powerSelectComponent() {
    return ensureSafeComponent(
      this.args.powerSelectComponent ?? PowerSelectMultipleComponent,
      this,
    );
  }

  selectOrCreate = (selection, select) => {
    const suggestion = selection.find((option) => {
      return option.__isSuggestion__;
    });

    if (suggestion) {
      this.args.onCreate(suggestion.__value__, select);
    } else {
      this.args.onChange(selection, select);
    }
  };
}
