import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import HardwareSelect from 'eflex/components/hardware-select';
import { t } from 'ember-intl';
import { array, fn } from '@ember/helper';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { selectVal, triggerTypes } from 'eflex/helpers';
import { CameraTriggerValues } from 'eflex/constants/tasks/trigger-types';
export default class TaskDeviceConfigImageCapture extends Component {
    get showWebcamDelay() {
        return [
            CameraTriggerValues.captureAdvance,
            CameraTriggerValues.taskActive
        ].includes(this.args.task.triggerType);
    }
    static{
        template(`
    <div class="row form-row">
      <div class="col-6">
        <HardwareSelect
          @types={{array 'web-cam'}}
          @model={{@task}}
          @form={{@form}}
          @default={{unless @task.usesOperations (t "webCam")}}
        />
      </div>
      <div class="col-6">
        <@form.element
          class="select-trigger-type"
          @controlType="power-select"
          @value={{findBy "value" @task.triggerType (triggerTypes "imageCapture")}}
          @label={{t "plant.task.errorproofing.trigger"}}
          @options={{triggerTypes "imageCapture"}}
          @onChange={{selectVal (fn (mut @task.triggerType))}}
        as |el|>
          <el.control as |item|>
            {{t item.label}}
          </el.control>
        </@form.element>
      </div>
    </div>
    <div class="row form-row">
      {{#if this.showWebcamDelay}}
        <div class="col-6 offset-6">
          <@form.element
            class="webcam-delay"
            @controlType="number"
            @property="webcamDelay"
            @label={{t "taskConfig.delay"}}
          />
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
