import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import moment from 'moment-timezone';
import { service } from '@ember/service';
import BsTooltip from 'eflex/components/bs-tooltip';
import { t } from 'ember-intl';
import PortalTarget from 'ember-stargate/components/portal-target';
export default class Footer extends Component {
    @service
    version;
    year = moment().get('year');
    get productVersion() {
        if (this.args.version) {
            return this.version.getVersion(this.args.version);
        } else {
            return this.version.getVersion();
        }
    }
    static{
        template(`
    <div
      class="main-footer d-flex align-items-center bg-gray-100 text-gray-900 fs-5 fw-semibold hidden-print px-3 py-2"
      ...attributes
    >
      <div class="version-wrapper d-flex">
        <BsTooltip @triggerEvents="hover">
          {{this.version.realVersion}}
        </BsTooltip>
        <div class="me-3">
          {{t "footer"}} © {{this.year}}.
        </div>
        <div class="version-text">
          {{t "version"}} {{this.productVersion}}
        </div>
      </div>
      <PortalTarget class="footer-portal d-flex align-items-center ms-auto" @name='footer-portal' />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
