import { template } from "@ember/template-compiler";
import smallestAvailable from 'eflex/util/smallest-available';
import DATA_TYPES from 'eflex/constants/process-data-types';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { mapBy, findBy } from '@eflexsystems/ember-composable-helpers';
import { fn } from '@ember/helper';
import { eq } from 'ember-truth-helpers';
import ProcessDataUnits from 'eflex/components/process-data-units';
import TrashCan from 'eflex/components/trash-can';
import AddButton from 'eflex/components/add-button';
export default class TaskConfigButton extends Component {
    @service
    jemProcessDataDefRepo;
    @service
    licensing;
    get nonDeletedDefs() {
        return this.args.task?.jemProcessDataDefs.filter((item1)=>!item1.isDeleted);
    }
    get bitDefs() {
        return this.jemProcessDataDefRepo.jemProcessDataDefs.filter((item1)=>item1.type === 'bit');
    }
    get units() {
        return this.jemProcessDataDefRepo.jemProcessDataDefs.filter((item1)=>item1.type !== 'bit').map((item1)=>item1.unit);
    }
    add = ()=>{
        this.jemProcessDataDefRepo.createProcessDataDef({
            displayedStart: smallestAvailable(this.nonDeletedDefs?.map((item1)=>item1.displayedStart)),
            task: this.args.task,
            type: 'ascii'
        });
    };
    delete = (def1)=>{
        if (this.args.disabled) {
            return;
        }
        this.jemProcessDataDefRepo.deleteProcessDataDef(def1);
    };
    selectedDataDefType = (dataDef1, selected1)=>{
        dataDef1.type = selected1.value;
    };
    static{
        template(`
    {{#if this.licensing.license.processDataEnabled}}
      <div class="bootstrap-table bg-gray-100 process-data-config component-task-config-button">
        <div class="row form-row header-row align-items-end">
          <div class="col">
            {{t "name"}}
          </div>
          <div class="col-auto">
            <div class="width-rem-6">
              {{t "dataType"}}
            </div>
          </div>
          <div class="col-6">
            {{t "unitsAndLimits"}}
          </div>
          {{#unless @task.isAuto}}
            <div class="col-1">
              <div class="model-specific">
                {{t "required"}}
              </div>
            </div>
          {{/unless}}
          <div class="col-icon border-0"></div>
        </div>
        {{#each this.nonDeletedDefs as |def|}}
          <div class="row form-row def-row align-items-end">
            <div class="col">
              <@form.element
                @controlType="text"
                @model={{def}}
                @property="name"
              as |el|>
                <el.control class="name-col" />
              </@form.element>
            </div>

            <div class="col-auto">
              <div class="width-rem-6 type-col">
                {{#if def.isNew}}
                  <@form.element
                    class="data-type-select"
                    @controlType="power-select"
                    @options={{DATA_TYPES}}
                    @value={{findBy "value" def.type DATA_TYPES}}
                    @onChange={{fn this.selectedDataDefType def}}
                  as |el|>
                    <el.control
                      @triggerClass="form-control"
                    as |type|>
                      {{t type.label}}
                    </el.control>
                  </@form.element>
                {{else}}
                  <@form.element>
                    <div class="data-type">
                      {{#let (findBy "value" def.type DATA_TYPES) as |type|}}
                        {{t type.label}}
                      {{/let}}
                    </div>
                  </@form.element>
                {{/if}}
              </div>
            </div>
            <div class="col-6">
              {{#if (eq def.type "bit")}}
                <div class="row align-items-end">
                  <div class="col">
                    <@form.element>
                      <ProcessDataUnits
                        class='test-process-datum-unit'
                        @selected={{def.unit}}
                        @processDataDef={{def}}
                        @units={{mapBy "unit" this.bitDefs}}
                        @disabled={{@disabled}}
                        @onChange={{fn (mut def.unit)}}
                      />
                    </@form.element>
                  </div>
                  <div class="col">
                    <@form.element>
                      <ProcessDataUnits
                        @selected={{def.falseUnit}}
                        @processDataDef={{def}}
                        @units={{mapBy "falseUnit" this.bitDefs}}
                        @disabled={{@disabled}}
                        @onChange={{fn (mut def.falseUnit)}}
                      />
                    </@form.element>
                  </div>
                </div>
              {{else if (eq def.type "float")}}
                <div class="row align-items-end">
                  <div class="col">
                    <@form.element>
                      <ProcessDataUnits
                        @selected={{def.unit}}
                        @processDataDef={{def}}
                        @units={{this.units}}
                        @disabled={{@disabled}}
                        @onChange={{fn (mut def.unit)}}
                      />
                    </@form.element>
                  </div>
                  {{#let (findBy "jemProcessDataDef" def @taskConfig.jemProcessDataDefConfigs) as |config|}}
                    <div class="col limits">
                      <@form.element
                        @controlType="number"
                        class="model-specific"
                        @model={{config}}
                        @property="lowerLimit"
                        @label={{t "lowerLimit"}}
                      />
                    </div>
                    <div class="col limits">
                      <@form.element
                        @controlType="number"
                        class="model-specific"
                        @model={{config}}
                        @property="upperLimit"
                        @label={{t "upperLimit"}}
                      />
                    </div>
                    <div class="col-auto limits">
                      <@form.element
                        class="model-specific width-rem-3"
                        @controlType="number"
                        @model={{config}}
                        @label={{t "decimals"}}
                        @property="decimalLength"
                      as |el|>
                        <el.control min={{0}} max={{10}} />
                      </@form.element>
                    </div>
                  {{/let}}
                </div>
              {{else}}
                <@form.element>
                  <ProcessDataUnits
                    @selected={{def.unit}}
                    @processDataDef={{def}}
                    @units={{this.units}}
                    @disabled={{@disabled}}
                    @onChange={{fn (mut def.unit)}}
                  />
                </@form.element>
              {{/if}}
            </div>

            {{#unless @task.isAuto}}
              <div class="col-1 d-flex justify-content-center">
                <@form.element
                  class="model-specific"
                  @controlType="checkbox"
                  @property="required"
                  @model={{findBy "jemProcessDataDef" def @taskConfig.jemProcessDataDefConfigs}}
                />
              </div>
            {{/unless}}
            <div class="col-icon align-self-center">
              <TrashCan
                class='delete-process-datum'
                disabled={{@disabled}}
                @onDelete={{fn this.delete def}}
              />
            </div>
          </div>
        {{/each}}

        <div class="row form-row no-hover-row">
          <div class="col-auto">
            <AddButton
              class="add test-add-process-datum"
              @disabled={{@disabled}}
              @onClick={{this.add}}
            >
              {{t "addProcessDataValue"}}
            </AddButton>
          </div>
        </div>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
