import Service, { service } from '@ember/service';

export default class JemProcessDataDefRepoService extends Service {
  @service store;

  jemProcessDataDefs = this.store.peekAll('jemProcessDataDef');

  createProcessDataDef(defParams) {
    const def = this.store.createRecord('jemProcessDataDef', defParams);

    def.task.taskConfigs.forEach((taskConfig) => {
      this.store.createRecord('jemProcessDataDefConfig', {
        jemProcessDataDef: def,
        taskConfig,
      });
    });

    return def;
  }

  deleteProcessDataDef(def) {
    def.edhrMappings.forEach(edhrMapping => { edhrMapping.deleteRecord(); });
    def.jemProcessDataDefConfigs.forEach(config => { config.deleteRecord(); });
    def.deleteRecord();
  }
}
