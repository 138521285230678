/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { prop, pluck, filter } from 'ramda';
import { mapIndexed } from 'ramda-adjunct';

export default class TaskSequenceCleaner extends Component {
  #compressGaps(tasks, sequenceType) {
    const usedGaps = [true];
    tasks.forEach(task => {
      const size = sequenceType === 'row' ? task.sizeY : 1;
      for (let i = task[sequenceType]; i < task[sequenceType] + size; i++) {
        usedGaps[i] = true;
      }
    });

    const availableGaps = intoArray(
      mapIndexed((p, index) => ({
        available: p == null,
        position: index,
      })),
      filter(prop('available')),
      pluck('position'),
    )(usedGaps);

    tasks.forEach(task => {
      const availableBefore = filter((a) => task[sequenceType] > a, availableGaps);
      task[sequenceType] = task[sequenceType] - availableBefore.length;
    });
  }

  @action
  onClick() {
    this.#compressGaps(this.args.tasks, 'row');
    this.#compressGaps(this.args.tasks, 'column');
  }
}
