import Service, { service } from '@ember/service';
import { camelize, dasherize } from '@ember/string';
import { isString } from 'ramda-adjunct';

export default class TriggerRepoService extends Service {
  @service store;

  adamIoTriggers = this.store.peekAll('adamIoTrigger');
  audioTriggers = this.store.peekAll('audioTrigger');
  edhrTriggers = this.store.peekAll('edhrTrigger');
  lightGuideTriggers = this.store.peekAll('lightGuideTrigger');
  plcHardwareTriggers = this.store.peekAll('plcHardwareTrigger');
  nodeRedTriggers = this.store.peekAll('nodeRedTrigger');
  turckIoTriggers = this.store.peekAll('turckIoTrigger');
  workInstructionHardwareTriggers = this.store.peekAll('workInstructionHardwareTrigger');

  get allTriggers() {
    return this.adamIoTriggers
      .concat(this.audioTriggers)
      .concat(this.edhrTriggers)
      .concat(this.lightGuideTriggers)
      .concat(this.plcHardwareTriggers)
      .concat(this.nodeRedTriggers)
      .concat(this.turckIoTriggers)
      .concat(this.workInstructionHardwareTriggers);
  }

  createTrigger(hardwareOrType, location, event = 'start') {
    let hardwareType;

    if (
      location.type === 'task' &&
      location.usesOperations &&
      isString(hardwareOrType)
    ) {
      hardwareType = dasherize(hardwareOrType);
    } else {
      hardwareType = hardwareOrType.constructor.modelName;
    }

    const triggerType = `${hardwareType}-trigger`;
    const trigger = this.store.createRecord(triggerType, { event });

    if (location.type === 'task') {
      trigger.task = location;

      if (location.usesOperations) {
        Object.assign(trigger, {
          hardwareAssignedType: camelize(hardwareType),
          hardwareIndex: 1,
        });
      } else {
        trigger.hardware = hardwareOrType;
      }

      location.taskConfigs.forEach((taskConfig) => {
        this.createTriggerConfig(trigger, taskConfig);
      });
    } else if (location.isButton) {
      Object.assign(trigger, {
        oeeButton: location,
        hardware: hardwareOrType,
      });

      this.createTriggerConfig(trigger, location);
    } else {
      Object.assign(trigger, {
        station: location,
        hardware: hardwareOrType,
      });
      this.createTriggerConfig(trigger, location);
    }
  }

  createTriggerConfig(trigger, location) {
    const triggerConfigType = `${trigger.constructor.modelName}-config`;
    const triggerConfig = this.store.createRecord(triggerConfigType, {
      parentTrigger: trigger,
    });

    if (location.type === 'station') {
      triggerConfig.station = location;
    } else if (location.isButton) {
      triggerConfig.oeeButton = location;
    } else {
      triggerConfig.taskConfig = location;
    }
  }
}
