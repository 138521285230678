import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import DecisionTypes from 'eflex/constants/tasks/decision-types';
import Component from '@glimmer/component';
import { notEq, eq } from 'ember-truth-helpers';
import BsButtonGroup from 'ember-bootstrap/components/bs-button-group';
let JemTaskDecisionDetails = class JemTaskDecisionDetails extends Component {
    decisionTypes = DecisionTypes;
    get selectedOptions() {
        if (this.args.childStatus == null) {
            return [];
        }
        if (this.args.task.decisionType === DecisionTypes.singleChoice) {
            return this.args.childStatus.decisionProcessData.find((item1)=>item1.isSelected);
        } else {
            return this.args.childStatus.decisionProcessData.filter((item1)=>item1.isSelected);
        }
    }
    selectDecision = (decisions1)=>{
        if (!this.args.childStatus?.isStarted) {
            return;
        }
        this.args.childStatus.decisionProcessData.forEach((decisionProcessDatum1)=>{
            if (decisions1.includes?.(decisionProcessDatum1) || decisions1 === decisionProcessDatum1) {
                decisionProcessDatum1.isSelected = true;
            } else {
                decisionProcessDatum1.isSelected = false;
            }
        });
    };
    static{
        template(`
    <div ...attributes>
      <div class="mt-2">
        <div class="fs-4 fw-semibold text-gray-800 mb-2">
          {{@task.decisionLabel}}
        </div>
        {{#if (notEq @task.decisionType this.decisionTypes.passFail)}}
          <BsButtonGroup
            class="radio-btn-group d-flex flex-column"
            @type={{if (eq @task.decisionType this.decisionTypes.singleChoice) "radio" "checkbox"}}
            @value={{this.selectedOptions}}
            @onChange={{this.selectDecision}}
            @vertical={{true}}
          as |bg|>
            {{#each @childStatus.decisionProcessData as |decision|}}
              <bg.button
                @value={{decision}}
                disabled={{@disabled}}
                class="decision-button detail-text-value fw-bold text-gray-900"
              >
                <div title={{decision.name}} class="decision-option">
                  {{decision.name}}
                </div>
              </bg.button>
            {{/each}}
          </BsButtonGroup>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default template(`
{{yield
  (hash
    taskDetails=(component
      JemTaskDecisionDetails
      disabled=@disabled
      task=@task
      childStatus=@childStatus
    )
  )
}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
