import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import moment from 'moment-timezone';
import weekdays from 'eflex/constants/weekdays';
import SidePanel from 'eflex/components/side-panel';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
import TreeSelect from 'eflex/components/tree-select';
import { and, not } from 'ember-truth-helpers';
import { formatTime, formatDateWithoutTime } from 'eflex/helpers';
import { findBy } from '@eflexsystems/ember-composable-helpers';
import { fn } from '@ember/helper';
export default class SchedulePanel extends Component {
    @service
    scheduleRepo;
    @service
    store;
    get weekdays() {
        return weekdays;
    }
    updateStartDate = (date1)=>{
        this.scheduleRepo.updateDate(this.args.schedule, date1);
    };
    updateEndDate = (date1)=>{
        this.scheduleRepo.updateDate(this.args.schedule, date1, true);
    };
    updateStations = (stations1 = [])=>{
        const stationRecords1 = stations1.map((station1)=>{
            return this.store.peekRecord('station', station1.id);
        });
        this.args.schedule.stations = stationRecords1;
    };
    updateTime = (day1, type1, time1)=>{
        if (!time1) {
            day1[`${type1}Hours`] = null;
            day1[`${type1}Minutes`] = null;
            return;
        }
        time1 = moment.tz(time1, day1.schedule.timezone);
        day1[`${type1}Hours`] = time1.hours();
        day1[`${type1}Minutes`] = time1.minutes();
    };
    static{
        template(`
    <SidePanel
      @header={{t "schedule"}}
      @onClose={{@onClose}}
      class="schedule-panel component-schedule-panel"
      ...attributes
    >
      <div class="d-flex flex-column h-100 p-3">
        <BsForm
          @model={{@schedule}}
          @showValidations={{@showValidations}}
        as |form|>
          <form.element
            @controlType="text"
            @property="text"
            @label={{t "schedules.scheduleName"}}
            class="is-required schedule-name"
          as |el|>
            <el.control disabled={{@disabled}} />
          </form.element>

          <div class="row form-row">
            <div class="col is-required">
              <label class="form-label">
                {{t "stations"}}
              </label>
              <TreeSelect
                class="station-select"
                @checkable={{true}}
                @sourceTree={{@treeData}}
                @checkedIds={{@schedule.stationIds}}
                @placeholder={{t "codes.selectedStations"}}
                @checkAllText={{t "codes.allStations"}}
                @onCheckedChanged={{this.updateStations}}
                @minDepth={{2}}
                @disabled={{@disabled}}
              />
            </div>
          </div>

          <div class="row form-row">
            <div class="col">
              {{#if (and @schedule.canEditStart (not @disabled))}}
                <form.element
                  class="is-required start-date"
                  @controlType="date-picker"
                  @property="startDate"
                  @label={{t "schedules.startDate"}}
                  @onChange={{this.updateStartDate}}
                as |el|>
                  <el.control
                    class="w-100"
                    @allowInput={{true}}
                    @altInput={{true}}
                  />
                </form.element>
              {{else}}
                <form.element
                  class="start-date disabled"
                  @controlType="text"
                  @label={{t "schedules.startDate"}}
                  @value={{formatDateWithoutTime @schedule.startDate}}
                as |el|>
                  <el.control disabled={{true}} />
                </form.element>
              {{/if}}
            </div>
            <div class="col">
              {{#if (and @schedule.isActive (not @disabled))}}
                <form.element
                  class="is-required exp-date"
                  @controlType="date-picker"
                  @property="endDate"
                  @label={{t "schedules.expirationDate"}}
                  @onChange={{this.updateEndDate}}
                as |el|>
                  <el.control
                    class="w-100"
                    @allowInput={{true}}
                    @altInput={{true}}
                  />
                </form.element>
              {{else}}
                <form.element
                  class="exp-date disabled"
                  @controlType="text"
                  @label={{t "schedules.expirationDate"}}
                  @value={{formatDateWithoutTime @schedule.endDate}}
                as |el|>
                  <el.control disabled={{true}} />
                </form.element>
              {{/if}}
            </div>
          </div>
        </BsForm>

        <div>
          <hr>
        </div>

        <div class="row form-row">
          <div class="col-1 me-4"></div>
          <div class="col">
            <div class="mb-0">
              <label class="form-label">
                {{t "startTime"}}
              </label>
            </div>
          </div>
          <div class="col">
            <div class="mb-0">
              <label class="form-label">
                {{t "endTime"}}
              </label>
            </div>
          </div>
          <div class="col col-duration">
            <div class="mb-0">
              <label class="form-label">
                {{t "duration"}}
              </label>
            </div>
          </div>
        </div>
        <div class="eflex-scroll">
          {{#each this.weekdays as |weekday|}}
            {{#let (findBy "day" weekday.value @schedule.days) as |day|}}
              <BsForm
                class="form-day"
                @model={{day}}
                @showValidations={{@showValidations}}
              as |form|>
                <div class="row form-row">
                  <div class="col-1 me-4">
                    {{t weekday.label}}
                  </div>
                  {{#if (and @schedule.isActive (not @disabled))}}
                    <div class="col">
                      <form.element
                        class="is-required day-start-time"
                        @controlType="date-picker"
                        @property="startTime"
                        @onChange={{fn this.updateTime day "start"}}
                      as |el|>
                        <el.control
                          class="w-100"
                          @allowInput={{true}}
                          @altInput={{true}}
                          @enableTime={{true}}
                          @noCalendar={{true}}
                        />
                      </form.element>
                    </div>
                    <div class="col">
                      <form.element
                        class="is-required day-end-time"
                        @controlType="date-picker"
                        @property="endTime"
                        @onChange={{fn this.updateTime day "end"}}
                      as |el|>
                        <el.control
                          class="w-100"
                          @allowInput={{true}}
                          @altInput={{true}}
                          @enableTime={{true}}
                          @noCalendar={{true}}
                        />
                      </form.element>
                    </div>
                  {{else}}
                    <div class="col">
                      <form.element
                        @controlType="text"
                        @value={{formatTime day.startTime "LT"}}
                      as |el|>
                        <el.control disabled={{true}} />
                      </form.element>
                    </div>
                    <div class="col">
                      <form.element
                        @controlType="text"
                        @value={{formatTime day.endTime "LT"}}
                      as |el|>
                        <el.control disabled={{true}} />
                      </form.element>
                    </div>
                  {{/if}}

                  <div class="col col-duration">
                    {{day.duration}}
                  </div>

                </div>
              </BsForm>
            {{/let}}
          {{/each}}
          <div class="d-flex">
            <div class="mb-0 ms-auto">
              <label class="form-label">
                {{t "schedules.timezone" timezone=@schedule.timezone}}
              </label>
            </div>
            <div class="col col-duration"/>
          </div>
        </div>
      </div>
    </SidePanel>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
