import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import { service } from '@ember/service';
import { all, task, timeout, waitForProperty, waitForQueue } from 'ember-concurrency';
import { EflexObjTypes } from 'eflex/constants/work-instructions/tool-props';
import getDelayTime from 'eflex/util/get-delay-time';
import { fabric } from 'fabric';
import { getCanvasObjects } from 'eflex/util/fabric-helpers';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import BsButton from 'eflex/components/bs-button';
import { isEmpty } from 'ember-truth-helpers';
import { round } from 'ramda-adjunct';
export default class WorkInstructionEditorToolPropertiesCrop extends Component {
    @service
    imageEditor;
    @tracked
    backingImage;
    @tracked
    backingScreen;
    @tracked
    currentCropbox;
    @tracked
    cropWidth = 0;
    @tracked
    cropHeight = 0;
    @tracked
    cropApplied = true;
    cropEventsRemoved = false;
    onDidInsert = task(waitFor(async ()=>{
        await all([
            waitForProperty(this.imageEditor, 'canvas'),
            waitForQueue('afterRender')
        ]);
        await this._createCrop.perform();
        this.imageEditor.on('selection:created', this._createCrop.perform).on('selection:updated', this._updateCrop.perform).on('selection:cleared', this._clearCropListener.perform);
    }));
    _clearCropListener = task(waitFor(async ()=>{
        // not an async method but concurrency is required to prevent multiple firings in listener
        await this._clearCrop();
    }));
    applyCrop = task(waitFor(async ()=>{
        this.cropApplied = true;
        this.currentCropbox.eflex.cropApplied = true;
        const image1 = this._getImageByCropbox(this.currentCropbox);
        this.removeCropEvents();
        this.imageEditor.canvas.discardActiveObject();
        this._clearCrop(false);
        const newImage1 = await this._applyClipPath.perform(image1);
        this.imageEditor.enableHistory();
        this.imageEditor.canvas.fire('object:modified', {
            target: newImage1
        });
        this.imageEditor.trigger('wie:crop:applied', newImage1);
    }));
    _getIntersect = task(waitFor(async (line11, line21)=>{
        if (typeof line11 === 'object' && typeof line21 === 'object') {
            const intersect1 = {};
            intersect1[line11.key] = line11.value;
            intersect1[line21.key] = line21.value;
            return intersect1;
        } else {
            const { default: nerdamer1 } = await import('nerdamer/all');
            const eq1 = nerdamer1(`${line11} = ${line21}`);
            const xIntersect1 = eq1.solveFor('x')[0].valueOf();
            const yIntersect1 = nerdamer1(line11).evaluate({
                x: xIntersect1
            }).valueOf();
            return {
                x: xIntersect1,
                y: yIntersect1
            };
        }
    }));
    _onScaling = task({
        restartable: true
    }, waitFor(async (image1, controls1, clipPath1, itl1, itr1, ibl1, ibr1, imageWidth1, imageHeight1, topSlope1, leftSlope1, iTopEquation1, iLeftEquation1, iBottomEquation1, iRightEquation1, delay1 = 0)=>{
        if (delay1) {
            await timeout(getDelayTime(delay1));
        }
        controls1.setCoords();
        let { tl: ctl1, bl: cbl1 } = controls1.aCoords;
        // since the image is always rectangular we can interchange top/bottom or left/right slopes.
        let cTopEquation1 = this._getPointSlopeLine(topSlope1, ctl1);
        let cLeftEquation1 = this._getPointSlopeLine(leftSlope1, cbl1);
        const topAboveOrBelow1 = this._aboveOrBelowLine(itl1, itr1, ctl1);
        const validTop1 = topAboveOrBelow1 <= 0;
        if (!validTop1) {
            const topIntersect1 = await this._getIntersect.perform(iTopEquation1, cLeftEquation1);
            controls1.set({
                top: topIntersect1.y,
                left: topIntersect1.x
            });
            controls1.setCoords();
            ctl1 = controls1.aCoords.tl;
            cTopEquation1 = this._getPointSlopeLine(topSlope1, ctl1);
        }
        const leftAboveOrBelow1 = this._aboveOrBelowLine(itl1, ibl1, ctl1);
        const validLeft1 = leftAboveOrBelow1 >= 0;
        if (!validLeft1) {
            const leftIntersect1 = await this._getIntersect.perform(iLeftEquation1, cTopEquation1);
            controls1.set({
                top: leftIntersect1.y,
                left: leftIntersect1.x
            });
            controls1.setCoords();
            cbl1 = controls1.aCoords.bl;
            cLeftEquation1 = this._getPointSlopeLine(leftSlope1, cbl1);
        }
        const rightIntersect1 = await this._getIntersect.perform(iRightEquation1, cTopEquation1);
        const maxH1 = this._getLineLength(rightIntersect1, ibr1);
        const maxScaleY1 = maxH1 / imageHeight1;
        const bottomIntersect1 = await this._getIntersect.perform(iBottomEquation1, cLeftEquation1);
        const maxW1 = this._getLineLength(bottomIntersect1, ibr1);
        const maxScaleX1 = maxW1 / imageWidth1;
        if (controls1.scaleX > maxScaleX1) {
            controls1.scaleX = maxScaleX1;
        }
        if (controls1.scaleY > maxScaleY1) {
            controls1.scaleY = maxScaleY1;
        }
        clipPath1.top = controls1.top;
        clipPath1.left = controls1.left;
        clipPath1.scaleX = controls1.scaleX;
        clipPath1.scaleY = controls1.scaleY;
        image1.dirty = true;
        Object.assign(this, {
            cropWidth: controls1.getScaledWidth(),
            cropHeight: controls1.getScaledHeight()
        });
    }));
    _createCrop = task(waitFor(async ()=>{
        const image1 = this._activeImage();
        if (image1 == null) {
            return;
        }
        this.imageEditor.disableHistory();
        image1.set({
            lockMovementX: true,
            lockMovementY: true
        });
        const clipPath1 = new fabric.Rect({
            width: image1.getScaledWidth(),
            height: image1.getScaledHeight(),
            top: image1.top,
            left: image1.left,
            angle: image1.angle,
            absolutePositioned: true
        });
        const controls1 = new fabric.Rect({
            width: image1.getScaledWidth(),
            height: image1.getScaledHeight(),
            top: image1.top,
            left: image1.left,
            angle: image1.angle,
            fill: 'rgba(0, 0, 0, 0.1)',
            cornerColor: 'rgba(0, 0, 0, 0.9)',
            lockMovementX: true,
            lockMovementY: true,
            lockScalingFlip: true,
            eflex: {
                type: EflexObjTypes.CROPBOX,
                itemKey: image1.eflex.itemKey,
                childObject: true,
                cropApplied: false
            }
        });
        controls1.setControlsVisibility({
            mtr: false
        });
        this._initControlListeners(image1, controls1, clipPath1);
        await this._addBackingImage.perform(image1);
        this.imageEditor.canvas.add(controls1);
        image1.clipPath = clipPath1;
        image1.dirty = true;
        this.imageEditor.canvas.setActiveObject(controls1);
        this.imageEditor.canvas.renderAll();
        Object.assign(this, {
            currentCropbox: controls1,
            cropWidth: controls1.getScaledWidth(),
            cropHeight: controls1.getScaledHeight()
        });
    }));
    _updateCrop = task(waitFor(async ()=>{
        if (this.currentCropbox == null) {
            return;
        }
        this._clearCrop();
        await this._createCrop.perform();
    }));
    _applyClipPath = task(waitFor(async (image1)=>{
        const clone1 = await new Promise((resolve1)=>{
            image1.clone(resolve1);
        });
        clone1.setCoords();
        clone1.clipPath.setCoords();
        const { tl: itl1, tr: itr1, bl: ibl1 } = clone1.aCoords;
        const topSlope1 = this._getSlope(itr1, itl1);
        const leftSlope1 = this._getSlope(ibl1, itl1);
        const iTopEquation1 = this._getPointSlopeLine(topSlope1, itl1);
        const iLeftEquation1 = this._getPointSlopeLine(leftSlope1, itl1);
        const { tl: ctl1, bl: cbl1 } = clone1.clipPath.aCoords;
        const cTopEquation1 = this._getPointSlopeLine(topSlope1, ctl1);
        const cLeftEquation1 = this._getPointSlopeLine(leftSlope1, cbl1);
        const topIntersect1 = await this._getIntersect.perform(iTopEquation1, cLeftEquation1);
        const top1 = this._getLineLength(topIntersect1, ctl1);
        const leftIntersect1 = await this._getIntersect.perform(iLeftEquation1, cTopEquation1);
        const left1 = this._getLineLength(leftIntersect1, ctl1);
        const width1 = clone1.clipPath.getScaledWidth();
        const height1 = clone1.clipPath.getScaledHeight();
        clone1.clipPath = null;
        clone1.angle = 0;
        const dataUrl1 = clone1.toDataURL({
            multiplier: 1,
            top: top1,
            left: left1,
            width: width1,
            height: height1
        });
        const newImage1 = await new Promise((resolve1)=>{
            fabric.Image.fromURL(dataUrl1, resolve1);
        });
        newImage1.set({
            top: image1.clipPath.top,
            left: image1.clipPath.left,
            angle: image1.angle,
            eflex: image1.eflex
        });
        newImage1.eflex.id = null;
        this.imageEditor.canvas.remove(image1);
        this.imageEditor.canvas.add(newImage1);
        return newImage1;
    }));
    _addBackingImage = task(waitFor(async (image1)=>{
        const backingImage1 = await new Promise((resolve1)=>{
            image1.clone(resolve1);
        });
        let layer1 = this.imageEditor.getObjectIndex(image1);
        if (layer1 < 0) {
            layer1 = 0;
        }
        const backingScreen1 = new fabric.Rect({
            width: backingImage1.getScaledWidth(),
            height: backingImage1.getScaledHeight(),
            top: backingImage1.top,
            left: backingImage1.left,
            angle: backingImage1.angle,
            fill: 'rgba(255, 255, 255, 0.45)',
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            eflex: {
                childObject: true
            }
        });
        this.imageEditor.canvas.insertAt(backingScreen1, layer1);
        backingImage1.set({
            opacity: 0.75,
            lockMovementX: true,
            lockMovementY: true,
            selectable: false,
            clipPath: null,
            eflex: {
                childObject: true
            }
        });
        this.imageEditor.canvas.insertAt(backingImage1, layer1);
        Object.assign(this, {
            backingScreen: backingScreen1,
            backingImage: backingImage1
        });
    }));
    willDestroy() {
        super.willDestroy(...arguments);
        if (!this.cropApplied) {
            this.removeCropEvents();
        }
    }
    _clearCrop = (history1 = true)=>{
        if (!this.currentCropbox.eflex.cropApplied) {
            const image1 = this._getImageByCropbox(this.currentCropbox);
            this._revertCrop(image1);
        }
        this.imageEditor.canvas.remove(this.currentCropbox);
        this.imageEditor.canvas.remove(this.backingImage);
        this.imageEditor.canvas.remove(this.backingScreen);
        Object.assign(this, {
            cropApplied: true,
            currentCropbox: null,
            backingImage: null,
            backingScreen: null,
            cropWidth: 0,
            cropHeight: 0
        });
        if (history1) {
            this.imageEditor.enableHistory();
        }
    };
    _initControlListeners(image1, controls1, clipPath1) {
        const { tl: itl1, tr: itr1, bl: ibl1, br: ibr1 } = image1.aCoords;
        const imageWidth1 = image1.getScaledWidth();
        const imageHeight1 = image1.getScaledHeight();
        // image top slope and controls top slope should always be equal.
        // same for image left slope and controls left slope.
        const topSlope1 = this._getSlope(itr1, itl1);
        const leftSlope1 = this._getSlope(ibl1, itl1);
        const iTopEquation1 = this._getPointSlopeLine(topSlope1, itl1);
        const iLeftEquation1 = this._getPointSlopeLine(leftSlope1, itl1);
        const iBottomEquation1 = this._getPointSlopeLine(topSlope1, ibr1);
        const iRightEquation1 = this._getPointSlopeLine(leftSlope1, ibr1);
        // constrain controls to inside of existing image
        controls1.on('scaling', ()=>{
            this._onScaling.perform(image1, controls1, clipPath1, itl1, itr1, ibl1, ibr1, imageWidth1, imageHeight1, topSlope1, leftSlope1, iTopEquation1, iLeftEquation1, iBottomEquation1, iRightEquation1, 100);
        });
        controls1.on('modified', ()=>{
            this.cropApplied = false;
            controls1.eflex.cropApplied = false;
            if (this.currentCropbox) {
                this._onScaling.perform(image1, controls1, clipPath1, itl1, itr1, ibl1, ibr1, imageWidth1, imageHeight1, topSlope1, leftSlope1, iTopEquation1, iLeftEquation1, iBottomEquation1, iRightEquation1);
            }
        });
    }
    _revertCrop(image1) {
        image1.set({
            clipPath: null,
            dirty: true,
            lockMovementX: false,
            lockMovementY: false
        });
        this.imageEditor.canvas.renderAll();
    }
    _isImage(obj1) {
        return obj1 != null && obj1.type === 'image';
    }
    _activeImage() {
        const obj1 = this.imageEditor.canvas?.getActiveObject();
        if (this._isImage(obj1)) {
            return this.imageEditor.applyItemKey(obj1);
        }
    }
    _getImageByCropbox(cropbox1) {
        const images1 = getCanvasObjects(this.imageEditor.canvas, 'image').filter((item1)=>item1.eflex?.itemKey === cropbox1.eflex.itemKey);
        if (images1.length > 1) {
            throw new Error('More images than expected for cropbox.');
        }
        return images1[0];
    }
    _getSlope(p11, p21) {
        return (p21.y - p11.y) / (p21.x - p11.x);
    }
    _getPointSlopeLine(slope1, point1) {
        if (slope1.valueOf() === 0) {
            // horizontal line
            return {
                key: 'y',
                value: point1.y
            };
        } else if (!Number.isFinite(slope1.valueOf())) {
            // vertical line
            return {
                key: 'x',
                value: point1.x
            };
        } else {
            // point slope line eq: y = m(x - Px) + Py
            return `${slope1.toFixed(2)} * (x - ${point1.x.toFixed(2)}) + ${point1.y.toFixed(2)}`;
        }
    }
    _getLineLength(p11, p21) {
        return Math.hypot(((p21.x - p11.x)), ((p21.y - p11.y)));
    }
    _aboveOrBelowLine(p11, p21, checkPoint1) {
        // above or below eq: d=(x−x1)(y2−y1)−(y−y1)(x2−x1)
        return (checkPoint1.x - p11.x) * (p21.y - p11.y) - (checkPoint1.y - p11.y) * (p21.x - p11.x);
    }
    removeCropEvents() {
        if (this.cropEventsRemoved) {
            return;
        }
        this.cropEventsRemoved = true;
        this.imageEditor.off('selection:created', this._createCrop.perform).off('selection:updated', this._updateCrop.perform).off('selection:cleared', this._clearCropListener.perform);
    }
    cancelCrop = ()=>{
        const image1 = this._getImageByCropbox(this.currentCropbox);
        this.removeCropEvents();
        this.imageEditor.canvas.discardActiveObject();
        this._clearCrop();
        this.imageEditor.trigger('wie:crop:applied', image1);
    };
    static{
        template(`
    <div
      class="component-work-instruction-editor-tool-properties-crop wie-crop-panel"
      {{didInsert this.onDidInsert.perform}}
      ...attributes
    >
      <div class="eflex-scroll h-100">
        <h6 class="title">
          {{t "imageEditor.crop"}}
        </h6>

        <div class="property-flex-row size-group">
          <div class="size-label-container">
            <label class="form-label width-label">
              {{t "imageEditor.width"}}
            </label>
            <span class="times">
              &times;
            </span>
            <label class="form-label height-label">
              {{t "imageEditor.height"}}
            </label>
          </div>
          <div class="size-input-container">
            <input
              type="number"
              value={{round this.cropWidth}}
              min={{0}}
              step={{1}}
              disabled
              class="form-control width-input"
            />
            <div class="keep-aspect-ratio">
              <div class="icon icon-link disabled"></div>
            </div>
            <input
              type="number"
              value={{round this.cropHeight}}
              min={{0}}
              step={{1}}
              disabled
              class="form-control height-input"
            />
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="crop-buttons">
          <div class="property-flex-row mb-2">
            <BsButton
              class="text-uppercase crop-apply"
              @type="primary"
              @disabled={{this.cropApplied}}
              @onClick={{this.applyCrop.perform}}
            >
              {{t "apply"}}
            </BsButton>
          </div>
          <div class="property-flex-row">
            <BsButton
              class="text-uppercase crop-cancel"
              @type="secondary"
              @disabled={{isEmpty this.currentCropbox}}
              @onClick={{this.cancelCrop}}
            >
              {{t "cancel"}}
            </BsButton>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
    ;
}
