import Helper from '@ember/component/helper';
import { registerDestructor } from '@ember/destroyable';
import config from 'eflex/config/environment';

export default class BeforeUnload extends Helper {
  #func;
  #didSetup = false;

  compute(positional, { isDirty, onBeforeUnload }) {
    if (window.isIntegrationTest || config.environment !== 'production') {
      return;
    }

    if (!this.#didSetup) {
      this.#didSetup = true;
      registerDestructor(this, () => {
        this.#removeListener();
      });
    }

    if (isDirty) {
      if (!this.#func) {
        this.#func = onBeforeUnload;
        addEventListener('beforeunload', onBeforeUnload);
      }
    } else {
      this.#removeListener();
    }
  }

  #removeListener() {
    if (!this.#func) {
      return;
    }

    removeEventListener('beforeunload', this.#func);
    this.#func = null;
  }
}
