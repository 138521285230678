import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import { FROM_TYPES, TO_TYPES, FLOAT_BYTES, MAX_BYTES } from 'eflex/constants/plc-variable-data-types';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { add, pipe, pluck, sum } from 'ramda';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { append, findBy } from '@eflexsystems/ember-composable-helpers';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { eq } from 'ember-truth-helpers';
export default class TaskConfigPlc extends Component {
    @tracked
    currentVariableTypeTab = `deviceToVariables-${this.args.taskConfig.id}`;
    @service
    variableDefRepo;
    @service
    taskRepo;
    get variableDefConfigs() {
        return this.args.taskConfig?.variableDefConfigs.filter((item1)=>!item1.isDeleted);
    }
    get deviceToId() {
        return `deviceToVariables-${this.args.taskConfig.id}`;
    }
    get deviceFromId() {
        return `deviceFromVariables-${this.args.taskConfig.id}`;
    }
    get showDeviceFrom() {
        return this.currentVariableTypeTab === this.deviceFromId;
    }
    get displayVariables() {
        return this.variableDefConfigs?.filter((item1)=>item1.isFromDevice === this.showDeviceFrom) ?? [];
    }
    get filteredJemProcessDataDefs() {
        return this.allPreviousTasks?.flatMap((_task1)=>_task1.jemProcessDataDefs).filter((def1)=>!def1.isDeleted && def1.type === 'float') ?? [];
    }
    get filteredVariableDefs() {
        return this.allPreviousTasks?.flatMap((_task1)=>_task1.variableDefs).filter((def1)=>!def1.isDeleted && def1.isForPlc && def1.isFromDevice) ?? [];
    }
    get allPreviousTasks() {
        return this.taskRepo.getAllPreviousTasks(this.args.task);
    }
    get availableByteLengths() {
        const totalConsumed1 = pipe(pluck('bytesConsumed'), sum)(this.displayVariables);
        const availableBytes1 = MAX_BYTES - totalConsumed1;
        const result1 = [];
        for(let i1 = FLOAT_BYTES; i1 <= availableBytes1; i1 += FLOAT_BYTES){
            result1.push(i1);
        }
        return result1;
    }
    setVariableValue = (variableDefConfig1, value1)=>{
        const variableDef1 = variableDefConfig1.variableDef;
        switch(value1.constructor?.modelName){
            case 'variable-def':
                {
                    variableDefConfig1.value = null;
                    variableDef1.variableDefForValue = value1;
                    variableDef1.jemProcessDataDefForValue = null;
                    break;
                }
            case 'jem-process-data-def':
                {
                    variableDefConfig1.value = null;
                    variableDef1.variableDefForValue = null;
                    variableDef1.jemProcessDataDefForValue = value1;
                    break;
                }
            default:
                {
                    variableDefConfig1.value = value1;
                    variableDef1.variableDefForValue = null;
                    variableDef1.jemProcessDataDefForValue = null;
                }
        }
    };
    addMultipleVariables = (variableQuantity1)=>{
        for(let i1 = 0; i1 < variableQuantity1; i1++){
            this.variableDefRepo.createVariableDef({
                task: this.args.task,
                isFromDevice: this.showDeviceFrom
            });
        }
    };
    deleteVariable = (variableDef1)=>{
        this.variableDefRepo.deleteVariable(variableDef1);
    };
    onSelectedVariableType = (variableDefConfig1, selected1)=>{
        const variableDef1 = variableDefConfig1.variableDef;
        variableDefConfig1.value = null;
        variableDef1.length = null;
        variableDef1.variableDefForValue = null;
        variableDef1.jemProcessDataDefForValue = null;
        variableDef1.type = selected1.value;
    };
    static{
        template(`
    <NoRouteTabs
      @activeTab={{this.currentVariableTypeTab}}
      @tabBecameActive={{fn (mut this.currentVariableTypeTab)}}
    as |tabs|>
      <tabs.navigation class="mb-3" as |nav|>
        <nav.tab @href={{this.deviceToId}} class="device-to-variables">
          {{t "deviceToVariables"}}
        </nav.tab>
        <nav.tab @href={{this.deviceFromId}} class="device-from-variables">
          {{t "deviceFromVariables"}}
        </nav.tab>
      </tabs.navigation>
      <tabs.panes as |panes|>
        <panes.pane @id={{this.deviceToId}}>
          <div class="bootstrap-table">
            <div class="row form-row header-row">
              <div class="col-icon border-0" />
              <div class="col-icon border-0">
                <FaIcon @icon="hashtag" @prefix="fas" />
              </div>
              <div class="col">
                {{t "variableName"}}
              </div>
              <div class="col-auto">
                <div class="width-rem-6">
                  {{t "dataType"}}
                </div>
              </div>
              <div class="col-auto">
                <div class="width-rem-5">
                  {{t "length"}}
                </div>
              </div>
              <div class="col">
                {{t "variableValue"}}
              </div>
              <div class="col-icon border-0" />
            </div>
            {{#each this.displayVariables as |variableDefConfig index|}}
              {{#let variableDefConfig.variableDef as |variableDef|}}
                <div class="variable-row row form-row">
                  <div class="col-icon model-specific">
                    <@form.element
                      @controlType="checkbox"
                      @model={{variableDefConfig}}
                      @property="enabled"
                    />
                  </div>
                  <div class="col-icon">
                    <span>
                      {{add index 1}}
                    </span>
                  </div>
                  <div class="col">
                    <@form.element
                      @controlType="text"
                      @model={{variableDef}}
                      @property="name"
                      class="variable-name"
                    />
                  </div>
                  <div class="col-auto">
                    <div class="width-rem-6">
                      <@form.element
                        class="variable-type"
                        @controlType="power-select"
                        @options={{TO_TYPES}}
                        @value={{findBy "value" variableDef.type TO_TYPES}}
                        @onChange={{fn this.onSelectedVariableType variableDefConfig}}
                      as |el|>
                        <el.control as |type|>
                          {{t type.label}}
                        </el.control>
                      </@form.element>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="width-rem-5">
                      {{#if (eq variableDef.type "ascii")}}
                        <@form.element
                          @controlType="power-select"
                          @options={{this.availableByteLengths}}
                          @model={{variableDef}}
                          @property="length"
                          class="variable-length"
                        />
                      {{/if}}
                    </div>
                  </div>
                  <div class="col model-specific">
                    {{#if (eq variableDef.type "variable")}}
                      <@form.element
                        @controlType="power-select"
                        @options={{
                          append
                          this.filteredVariableDefs
                          this.filteredJemProcessDataDefs
                        }}
                        @optionLabelPath="name"
                        @model={{variableDef}}
                        @property="selectedValue"
                        @onChange={{fn this.setVariableValue variableDefConfig}}
                        class="variable-value"
                      as |el|>
                        <el.control
                          @searchEnabled={{true}}
                          @searchField="name"
                        />
                      </@form.element>
                    {{else}}
                      <@form.element
                        @controlType="text"
                        @model={{variableDefConfig}}
                        @property="value"
                        class="variable-value"
                      />
                    {{/if}}
                  </div>
                  <div class="col-icon">
                    <TrashCan
                      disabled={{@disabled}}
                      @onDelete={{fn this.deleteVariable variableDef}}
                    />
                  </div>
                </div>
              {{/let}}
            {{/each}}
            <div class="row form-row no-hover-row">
              <div class="col-auto">
                <QuantitySelect
                  class="px-2 text-uppercase"
                  @disabled={{@disabled}}
                  @onSubmit={{this.addMultipleVariables}}
                >
                  {{t "components.addVariable"}}
                </QuantitySelect>
              </div>
            </div>
          </div>
        </panes.pane>
        <panes.pane @id={{this.deviceFromId}}>
          <div class="bootstrap-table">
            <div class="row form-row header-row">
              <div class="col-icon border-0" />
              <div class="col-icon border-0">
                <FaIcon @icon="hashtag" @prefix="fas" />
              </div>
              <div class="col">
                {{t "variableName"}}
              </div>
              <div class="col-auto">
                <div class="width-rem-6">
                  {{t "dataType"}}
                </div>
              </div>
              <div class="col-auto">
                <div class="width-rem-5">
                  {{t "length"}}
                </div>
              </div>
              <div class="col-icon border-0" />
            </div>
            {{#each this.displayVariables as |variableDefConfig index|}}
              {{#let variableDefConfig.variableDef as |variableDef|}}
                <div class="row form-row variable-row">
                  <div class="col-icon models-specific">
                    <@form.element
                      @controlType="checkbox"
                      @model={{variableDefConfig}}
                      @property="enabled"
                    />
                  </div>
                  <div class="col-icon">
                    <span>
                      {{add index 1}}
                    </span>
                  </div>
                  <div class="col">
                    <@form.element
                      @controlType="text"
                      @model={{variableDef}}
                      @property="name"
                      class="variable-name"
                    />
                  </div>
                  <div class="col-auto">
                    <div class="width-rem-6">
                      <@form.element
                        class="variable-type"
                        @controlType="power-select"
                        @options={{FROM_TYPES}}
                        @value={{findBy "value" variableDef.type FROM_TYPES}}
                        @onChange={{fn this.onSelectedVariableType variableDefConfig}}
                      as |el|>
                        <el.control as |type|>
                          {{t type.label}}
                        </el.control>
                      </@form.element>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="width-rem-5">
                      {{#if (eq variableDef.type "ascii")}}
                        <@form.element
                          @controlType="power-select"
                          @options={{this.availableByteLengths}}
                          @model={{variableDef}}
                          @property="length"
                          class="variable-length"
                        />
                      {{/if}}
                    </div>
                  </div>
                  <div class="col-icon">
                    <TrashCan
                      disabled={{@disabled}}
                      @onDelete={{fn this.deleteVariable variableDef}}
                    />
                  </div>
                </div>
              {{/let}}
            {{/each}}
            <div class="row form-row no-hover-row">
              <div class="col-auto">
                <QuantitySelect
                  class="px-2 text-uppercase"
                  @disabled={{@disabled}}
                  @onSubmit={{this.addMultipleVariables}}
                >
                  {{t "components.addVariable"}}
                </QuantitySelect>
              </div>
            </div>
          </div>
        </panes.pane>
      </tabs.panes>
    </NoRouteTabs>

    <div class="row form-row model-specific">
      <div class="col-3">
        <@form.element
          @controlType="number"
          @label={{t "programNumber"}}
          @property="programNumber"
          class="program-number"
        as |el|>
          <el.control disabled={{@disabled}} />
        </@form.element>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
