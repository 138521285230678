import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { isBlank } from '@ember/utils';
import { uniq, filter } from 'ramda';
import { intoArray } from '@eflexsystems/ramda-helpers';
import { compact } from 'ramda-adjunct';
import PowerSelectWithCreate from 'eflex/components/power-select-with-create';
const getUniqueUnits = (units1)=>{
    return intoArray(filter((unit1)=>!isBlank(unit1)), compact, uniq)(units1 ?? []);
};
const showCreateWhen = (value1, units1)=>{
    return !units1.includes(value1);
};
export default class ProcessDataUnits extends Component {
    onCreate = (newName1)=>{
        this.args.onChange(newName1.trim());
    };
    onChange = (newUnit1)=>{
        if (newUnit1 === this.args.selected) {
            return;
        }
        this.args.onChange(newUnit1);
    };
    static{
        template(`
    <PowerSelectWithCreate
      class="unit-select {{@processDataDef.type}}"
      @triggerClass="form-control"
      @options={{getUniqueUnits @units}}
      @selected={{@selected}}
      @disabled={{@disabled}}
      @onChange={{this.onChange}}
      @onCreate={{this.onCreate}}
      @showCreateWhen={{showCreateWhen}}
      @searchEnabled={{true}}
      ...attributes
    as |unit|>
      {{unit}}
    </PowerSelectWithCreate>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
