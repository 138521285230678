/* eslint-disable indent */ import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import FormatDurationAxisBi from 'eflex/util/bi-duration-axis-formatter';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import { get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
export default class CycleTimeSerialNumber extends Component {
    // jscpd:ignore-start
    @service
    queryRunner;
    @service
    intl;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const serialCycleTimeQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $sort: {
                    timestamp: 1
                }
            },
            {
                $project: {
                    serialNumber: 1,
                    cycleTime: 1,
                    timestamp: 1
                }
            }
        ]);
        const serialCycleTimeData1 = await this.queryRunner.runQuery.perform('BuildStatuses', serialCycleTimeQuery1);
        return {
            chartData: this.getEchartOptions(serialCycleTimeData1),
            exportData: ()=>this.formatCycleTimeExportData(clone(serialCycleTimeData1))
        };
    // jscpd:ignore-end
    }));
    serialCycleTimeData = trackedTask(this, this.getData, ()=>[
            this.params
        ]);
    getEchartOptions(cycleTimeData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.cycleTimeBySerial')
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params1)=>{
                    const serialNumber1 = params1[1].data.name;
                    const tooltipData1 = params1[0];
                    return `<div style="text-align:center;">${serialNumber1}</div><br>
            <div>${tooltipData1.marker} ${tooltipData1.name}:  <span style="font-weight:bold;">
            ${moment.duration(tooltipData1.value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                        precision: 1,
                        trim: 'both mid'
                    })}
            </span></div>`;
                }
            },
            legend: {
                show: false,
                selected: {
                    'series 1': true,
                    'series 2': false
                }
            },
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    axisLabel: {
                        formatter: (value1)=>FormatDurationAxisBi(value1)
                    }
                },
                {
                    type: 'value',
                    show: false
                }
            ],
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: cycleTimeData1.map((data1)=>moment(data1.timestamp).format('MM/DD/YYYY hh:mm A')),
                axisLabel: {
                    show: true,
                    rotate: 75,
                    interval: 'auto'
                }
            },
            series: [
                {
                    yAxisIndex: 0,
                    name: this.intl.t('navigation.bi.cycleTime'),
                    data: cycleTimeData1.map((m1)=>m1.cycleTime),
                    type: 'line',
                    color: echartsTheme.colorPalette.biBlue,
                    markLine: {
                        data: [
                            {
                                type: 'average',
                                name: 'Avg'
                            }
                        ],
                        label: {
                            show: true,
                            position: 'insideEndTop',
                            formatter: (params1)=>`${this.intl.t('bi.chartLegend.avg')}: ${moment.duration(params1.value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                                    precision: 1,
                                    trim: 'both mid'
                                })}`
                        }
                    }
                },
                {
                    yAxisIndex: 1,
                    data: cycleTimeData1.map((m1)=>{
                        return {
                            name: m1.serialNumber,
                            value: 0
                        };
                    }),
                    type: 'line',
                    color: '#00000000'
                }
            ]
        };
    }
    formatCycleTimeExportData(cycleTimeData1) {
        cycleTimeData1.forEach((serialNumber1)=>{
            serialNumber1.cycleTime = moment.duration(serialNumber1.cycleTime, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                precision: 1,
                trim: 'both mid'
            });
            serialNumber1.timestamp = moment(serialNumber1.timestamp).format('MM/DD/YYYY hh:mm A');
            delete serialNumber1._id;
        });
        return cycleTimeData1;
    }
    static{
        template(`
    <div ...attributes>
      {{#if this.serialCycleTimeData.isRunning}}
        <Spinner />
      {{else if (get this.serialCycleTimeData.value.chartData.series '0.data.length')}}
        <div class="row h-100">
          <div class="col d-flex justify-content-between">
            <EChart
              @option={{this.serialCycleTimeData.value.chartData}}
              @exportData={{this.serialCycleTimeData.value.exportData}}
              class="h-100 w-100 cycle-time-serial-number"
            />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
