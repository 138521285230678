/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { service } from '@ember/service';
import { all, task, waitForProperty, waitForQueue } from 'ember-concurrency';
import { StrokeOptions } from 'eflex/constants/work-instructions/tool-props';
import { hexOpacityToDec, decOpacityToHex } from 'eflex/util/opacity-helper';
import WieSettings from 'eflex/constants/work-instructions/wie-settings';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { updateObjectProperty } from 'eflex/util/fabric-helpers';

const HEX_LENGTH = 7;

export default class WorkInstructionEditorToolPropertiesImage extends Component {
  @service imageEditor;
  @service systemConfig;
  @service workInstructionImageRepo;
  @service store;

  @tracked width = 1;
  @tracked height = 1;
  @tracked aspectRatio = 1;
  @tracked keepAspectRatio = true;
  @tracked fileName = '';
  @tracked selectedStrokeColor = '#000000';
  @tracked selectedStrokeOpacity = 100;
  @tracked selectedOpacity = 100;
  @tracked dynamicObjectName;
  @tracked startHidden = false;
  @tracked link = '';
  @tracked selectedStrokeWidth = 3;
  @tracked selectedStrokeStyle = StrokeOptions.default;
  @tracked strokeDashArray = [];
  @tracked calculatedStrokeColor = '#000000FF';

  supportedFileTypes = WieSettings.supportedFileTypes;

  get opacity() {
    return this.selectedOpacity / 100;
  }

  _isImage(obj) {
    return obj != null && obj.type === 'image';
  }

  _activeObject() {
    const obj = this.imageEditor.canvas?.getActiveObject();
    if (this._isImage(obj)) {
      return obj;
    } else {
      return null;
    }
  }

  resetProperties() {
    Object.assign(this, {
      dynamicObjectName: null,
      link: '',
    });
  }

  #updateFromSystemConfig = () => {
    this.resetProperties();
    const config = this.systemConfig.getWieDefaults('image');

    const strokeOpacity = config.stroke?.opacity ?? 0;
    const calculatedStrokeColor = config.stroke?.color + decOpacityToHex(strokeOpacity);

    Object.assign(this, {
      selectedOpacity: config.opacity,
      selectedStrokeStyle: config.stroke?.style,
      selectedStrokeWidth: config.stroke?.width,
      selectedStrokeColor: config.stroke?.color,
      selectedStrokeOpacity: strokeOpacity,
      calculatedStrokeColor,
    });
  };

  @action
  updateSelectedProperties() {
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    const stroke = obj.stroke ?? '#000000FF';
    Object.assign(this, {
      width: Number.parseInt(obj.getScaledWidth()),
      height: Number.parseInt(obj.getScaledHeight()),
      selectedOpacity: Number.parseInt(obj.opacity * 100),
      calculatedStrokeColor: stroke,
      selectedStrokeColor: stroke.slice(0, HEX_LENGTH),
      selectedStrokeOpacity: hexOpacityToDec(stroke.slice(HEX_LENGTH)),
      strokeDashArray: obj.strokeDashArray,
      selectedStrokeWidth: obj.strokeWidth,
      selectedStrokeStyle: obj.eflex?.strokeDashArrayStyle ?? StrokeOptions.default,
      fileName: obj.eflex?.fileName ?? '',
      dynamicObjectName: obj.eflex?.dynamicObjectName,
      startHidden: obj.eflex?.startHidden,
      link: obj.eflex?.link,
      aspectRatio: obj.getScaledWidth() / obj.getScaledHeight(),
    });
  }

  #modified = (canvasEvent) => {
    // check to see if the mouse button is down before proceeding
    // to prevent uneccessary code running
    if (canvasEvent?.e?.buttons < 1) {
      return;
    }
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    Object.assign(this, {
      width: Number.parseInt(obj.getScaledWidth()),
      height: Number.parseInt(obj.getScaledHeight()),
    });
  };

  onDidInsert = task(waitFor(async () => {
    await all([
      waitForProperty(this.imageEditor, 'canvas'),
      waitForQueue('afterRender'),
    ]);

    this.updateSelectedProperties();

    this.imageEditor
      .on('selection:updated', this.updateSelectedProperties)
      .on('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas.on('mouse:move', this.#modified);
  }));

  _doAddImage = task(waitFor(async (fileUrl, fileName) => {
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    const options = {
      left: obj?.left ?? 0,
      top: obj?.top ?? 0,
      opacity: this.opacity,
      stroke: this.calculatedStrokeColor,
      strokeDashArray: this.strokeDashArray,
      strokeWidth: this.selectedStrokeWidth,
      eflex: {
        fileName,
        strokeDashArrayStyle: this.selectedStrokeStyle,
      },
    };

    await this.imageEditor.addImage.perform(fileUrl, options);
    this.imageEditor.removeObject(obj);
  }));

  _addImage = task(waitFor(async files => {
    const file = files[0];

    if (!this.workInstructionImageRepo.fileAllowed(file)) {
      return;
    }

    const { blobToDataURL } = await import('blob-util');
    const dataUrl = await blobToDataURL(file);
    await this._doAddImage.perform(dataUrl, file.name);
  }));

  willDestroy() {
    super.willDestroy(...arguments);
    this.imageEditor
      .off('selection:updated', this.updateSelectedProperties)
      .off('selection:cleared', this.#updateFromSystemConfig);

    this.imageEditor.canvas?.off('mouse:move');
  }

  // jscpd:ignore-start
  @action
  updateOpacity(val) {
    this.selectedOpacity = val;
    const obj = this._activeObject();
    updateObjectProperty(obj, 'opacity', this.opacity);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateLink(val) {
    this.link = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'link', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateDynamicObjectName(val) {
    this.dynamicObjectName = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'dynamicObjectName', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStartHidden(val) {
    this.startHidden = val;
    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj.eflex, 'startHidden', val);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateStrokeColor(color, opacity, combinedColor) {
    Object.assign(this, {
      selectedStrokeColor: color,
      selectedStrokeOpacity: opacity,
      calculatedStrokeColor: combinedColor,
    });

    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj, 'stroke', combinedColor);
    this.imageEditor.notifyModified(obj);
  }
  // jscpd:ignore-end

  @action
  updateStroke(strokeDashArray, selectedStrokeStyle, selectedStrokeWidth) {
    Object.assign(this, {
      strokeDashArray,
      selectedStrokeStyle,
      selectedStrokeWidth,
    });

    const obj = this._activeObject();
    if (!obj) {
      return;
    }
    obj.eflex ??= {};
    updateObjectProperty(obj, 'stroke', this.calculatedStrokeColor);
    updateObjectProperty(obj, 'strokeDashArray', strokeDashArray);
    updateObjectProperty(obj.eflex, 'strokeDashArrayStyle', selectedStrokeStyle);
    updateObjectProperty(obj, 'strokeWidth', selectedStrokeWidth);
    this.imageEditor.notifyModified(obj);
  }

  @action
  updateWidth(width) {
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    this.width = width;

    obj.scaleX = this.width / obj.width;
    if (this.keepAspectRatio) {
      this.height = Number.parseInt(this.width / this.aspectRatio);
      obj.scaleY = this.height / obj.height;
    }

    this.imageEditor.notifyModified(obj);
    this.imageEditor.canvas.renderAll();
  }

  @action
  updateHeight(height) {
    const obj = this._activeObject();
    if (obj == null) {
      return;
    }

    this.height = height;

    obj.scaleY = this.height / obj.height;
    if (this.keepAspectRatio) {
      this.width = Number.parseInt(this.height * this.aspectRatio);
      obj.scaleX = this.width / obj.width;
    }

    this.imageEditor.notifyModified(obj);
    this.imageEditor.canvas.renderAll();
  }

  @action
  toggleKeepAspectRatio() {
    this.keepAspectRatio = !this.keepAspectRatio;

    if (this.keepAspectRatio) {
      const obj = this._activeObject();
      if (obj == null) {
        return;
      }

      this.aspectRatio = obj.getScaledWidth() / obj.getScaledHeight();
    }
  }

  @action
  onMakeDefault() {
    const imageDefault = this.store.createRecord('wieConfig/image', {
      opacity: this.selectedOpacity,
      stroke: this.store.createRecord('wieConfig/stroke', {
        style: this.selectedStrokeStyle,
        width: this.selectedStrokeWidth,
        color: this.selectedStrokeColor,
        opacity: this.selectedStrokeOpacity,
      }),
    });

    this.systemConfig.config.wie.editorDefaults.image = imageDefault;
    return this.args.saveDefault();
  }
}
