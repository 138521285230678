import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import filterByIds from 'eflex/helpers/filter-by-ids';
import { t } from 'ember-intl';
import BsForm from 'eflex/components/bs-form';
export default class BiPanelProcessData extends Component {
    @service
    jemProcessDataDefRepo;
    get controlType() {
        return this.args.controlType || 'power-select-multiple';
    }
    get filteredProcessDataDefs() {
        let processDataDefs1 = this.jemProcessDataDefRepo.jemProcessDataDefs.filter((item1)=>item1.type === 'float');
        if (isPresent(this.args.stationIds)) {
            processDataDefs1 = processDataDefs1.filter((processDataDef1)=>this.args.stationIds.includes(processDataDef1.task?.station?.id));
        }
        return processDataDefs1;
    }
    get selected() {
        if (this.controlType === 'power-select-multiple') {
            return filterByIds(this.filteredProcessDataDefs, this.args.processDataDefIds);
        }
        const selectedId1 = this.args.processDataDefIds[0];
        if (!selectedId1) {
            return null;
        }
        return this.filteredProcessDataDefs.find((dataDef1)=>dataDef1.id === selectedId1);
    }
    onChange = (value1)=>{
        if (this.controlType === 'power-select-multiple') {
            value1 = value1.map((item1)=>item1.id);
        } else {
            value1 = [
                value1.id
            ];
        }
        this.args.onChange(value1);
    };
    static{
        template(`
    <BsForm as |form|>
      <form.element
        class="process-data-select"
        @controlType={{this.controlType}}
        @label={{t "bi.biPanel.process-data"}}
        @options={{this.filteredProcessDataDefs}}
        @optionLabelPath="name"
        @value={{this.selected}}
        @onChange={{this.onChange}}
      as |el|>
        <el.control
          @searchEnabled={{true}}
          @searchField="name"
        />
      </form.element>
    </BsForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
