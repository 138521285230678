import { task, timeout } from 'ember-concurrency';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

export default class AppReloader extends Component {
  @service eflexAjax;

  constructor(owner, args) {
    super(owner, args);
    this.checkWebservice.perform();
  }

  checkWebservice = task(waitFor(async () => {
    const interval = (this.args.checkInterval ?? 1) * 1000;
    let isSuccessful = false;

    while (!isSuccessful) {
      try {
        await timeout(interval);
        await this.eflexAjax.get.perform('health');
        isSuccessful = true;
      } catch {
        console.error('webservice not available yet');
      }
    }

    this.args.reload();
  }));
}
