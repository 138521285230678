import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import OEE_STATES from 'eflex/constants/oee-states';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import { t } from 'ember-intl';
import { get } from '@ember/helper';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
export default class OeeStatesPieChart extends Component {
    // jscpd:ignore-start
    @service
    queryRunner;
    @service
    intl;
    get params() {
        return {
            stationIds: this.args.stationIds,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            buttonIds: this.args.buttonIds,
            states: this.args.states
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const oeeStatesQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $group: {
                    _id: '$state',
                    totalTimeInState: {
                        $sum: {
                            $dateDiff: {
                                startDate: '$startDate',
                                endDate: '$endDate',
                                unit: 'millisecond'
                            }
                        }
                    }
                }
            }
        ], true);
        let oeeStates1 = await this.queryRunner.runQuery.perform('OeeStates', oeeStatesQuery1);
        oeeStates1 = oeeStates1.filter((oeeState1)=>Object.values(OEE_STATES.STATES).includes(oeeState1._id));
        // return this.getEchartOptions(oeeStates);
        return {
            chartData: this.getEchartOptions(oeeStates1),
            exportData: ()=>this.formatExportData(clone(oeeStates1))
        };
    // jscpd:ignore-end
    }));
    oeeStateData = trackedTask(this, this.getData, ()=>[
            this.params
        ]);
    getEchartOptions(oeeStates1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.oeeStatesHistoryPieChart')
            },
            tooltip: {
                formatter (params1) {
                    // eslint-disable-next-line max-len
                    return `<div style="min-width:175px;">${params1.name.toUpperCase()}: <span style="float:right;font-weight:bold;">${params1.percent}% </span> <br> Total:  <span style="float:right;font-weight:bold;">${moment.duration(params1.value, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                        precision: 1,
                        trim: 'both mid'
                    })}</span></div>`;
                }
            },
            legend: {
                top: 'bottom',
                formatter (name1) {
                    return `${name1.toUpperCase()}`;
                }
            },
            series: [
                {
                    label: {
                        formatter (params1) {
                            return ` {b|${params1.name.toUpperCase()}: } {per|${params1.percent}%} `;
                        },
                        backgroundColor: echartsTheme.colorPalette.labelBackground,
                        borderColor: echartsTheme.colorPalette.labelBorder,
                        borderWidth: 1,
                        borderRadius: 4,
                        rich: {
                            b: {
                                color: echartsTheme.colorPalette.oeeBackground,
                                fontSize: 12,
                                fontWeight: 'bold'
                            },
                            c: {
                                color: echartsTheme.colorPalette.oeeBackground
                            },
                            per: {
                                fontSize: 12,
                                color: '#fff',
                                backgroundColor: echartsTheme.colorPalette.oeeBackground,
                                padding: [
                                    3,
                                    4
                                ],
                                borderRadius: 4
                            }
                        }
                    },
                    name: this.intl.t('bi.pieChart.legend'),
                    type: 'pie',
                    radius: '65%',
                    center: [
                        '50%',
                        '50%'
                    ],
                    data: oeeStates1.map((m1)=>({
                            value: m1.totalTimeInState,
                            name: m1._id,
                            itemStyle: {
                                color: this.setPieSliceColor(m1._id)
                            }
                        }))
                }
            ]
        };
    }
    setPieSliceColor(state1) {
        // oee button colors, except for the buttons that share a color. those button colors came from george's dundas chart.
        switch(state1){
            case 'running':
                {
                    return echartsTheme.colorPalette.success;
                }
            case 'faulted':
                {
                    return '#e43743';
                }
            case 'starved':
                {
                    return '#fada4a';
                }
            case 'blocked':
                {
                    return echartsTheme.colorPalette.biBlue;
                }
            case 'changeover':
                {
                    return 'chocolate';
                }
            case 'breaks':
                {
                    return '#FF7F0E';
                }
            default:
                {
                    return 'white';
                }
        }
    }
    formatExportData(oeeStates1) {
        return oeeStates1.map((oeeState1)=>({
                state: oeeState1._id,
                totalTimeInState: moment.duration(oeeState1.totalTimeInState, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                    precision: 1,
                    trim: 'both mid'
                })
            }));
    }
    static{
        template(`
    <div class="oee-state-pie-chart-wrapper h-100 w-100">
      {{#if this.oeeStateData.isRunning}}
        <Spinner />
      {{else if (get this.oeeStateData.value.chartData.series '0.data.0.value')}}
        <EChart
          class="h-100 w-100 oee-state-pie-chart"
          @option={{this.oeeStateData.value.chartData}}
          @exportData={{this.oeeStateData.value.exportData}}
          @exportFilename={{t 'bi.chartLabel.oeeStatesHistoryPieChart'}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
