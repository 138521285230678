import Service from '@ember/service';
import { task, taskGroup } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

// Load devices in top level route
// to access connection information for usb devices
export default class WebCamService extends Service {
  @taskGroup({ enqueue: true }) deviceTasks;

  @tracked cameraStreamPlaying = false;
  @tracked devices = [];
  @tracked cameraStream;

  load = task({ drop: true }, waitFor(async permissionCheckKind => {
    if (window.isTesting) {
      return;
    }

    try {
      let devices = await navigator.mediaDevices.enumerateDevices();

      if (permissionCheckKind != null) {
        const checkDevices = devices.filter(item => item.kind === permissionCheckKind);

        // Check if the labels are missing, that means we don't have permission to access them.
        // If we don't then get the stream to ask the user for permission and refresh the device list
        if (checkDevices.length > 0 && !checkDevices[0].label) {
          await this.initStream.linked().perform(checkDevices[0].deviceId);
          await this.destroyStream.linked().perform();

          devices = await navigator.mediaDevices.enumerateDevices();
        }
      }

      this.devices = devices;
    } catch (e) {
      console.error(e);
      this.devices = [];
    }
  }));

  initStream = task({ group: 'deviceTasks' }, waitFor(async deviceId => {
    if (window.isTesting || !navigator.mediaDevices) {
      return;
    }

    // gross hack to get webcams working in chrome headless
    if (navigator.webdriver) {
      await navigator.mediaDevices.enumerateDevices();
    }

    this.cameraStream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: {
          ideal: [deviceId],
        },
        width: {
          min: 640,
          ideal: 4096,
          max: 4320,
        },
        height: {
          min: 480,
          ideal: 2160,
          max: 7680,
        },
        frameRate: {
          ideal: 30,
        },
        aspectRatio: {
          min: 1.3333,
          ideal: 1.896_296_296,
          max: 1.896_296_296,
        },
      },
    });
  }));

  destroyStream = task({ group: 'deviceTasks' }, waitFor(async () => {
    this.destroyStreamTracks();
    await Promise.resolve();
  }));

  willDestroy() {
    super.willDestroy(...arguments);
    this.destroyStreamTracks();
  }

  destroyStreamTracks() {
    this.cameraStream?.getTracks().forEach((track) => {
      track.stop();
    });

    this.cameraStream = null;
    this.cameraStreamPlaying = false;
  }
}
