import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import TaskStatuses from 'eflex/constants/task-statuses';
import { isBlank, isPresent } from '@ember/utils';
import getJemStatusTranslationOrUnknown from 'eflex/util/get-jem-status-translation-or-unknown';
import { service } from '@ember/service';
import { cached, tracked } from '@glimmer/tracking';
import { getCaptionText } from 'eflex/util/translation-helper';
import { objectAttr, arrayAttr } from 'eflex/decorators';
import { compact, sortByProps } from 'ramda-adjunct';
import { chain, filter, flow, prop, reject } from 'ramda';
import { dp, intoArray } from '@eflexsystems/ramda-helpers';

export default class BuildStatus extends Model {
  @attr('string') serialNumber;
  @attr('number') status;
  @attr('number') cycleTime;
  @attr('number') startTime;
  @attr('number') endTime;
  @attr('number', { defaultValue: 0 }) totalTargetCycleTime;
  @attr('number', { defaultValue: 0 }) palletNumber;
  @attr('number', { defaultValue: 0 }) bufferedDuration;
  @attr('string') userId;
  @attr('string') userName;
  @attr('string') kineticEmployeeId;
  @attr('string') bomSourceLookupValue;
  @attr('string') area;
  @attr('date') timestamp;
  @attr('date') createdAt;
  @arrayAttr allSerialNumbers;
  @arrayAttr marriedSerialNumbers;
  @arrayAttr previousDecisionTags;
  @objectAttr location;
  @objectAttr model;
  @objectAttr rejectCodes;
  @objectAttr scrapCodes;

  @hasMany('buildStatusChild', { async: false, inverse: 'buildStatus', embedded: true }) children;
  @hasMany('customIdentifierDatum', { inverse: 'buildStatus', async: false, embedded: true }) customIdentifierData;
  @belongsTo('buildDatum', { async: false, inverse: null }) buildDatum;
  @belongsTo('productionSchedule', { async: false, inverse: null }) productionSchedule;
  @belongsTo('kineticAssemblyOperation', { async: false, inverse: null }) kineticAssemblyOperation;
  @belongsTo('configurationHistory', { async: false, inverse: null }) configurationHistory;

  @tracked isSelected;

  @service intl;
  @service currentUser;

  @cached
  get sortedChildren() {
    return flow(this.children ?? [], [
      sortByProps([dp('location.row'), dp('location.column')]),
      compact,
    ]);
  }

  @cached
  get station() {
    if (!this.stationId) {
      return null;
    }

    return this.store.peekRecord('station', this.stationId) ?? this.location;
  }

  set station(station) {
    this.location = station;
  }

  get stationName() {
    return getCaptionText(this.station?.captions, this.currentUser);
  }

  get stationId() {
    return this.location?.id ?? this.location?._id;
  }

  get kineticOperation() {
    return this.kineticAssemblyOperation?.operation;
  }

  get modelName() {
    let displayed = this.model?.code;
    const modelName = this.model?.name;

    if (!isBlank(modelName)) {
      displayed += ` - ${modelName}`;
    }

    return displayed;
  }

  get isStarted() {
    return this.status === TaskStatuses.STARTED;
  }

  get isRepaired() {
    return this.status === TaskStatuses.REPAIRED;
  }

  get usesComponents() {
    return this.station?.usesComponents ?? false;
  }

  get hasRejectCodes() {
    return isPresent(this.rejectCodes?.codes);
  }

  get hasScrapCodes() {
    return isPresent(this.scrapCodes?.codes);
  }

  get hasCodes() {
    return this.hasRejectCodes || this.hasScrapCodes;
  }

  get isGood() {
    return TaskStatuses.isGood(this.status);
  }

  get isRejected() {
    return TaskStatuses.isRejected(this.status);
  }

  get statusTranslation() {
    return getJemStatusTranslationOrUnknown(this.intl, `status.${this.status}`, this.status);
  }

  get statusClass() {
    if (this.isGood) {
      return 'bg-success';
    } else if (this.isRejected) {
      return 'bg-danger';
    } else if (this.isStarted) {
      return 'bg-primary';
    } else {
      return 'bg-gray-800';
    }
  }

  get formattedProcessData() {
    return this.customIdentifierData
      .map(identifier => ({
        timestamp: this.timestamp,
        displayName: identifier.name,
        displayValue: identifier.value,
      }))
      .concat(
        this.children.flatMap(buildStatusChild => buildStatusChild.formattedProcessData),
      );
  }

  get hasMarriages() {
    return this.children.some(item => item.latestPartMarriageStatus);
  }

  get formattedMarriages() {
    return flow(this.children ?? [], [
      chain(prop('allFormattedMarriages')),
      sortByProps(['timestamp']),
      compact,
    ]);
  }

  getAllowedDecisions(task) {
    if (task == null) {
      return this.previousDecisionTags;
    }

    const currentDecisionTags = intoArray(
      filter(prop('isFinished')),
      reject(child => {
        if (child.task.row === task.row) {
          return child.task.column > task.column;
        } else {
          return child.task.row > task.row;
        }
      }),
      chain(prop('selectedDecisions')),
    )(this.children);

    return this.previousDecisionTags.concat(currentDecisionTags);
  }
}
