import { template } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { eq, or } from 'ember-truth-helpers';
export default template(`
<div ...attributes>
  <label class="form-label">
    {{t "status"}}
  </label>
  <div class="jem-build-status form-control form-control-lg border-0 d-flex align-items-center fw-semibold px-3
    {{if @statusClass @statusClass 'bg-gray-800'}}
    {{if (or
      (eq @statusClass 'bg-warning')
      (eq @statusClass 'bg-success')
    ) 'text-gray-900' 'text-white'}}"
  >
    {{#if @statusTranslation}}
      {{@statusTranslation}}
    {{else}}
      {{t "plant.station.jem.unknown"}}
    {{/if}}
  </div>
</div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
