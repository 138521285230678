import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import TaskAndFastenerMatcher from 'eflex/util/bi-task-fastener-matcher';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import TabulatorTable from 'eflex/components/tabulator-table';
import { t } from 'ember-intl';
export default class BoltDataTable extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    systemConfig;
    get params() {
        return {
            stationIds: this.args.stationIds,
            fasteners: this.args.fasteners,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    get tableConfiguration() {
        return {
            columns: [
                {
                    title: this.intl.t('serialNumber'),
                    field: 'serialNumber',
                    formatter: 'textarea',
                    resizable: true
                },
                {
                    title: this.intl.t('station'),
                    field: 'station',
                    formatter: 'textarea',
                    resizable: true
                },
                {
                    title: this.intl.t('user'),
                    field: 'user',
                    formatter: 'textarea',
                    resizable: true
                },
                {
                    title: this.intl.t('task'),
                    field: 'taskName',
                    formatter: (cell1)=>this.formatTaskColumnData(cell1),
                    resizable: true
                },
                {
                    title: this.intl.t('bi.chartLabel.boltNumber'),
                    field: 'boltNumber',
                    formatter: 'textarea',
                    width: 200,
                    resizable: true
                },
                {
                    title: this.intl.t('bi.chartLabel.boltFinishTime'),
                    field: 'boltFinishTime',
                    formatter: (cell1)=>this.formatDateTimeString(cell1),
                    width: 210
                },
                {
                    title: this.intl.t('torque'),
                    field: 'torque',
                    formatter: (cell1)=>this.formatTorqueAndAngleColumn(cell1, true),
                    width: 125
                },
                {
                    title: this.intl.t('angle'),
                    field: 'angle',
                    formatter: (cell1)=>this.formatTorqueAndAngleColumn(cell1, false),
                    width: 125
                },
                {
                    title: this.intl.t('status'),
                    formatter: (cell1)=>this.formatStatusColumn(cell1),
                    headerSort: false,
                    width: 75
                }
            ]
        };
    }
    getTorqueData = task({
        restartable: true
    }, waitFor(async (params1 = {})=>{
        let sortStep1;
        if (params1.sort?.length > 0) {
            const dir1 = params1.sort[0].dir === 'asc' ? 1 : -1;
            const field1 = params1.sort[0].field;
            sortStep1 = {
                [field1]: dir1
            };
        } else {
            sortStep1 = {
                boltFinishTime: -1
            };
        }
        const query1 = await this.queryRunner.queryWithParams.perform(this.params, [
            {
                $limit: 100_000
            },
            {
                $match: {
                    'children.boltProcessData.0': {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $unwind: {
                    path: '$children.boltProcessData'
                }
            },
            {
                $addFields: {
                    idAndFastener: {
                        $concat: [
                            {
                                $toString: '$children.location._id'
                            },
                            {
                                $toString: '$children.boltProcessData.boltNumber'
                            }
                        ]
                    }
                }
            },
            TaskAndFastenerMatcher(this.params.fasteners),
            {
                $skip: params1.skip ?? 0
            },
            {
                $limit: params1.size ?? 100_000
            },
            {
                $project: {
                    serialNumber: 1,
                    station: {
                        $first: '$location.captions.text'
                    },
                    user: '$userName',
                    taskName: {
                        $first: '$children.location.captions.text'
                    },
                    boltNumber: '$children.boltProcessData.boltNumber',
                    torqueStatus: '$children.boltProcessData.torqueStatus',
                    angleStatus: '$children.boltProcessData.angleStatus',
                    errorCode: '$children.boltProcessData.errorCode',
                    boltFinishTime: '$children.boltProcessData.timestamp',
                    torque: '$children.boltProcessData.torque',
                    torqueFormat: '$children.boltProcessData.torqueFormat',
                    angle: '$children.boltProcessData.angle'
                }
            },
            {
                $sort: sortStep1
            }
        ]);
        // jscpd:ignore-end
        const torqueData1 = await this.queryRunner.runQuery.perform('BuildStatuses', query1, true);
        return {
            count: torqueData1.meta?.count,
            data: torqueData1.result,
            exportData: ()=>this.formatExportData(clone(torqueData1))
        };
    }));
    chartData = trackedTask(this, this.getTorqueData, ()=>[
            this.params
        ]);
    formatTaskColumnData = (cell1)=>{
        const { taskName: taskName1, torqueStatus: torqueStatus1, angleStatus: angleStatus1, errorCode: errorCode1 } = cell1.getData();
        const backgroundColor1 = this.getColumnStatus(torqueStatus1, errorCode1) && this.getColumnStatus(angleStatus1, errorCode1) ? echartsTheme.colorPalette.taskRowGreen : echartsTheme.colorPalette.taskRowRed;
        Object.assign(cell1.getElement().style, {
            backgroundColor: backgroundColor1,
            color: 'black'
        });
        return taskName1;
    };
    formatTorqueAndAngleColumn = (cell1, isTorque1)=>{
        let { torqueStatus: torqueStatus1, angleStatus: angleStatus1, torque: torque1, angle: angle1, torqueFormat: torqueFormat1 } = cell1.getData();
        let fontColor1;
        if (isTorque1 && this.getColumnStatus(torqueStatus1)) {
            fontColor1 = echartsTheme.colorPalette.taskRowGreen;
        } else if (!isTorque1 && this.getColumnStatus(angleStatus1)) {
            fontColor1 = echartsTheme.colorPalette.taskRowGreen;
        } else {
            fontColor1 = echartsTheme.colorPalette.taskRowRed;
        }
        Object.assign(cell1.getElement().style, {
            color: fontColor1
        });
        torque1 = torque1 ?? '0';
        angle1 = angle1 ?? '0';
        torqueFormat1 = torqueFormat1 ?? this.systemConfig.jem.torqueFormat;
        return isTorque1 ? `${torque1} ${torqueFormat1}` : `${angle1}°`;
    };
    formatStatusColumn = (cell1)=>{
        const { torqueStatus: torqueStatus1, angleStatus: angleStatus1, errorCode: errorCode1 } = cell1.getData();
        const boltIsGood1 = this.boltIsGood(torqueStatus1, angleStatus1, errorCode1);
        Object.assign(cell1.getElement().style, {
            backgroundColor: boltIsGood1 ? echartsTheme.colorPalette.taskRowGreen : echartsTheme.colorPalette.taskRowRed,
            color: boltIsGood1 ? echartsTheme.colorPalette.success : echartsTheme.colorPalette.danger,
            textAlign: 'center'
        });
        return boltIsGood1 ? '✔' : '✖';
    };
    formatDateTimeString = (cell1)=>{
        const { boltFinishTime: boltFinishTime1 } = cell1.getData();
        return moment(boltFinishTime1).format('MM/DD/YYYY h:mm:ss A');
    };
    formatExportData = (data1)=>{
        data1.forEach((row1)=>{
            row1.torque = `${row1.torque ?? '0'} ${row1.torqueFormat ?? this.systemConfig.jem.torqueFormat}`;
            row1.angle = `${row1.angle ?? '0'} ${this.intl.t('degrees')}`;
            row1.boltFinishTime = moment(row1.boltFinishTime).format('MM/DD/YYYY h:mm:ss A');
            row1.status = this.boltIsGood(row1.torqueStatus, row1.angleStatus, row1.errorCode) ? this.intl.t('pass') : this.intl.t('fail');
            delete row1._id;
            delete row1.torqueFormat;
        });
        return data1;
    };
    getColumnStatus = (status1)=>{
        return status1 === 1;
    };
    boltIsGood = (torqueStatus1, angleStatus1, errorCode1)=>{
        return !!(this.getColumnStatus(torqueStatus1) && this.getColumnStatus(angleStatus1) && !errorCode1);
    };
    static{
        template(`
    <div class="bi-tabulator component-torque-status" ...attributes>
      {{#if this.chartData.isRunning}}
        <Spinner />
      {{else if this.chartData.value.count}}
        <TabulatorTable
          @tableConfiguration={{this.tableConfiguration}}
          @getRowData={{this.getTorqueData.perform}}
          @updateDataAttr={{this.params}}
          @exportFilename={{t 'bi.chartLabel.boltData'}}
          @exportDataFunction={{this.chartData.value.exportData}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
