import { template } from "@ember/template-compiler";
import { task, timeout } from 'ember-concurrency';
import EchartsTheme from 'eflex/echarts-theme';
import Component from '@glimmer/component';
import getDelayTime from 'eflex/util/get-delay-time';
import { waitFor } from '@ember/test-waiters';
import { unregisterDestructor, registerDestructor } from '@ember/destroyable';
import exportToCsv from 'eflex/util/export-to-csv';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { onResize } from 'eflex/modifiers';
import { eventBus } from 'eflex/helpers';
export default class EChart extends Component {
    #destructor;
    onResize = task({
        restartable: true
    }, waitFor(async (element1)=>{
        await timeout(getDelayTime(100));
        const ECharts1 = await this._loadEcharts.perform();
        ECharts1.getInstanceByDom(element1)?.resize();
    }));
    startAutoRefresh = task({
        restartable: true
    }, waitFor(async (element1, [onClick1, option1])=>{
        if (window.isTesting || window.isIntegrationTest) {
            return;
        }
        while(this.args.autoRefresh > 0){
            await timeout(this.args.autoRefresh);
            await this.createChart.perform(element1, [
                onClick1,
                option1
            ]);
        }
    }));
    createChart = task({
        enqueue: true
    }, waitFor(async (element1, [onClick1, option1])=>{
        const ECharts1 = await this._loadEcharts.perform();
        let chart1 = ECharts1.getInstanceByDom(element1);
        this.#destroyChart(chart1);
        const options1 = {
            renderer: 'canvas',
            useDirtyRect: true
        };
        if (window.isTesting) {
            options1.width = 1;
            options1.height = 1;
        }
        chart1 = ECharts1.init(element1, EchartsTheme, options1);
        this.#destructor = registerDestructor(this, ()=>{
            this.#destroyChart(chart1);
        });
        chart1.setOption(option1 ?? {});
        if (onClick1) {
            chart1.on('click', (param1)=>{
                onClick1(param1?.dataIndex, chart1);
            });
        }
    }));
    _loadEcharts = task(waitFor(async ()=>{
        const echarts1 = await import('echarts');
        window.ECharts ??= echarts1;
        return echarts1;
    }));
    #destroyChart(chart1) {
        if (!this.isDestroyed && !this.isDestroying && this.#destructor) {
            unregisterDestructor(this, this.#destructor);
        }
        if (!chart1) {
            return;
        }
        chart1.off('click');
        chart1.dispose();
    }
    exportToCsv = task(waitFor(async ()=>{
        await exportToCsv(this.args.exportData, this.args.option?.title?.text);
    }));
    static{
        template(`
    {{eventBus exportToCsv=this.exportToCsv.perform}}

    <div
      {{didInsert this.createChart.perform @onClick @option}}
      {{didUpdate this.createChart.perform @onClick @option}}
      {{didInsert this.startAutoRefresh.perform @onClick @option}}
      {{didUpdate this.startAutoRefresh.perform @onClick @option}}
      {{onResize this.onResize.perform}}
      ...attributes
    >
      <div class="echarts-chart">
        {{yield}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
