import { defaults, defaultStack } from '@pnotify/core';

defaults.delay = 2000;

defaultStack.maxOpen = Number.POSITIVE_INFINITY;
defaultStack.modal = false;

window.disablePnotify = function() {
  defaults.autoOpen = false;
};
