import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
import { fn } from '@ember/helper';
import { lt, lte, or } from 'ember-truth-helpers';
const MAX_SPINDLES = 50;
export default class TaskConfigMultispindle extends Component {
    @service
    spindleRepo;
    get remainingSpindles() {
        return MAX_SPINDLES - this.args.task.spindles.length;
    }
    get nonDeletedSpindleConfigs() {
        return this.args.taskConfig.spindleConfigs.filter((item1)=>!item1.isDeleted);
    }
    addSpindles = (quantity1)=>{
        for(let i1 = 0; i1 < quantity1; i1++){
            this.spindleRepo.createSpindle({
                task: this.args.task
            });
        }
    };
    deleteSpindle = (spindle1)=>{
        this.spindleRepo.deleteSpindle(spindle1);
    };
    static{
        template(`
    <div class="row form-row model-specific">
      <div class="col-3">
        <@form.element
          @controlType="number"
          @label={{t "programNumber"}}
          @property="programNumber"
        />
      </div>
    </div>

    <div>
      <label class="form-label">
        {{t "multispindle.spindleConfig"}}
      </label>
      <div class="bootstrap-table">
        <div class="row form-row header-row">
          <div class="col-1 model-specific">
            {{t "enabled"}}
          </div>
          <div class="col model-specific">
            {{t "name"}}
          </div>
          <div class="col-icon"/>
        </div>
        {{#each this.nonDeletedSpindleConfigs as |spindleConfig|}}
          <div class="row form-row test-multispindle-config">
            <div class="col-1">
              <@form.element
                @controlType="checkbox"
                @model={{spindleConfig}}
                @property="enabled"
                class="form-switch ps-3 model-specific d-flex justify-content-center align-items-center"
              />
            </div>
            <div class="col pe-0">
              <@form.element
                @controlType="text"
                @model={{spindleConfig}}
                @property="name"
                class="model-specific"
              />
            </div>
            <div class="col-auto">
              <div class="col-icon">
                <TrashCan
                  disabled={{or @disabled (lte this.nonDeletedSpindleConfigs.length 1)}}
                  @onDelete={{fn this.deleteSpindle spindleConfig.spindle}}
                />
              </div>
            </div>
          </div>
        {{/each}}
        <div class="row form-row no-hover-row">
          <div class="col-auto">
            <QuantitySelect
              class="px-2 text-uppercase test-add-multispindle"
              @disabled={{or @disabled (lt this.remainingSpindles 1)}}
              @max={{this.remainingSpindles}}
              @onSubmit={{this.addSpindles}}
            >
              {{t "multispindle.addSpindle"}}
            </QuantitySelect>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
