import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import moment from 'moment-timezone';
import { formatStateColumnData } from 'eflex/util/bi-format-oee-state-cell';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import { t } from 'ember-intl';
import TabulatorTable from 'eflex/components/tabulator-table';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
export default class OeeStatesTable extends Component {
    // jscpd:ignore-start
    @service
    queryRunner;
    @service
    intl;
    @service
    store;
    get params() {
        return {
            stationIds: this.args.stationIds,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames,
            buttonIds: this.args.buttonIds,
            states: this.args.states
        };
    }
    get tableConfiguration() {
        return {
            columns: [
                {
                    title: this.intl.t('startTime'),
                    field: 'startDate',
                    formatter: 'textarea',
                    resizable: true
                },
                {
                    title: this.intl.t('finishTime'),
                    field: 'endDate',
                    formatter: 'textarea',
                    resizable: true
                },
                {
                    title: this.intl.t('scheduler.duration'),
                    field: 'duration',
                    formatter: 'textarea',
                    resizable: true
                },
                {
                    title: this.intl.t('username'),
                    field: 'username',
                    resizable: true
                },
                {
                    title: this.intl.t('state'),
                    field: 'state',
                    formatter: (cell1)=>formatStateColumnData(cell1),
                    hozAlign: 'center',
                    vertAlign: 'center',
                    width: 125,
                    resizable: true,
                    tooltip: false
                }
            ],
            childConfiguration: this.childConfiguration,
            options: {
                paginationSize: false,
                groupBy: 'stationId',
                groupToggleElement: 'header',
                groupHeader: (value1)=>{
                    return this.store.peekRecord('station', value1).name.toUpperCase();
                }
            }
        };
    }
    get childConfiguration() {
        return {
            columns: [
                {
                    title: this.intl.t('area'),
                    field: 'areaName',
                    formatter: 'textarea',
                    resizable: true,
                    headerSort: false
                },
                {
                    title: this.intl.t('group'),
                    field: 'groupName',
                    formatter: 'textarea',
                    resizable: true,
                    headerSort: false
                },
                {
                    title: this.intl.t('bi.chartLabel.oeeFaultCodes'),
                    formatter: 'textarea',
                    field: 'faultCode',
                    resizable: true,
                    headerSort: false
                },
                {
                    title: this.intl.t('bi.chartLabel.oeeFaultMessages'),
                    field: 'faultDescription',
                    formatter: 'textarea',
                    resizable: true,
                    headerSort: false
                }
            ]
        };
    }
    getOeeStateData = task({
        drop: true
    }, waitFor(async (params1 = {})=>{
        let sortStep1;
        if (params1.sort?.length > 0) {
            const dir1 = params1.sort[0].dir === 'asc' ? 1 : -1;
            const field1 = params1.sort[0].field;
            sortStep1 = {
                [field1]: dir1
            };
        } else {
            sortStep1 = {
                endDate: -1
            };
        }
        const query1 = await this.queryRunner.queryWithParams.perform(this.params, [
            {
                $skip: params1.skip ?? 0
            },
            {
                $limit: params1.size ?? 100_000
            },
            {
                $match: {
                    endDate: {
                        $ne: null
                    }
                }
            },
            {
                $lookup: {
                    from: 'LocationBases',
                    localField: 'station._id',
                    foreignField: '_id',
                    as: 'station'
                }
            },
            {
                $unwind: {
                    path: '$station'
                }
            },
            {
                $addFields: {
                    areaId: {
                        $toObjectId: {
                            $arrayElemAt: [
                                {
                                    $split: [
                                        '$station.path',
                                        '#'
                                    ]
                                },
                                0
                            ]
                        }
                    },
                    groupId: {
                        $toObjectId: {
                            $arrayElemAt: [
                                {
                                    $split: [
                                        '$station.path',
                                        '#'
                                    ]
                                },
                                1
                            ]
                        }
                    }
                }
            },
            {
                $lookup: {
                    from: 'LocationBases',
                    localField: 'areaId',
                    foreignField: '_id',
                    as: 'area'
                }
            },
            {
                $unwind: {
                    path: '$area'
                }
            },
            {
                $lookup: {
                    from: 'LocationBases',
                    localField: 'groupId',
                    foreignField: '_id',
                    as: 'group'
                }
            },
            {
                $unwind: {
                    path: '$group'
                }
            },
            {
                $project: {
                    _id: 0,
                    areaName: {
                        $last: '$area.captions.text'
                    },
                    groupName: {
                        $last: '$group.captions.text'
                    },
                    stationId: '$station._id',
                    stationName: {
                        $last: '$station.captions.text'
                    },
                    state: true,
                    userName: true,
                    startDate: true,
                    endDate: true,
                    duration: {
                        $dateDiff: {
                            startDate: '$startDate',
                            endDate: '$endDate',
                            unit: 'millisecond'
                        }
                    },
                    faultCodes: true,
                    customButtonColor: true
                }
            },
            {
                $sort: sortStep1
            }
        ], true);
        const oeeStates1 = await this.queryRunner.runQuery.perform('OeeStates', query1, true);
        const rows1 = oeeStates1?.result?.filter((row1)=>row1.endDate).map((state1)=>({
                stationId: state1.stationId,
                stationName: state1.stationName,
                startDate: moment(state1.startDate).format('MM/DD/YYYY hh:mm A'),
                endDate: moment(state1.endDate).format('MM/DD/YYYY hh:mm A'),
                duration: moment.duration(state1.duration, 'milliseconds').format('d[d] h[h] m[m] s[s]', {
                    precision: 1,
                    trim: 'both mid'
                }),
                username: state1.userName,
                state: state1,
                isParentRow: true,
                children: [
                    {
                        areaName: state1.areaName,
                        groupName: state1.groupName,
                        faultCode: state1.faultCodes?.codes?.map((faultCode1)=>`${faultCode1.code} - ${faultCode1.description}`).join(', '),
                        faultDescription: state1.faultCodes?.note
                    }
                ]
            }));
        return {
            count: oeeStates1.meta?.count,
            data: rows1,
            exportData: ()=>this.formatExportData(clone(rows1))
        };
    // jscpd:ignore-end
    }));
    oeeStateData = trackedTask(this, this.getOeeStateData, ()=>[
            this.params
        ]);
    formatExportData(data1) {
        return data1.map((row1)=>{
            const { stationName: stationName1, startDate: startDate1, endDate: endDate1, duration: duration1, username: username1, state: state1 } = row1;
            const { areaName: areaName1, groupName: groupName1, faultCode: faultCode1, faultDescription: faultDescription1 } = row1.children[0];
            return {
                stationName: stationName1,
                areaName: areaName1,
                groupName: groupName1,
                startDate: startDate1,
                endDate: endDate1,
                duration: duration1,
                username: username1,
                state: state1.state,
                faultCode: faultCode1 ?? '',
                faultDescription: faultDescription1 ?? ''
            };
        });
    }
    static{
        template(`
    <div class="bi-tabulator component-bi-oee-states-table d-flex flex-column h-100" ...attributes>
      {{#if this.oeeStateData.isRunning}}
        <Spinner />
      {{else if this.oeeStateData.value.count}}
        <TabulatorTable
          @tableConfiguration={{this.tableConfiguration}}
          @getRowData={{this.getOeeStateData.perform}}
          @updateDataAttr={{this.params}}
          @exportFilename={{t 'bi.chartLabel.oeeStatesTable'}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
