import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import getJemTaskComponent from 'eflex/util/get-jem-task-component';
import * as JemTaskState from 'eflex/util/jem-task-state';
import TaskStatuses from 'eflex/constants/task-statuses';
import { on } from '@ember/modifier';
import { hash, fn } from '@ember/helper';
import Portal from 'ember-stargate/components/portal';
import { formatCycleTime, isNull } from 'eflex/helpers';
import { and, not, or } from 'ember-truth-helpers';
import { scrollIntoView } from 'eflex/modifiers';
import { withDefault } from '@eflexsystems/ember-composable-helpers';
import JemTaskButton from 'eflex/components/jem/task-button';
import JemTaskOkButton from 'eflex/components/jem/task-ok-button';
import JemTaskRejectButton from 'eflex/components/jem/task-reject-button';
import JemTaskFault from 'eflex/components/jem/task-fault';
import JemTaskComponentOption from 'eflex/components/jem/task/component-option';
import onKey from 'ember-keyboard/modifiers/on-key';
const getTaskConfirmButtonIsShown = (task1, taskConfig1, childStatus1, isSelected1)=>{
    if (!isSelected1) {
        return false;
    }
    return JemTaskState.taskConfirmButtonIsShown({
        childStatus: childStatus1,
        task: task1,
        taskConfig: taskConfig1
    });
};
const getTaskConfirmIsDisabled = (task1, taskConfig1, childStatus1, isSelected1, notAuthorized1)=>{
    if (!isSelected1) {
        return true;
    }
    return JemTaskState.taskConfirmIsDisabled({
        childStatus: childStatus1,
        task: task1,
        taskConfig: taskConfig1,
        notAuthorized: notAuthorized1
    });
};
const getTaskRejectButtonIsShown = (task1, taskConfig1, childStatus1, isSelected1)=>{
    if (!isSelected1) {
        return false;
    }
    return JemTaskState.taskRejectButtonIsShown({
        childStatus: childStatus1,
        task: task1,
        taskConfig: taskConfig1
    });
};
const getTaskRejectIsDisabled = (task1, taskConfig1, childStatus1, isSelected1, notAuthorized1)=>{
    if (!isSelected1) {
        return true;
    }
    return JemTaskState.taskRejectIsDisabled({
        childStatus: childStatus1,
        task: task1,
        taskConfig: taskConfig1,
        notAuthorized: notAuthorized1
    });
};
const getShowBigOkButton = (taskConfig1, childStatus1, isSelected1, notAuthorized1)=>{
    if (!isSelected1) {
        return false;
    }
    if (!childStatus1) {
        return false;
    }
    if (!childStatus1.isStarted) {
        return false;
    }
    if (notAuthorized1) {
        return false;
    }
    if (!taskConfig1) {
        return false;
    }
    return true;
};
export default class JemTaskGridRow extends Component {
    @tracked
    showDetails = true;
    onClick = (isSelected1, task1)=>{
        if (isSelected1) {
            this.showDetails = !this.showDetails;
        } else {
            this.showDetails = true;
            this.args.onSelectTask(task1);
        }
    };
    onTaskComplete = (status1)=>{
        return this.args.onTaskComplete(status1, this.args.childStatus);
    };
    static{
        template(`
    <div
      class="jem-task-row rounded p-2 mb-1
        {{@childStatus.taskStatusClass}}
        {{if @isSelected "selected"}}
        {{if (and @isSelected @childStatus.isStarted) "active-selection"}}
      "
      ...attributes
    >
      {{#let (getJemTaskComponent @task.taskType) as |TaskComponent|}}
        <TaskComponent
          @childStatus={{@childStatus}}
          @taskConfig={{@taskConfig}}
          @notAuthorized={{@notAuthorized}}
          @task={{@task}}
          @station={{@station}}
          @hardware={{@hardware}}
          @disabled={{or
            (isNull @taskConfig)
            (not @isSelected)
            (not @childStatus.isRunning)
          }}
          @onTaskComplete={{this.onTaskComplete}}
          @onRepair={{@onRepair}}
        as |TaskDisplay|>
          <div
            class="nested-grid jem-task align-items-center {{if @childStatus.isStarted 'started'}}"
            {{scrollIntoView
              shouldScroll=(and @isSelected (not @childStatus.isScrollPrevented))
              options=(hash behavior="smooth" block="center")
            }}
            {{on "click" (fn this.onClick @isSelected @task)}}
          >
            {{#let
              (getTaskConfirmIsDisabled @task @taskConfig @childStatus @isSelected @notAuthorized)
              (withDefault TaskDisplay.onTaskComplete this.onTaskComplete)
            as |taskConfirmIsDisabled onTaskComplete|}}
              <div class="jem-step text-gray-900 text-truncate fw-semibold">
                {{@task.row}}-{{@task.column}}
              </div>
              <div></div>
              <div class="jem-description text-truncate {{if @task.isPlc "overflow-visible"}} ms-1">
                <div class="task-description d-flex align-items-center">
                  <div class="task-icon {{@task.iconClass}} me-2"></div>
                  <div class="task-name fw-semibold text-truncate text-gray-900">
                    {{@task.name}}
                  </div>
                </div>
              </div>
              <div></div>
              <div class="jem-status text-capitalize ms-1">
                <div class="row align-items-center flex-nowrap overflow-hidden gx-2">
                  {{#let
                    (getTaskConfirmButtonIsShown @task @taskConfig @childStatus @isSelected)
                    (getTaskRejectButtonIsShown @task @taskConfig @childStatus @isSelected)
                    (
                      getTaskRejectIsDisabled
                      @task
                      @taskConfig
                      @childStatus
                      @isSelected
                      @notAuthorized
                    )
                  as |taskConfirmButtonIsShown taskRejectButtonIsShown taskRejectIsDisabled|}}
                    {{#if taskConfirmButtonIsShown}}
                      <div
                        class="col-auto"
                        {{onKey
                          'Enter'
                          (fn onTaskComplete TaskStatuses.GOOD)
                          activated=(not taskConfirmIsDisabled)
                          priority=1
                        }}
                        {{onKey
                          'ctrl+alt+KeyO'
                          (fn onTaskComplete TaskStatuses.GOOD)
                          activated=(not taskConfirmIsDisabled)
                          priority=1
                        }}
                      >
                        <JemTaskButton
                          @task={{@task}}
                          @childStatus={{@childStatus}}
                          @notAuthorized={{@notAuthorized}}
                          @disabled={{or taskConfirmIsDisabled TaskDisplay.okDisabled}}
                          @okButtonOverride={{TaskDisplay.okButtonOverride}}
                          @retryButtonOverride={{TaskDisplay.retryButtonOverride}}
                          @onTaskComplete={{onTaskComplete}}
                        />
                      </div>
                    {{else if (not taskRejectButtonIsShown)}}
                      <div class="col-auto">
                        <span class="jem-status text-truncate fw-semibold text-gray-900">
                          {{@childStatus.statusTranslation}}
                        </span>
                      </div>
                    {{/if}}

                    <div
                      class="col-auto"
                      {{onKey
                        'ctrl+alt+KeyR'
                        (fn onTaskComplete TaskStatuses.REJECTED)
                        activated=(not taskRejectIsDisabled)
                      }}
                    >
                      {{#if taskRejectButtonIsShown}}
                        <JemTaskRejectButton
                          @notAuthorized={{@notAuthorized}}
                          @disabled={{taskRejectIsDisabled}}
                          @onTaskComplete={{onTaskComplete}}
                        />
                      {{/if}}
                    </div>
                  {{/let}}
                </div>
              </div>
              <div></div>

              {{#if @station.jemShowTaskCycle }}
                <div class="time ms-1">
                  <div class="task-actual-time task-cycle-time text-gray-900 text-lowercase fw-semibold text-truncate">
                    {{formatCycleTime @childStatus.cycleTime}}
                  </div>
                </div>
              {{/if}}

              {{#if (getShowBigOkButton @taskConfig @childStatus @isSelected @notAuthorized)}}
                <Portal @target="jem-ok-button-portal">
                  <div class="big-ok-button h-100">
                    <JemTaskOkButton
                      class="jem-status-button text-uppercase h-100 mb-0"
                      @size="lg"
                      @type="success"
                      @disabled={{or taskConfirmIsDisabled TaskDisplay.okDisabled}}
                      @onTaskComplete={{onTaskComplete}}
                    />
                  </div>
                </Portal>
              {{/if}}
            {{/let}}
          </div>

          {{#if (or this.showDetails @childStatus.isStarted)}}
            <div
              class="nested-grid align-items-center jem-component-option"
              {{on "click" (fn @onSelectTask @task)}}
            >
              <div></div>
              <div></div>
              <div class="jem-task-grid-wide ms-2">
                <JemTaskComponentOption
                  @task={{@task}}
                  @taskConfig={{@taskConfig}}
                  @childStatus={{@childStatus}}
                />
              </div>
            </div>
            <div
              class="nested-grid align-items-center jem-task-details"
              {{on "click" (fn @onSelectTask @task)}}
            >
              <div></div>
              <div></div>
              <div class="jem-task-grid-wide ms-1">
                <TaskDisplay.taskDetails class="task-details ps-1" />
              </div>
            </div>
          {{/if}}

          <div
            class="jem-task-fault full-width-grid"
            {{on "click" (fn @onSelectTask @task)}}
          >
            <div></div>
            <div class="jem-task-grid-wide ms-1">
              <JemTaskFault
                @task={{@task}}
                @station={{@station}}
                @hardware={{@hardware}}
              />
            </div>
          </div>
        </TaskComponent>
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
