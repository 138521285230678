import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { clone } from 'ramda';
import echartsTheme from 'eflex/echarts-theme';
import Wildcard from 'eflex/util/wildcard';
import { t } from 'ember-intl';
import TabulatorTable from 'eflex/components/tabulator-table';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
import { isEmpty } from '@ember/utils';
export default class ProcessDataMarriedTable extends Component {
    @service
    queryRunner;
    @service
    intl;
    @service
    store;
    get params() {
        return {
            searchTerm: this.args.searchTerm,
            isNumericSearch: this.args.isNumericSearch,
            startDate: this.args.beginDate,
            endDate: this.args.endDate
        };
    }
    get numberStages() {
        const searchTerm1 = this.formattedSearchTerm;
        return [
            {
                $match: {
                    'children.processData.0': {
                        $exists: true
                    }
                }
            },
            {
                $project: {
                    serialNumber: 1,
                    children: {
                        status: 1,
                        endTime: 1,
                        timestamp: 1,
                        processData: {
                            value: 1,
                            'captions.text': 1
                        },
                        'location.captions.text': 1
                    }
                }
            },
            {
                $unwind: '$children'
            },
            {
                $project: {
                    serialNumber: 1,
                    children: 1
                }
            },
            {
                $unwind: '$children.processData'
            },
            {
                $match: {
                    'children.processData.value': searchTerm1
                }
            },
            {
                $project: {
                    serialNumber: 1,
                    date: {
                        $dateToString: {
                            date: {
                                $cond: {
                                    if: {
                                        $lte: [
                                            '$children.endTime',
                                            0
                                        ]
                                    },
                                    then: '$children.timestamp',
                                    else: {
                                        $toDate: '$children.endTime'
                                    }
                                }
                            },
                            format: '%m/%d/%Y'
                        }
                    },
                    location: {
                        $first: '$children.location.captions.text'
                    },
                    associatedTo: '$children.processData.value',
                    status: '$children.status',
                    type: 'processData',
                    label: {
                        $first: '$children.processData.captions.text'
                    }
                }
            }
        ];
    }
    get stringStages() {
        const searchTerm1 = this.formattedSearchTerm;
        return [
            {
                $unwind: {
                    path: '$customIdentifierData',
                    preserveNullAndEmptyArrays: true
                }
            },
            {
                $unwind: '$children'
            },
            {
                $unwind: {
                    path: '$children.processData',
                    preserveNullAndEmptyArrays: true
                }
            },
            {
                $match: {
                    $or: [
                        {
                            'children.processData.value': searchTerm1
                        },
                        {
                            'customIdentifierData.value': searchTerm1
                        },
                        {
                            'children.scannedBarcode': searchTerm1
                        }
                    ]
                }
            },
            {
                $addFields: {
                    customIdentifiers: {
                        $cond: {
                            if: {
                                $eq: [
                                    {
                                        $type: '$customIdentifierData.value'
                                    },
                                    'missing'
                                ]
                            },
                            then: [],
                            else: [
                                {
                                    type: 'customIdentifier',
                                    value: '$customIdentifierData.value',
                                    label: {
                                        $first: '$customIdentifierData.captions.text'
                                    }
                                }
                            ]
                        }
                    },
                    processData: {
                        $cond: {
                            if: {
                                $eq: [
                                    {
                                        $type: '$children.processData.value'
                                    },
                                    'missing'
                                ]
                            },
                            then: [],
                            else: [
                                {
                                    type: 'processData',
                                    value: '$children.processData.value',
                                    label: {
                                        $first: '$children.processData.captions.text'
                                    }
                                }
                            ]
                        }
                    },
                    barcodes: {
                        $cond: {
                            if: {
                                $eq: [
                                    {
                                        $type: '$children.scannedBarcode'
                                    },
                                    'null'
                                ]
                            },
                            then: [],
                            else: [
                                {
                                    type: 'barcode',
                                    value: '$children.scannedBarcode',
                                    label: {
                                        $first: '$children.marriageCaptions.text'
                                    }
                                }
                            ]
                        }
                    }
                }
            },
            {
                $project: {
                    serialNumber: 1,
                    station: {
                        location: '$location',
                        status: '$status',
                        timestamp: '$timestamp'
                    },
                    children: {
                        status: 1,
                        location: 1,
                        timestamp: {
                            $cond: {
                                if: {
                                    $lte: [
                                        '$children.endTime',
                                        0
                                    ]
                                },
                                then: '$children.timestamp',
                                else: {
                                    $toDate: '$children.endTime'
                                }
                            }
                        }
                    },
                    potentialMatches: {
                        $concatArrays: [
                            '$customIdentifiers',
                            '$processData',
                            '$barcodes'
                        ]
                    }
                }
            },
            {
                $unwind: '$potentialMatches'
            },
            {
                $match: {
                    'potentialMatches.value': searchTerm1
                }
            },
            {
                $project: {
                    serialNumber: 1,
                    location: {
                        $cond: {
                            if: {
                                $eq: [
                                    '$potentialMatches.type',
                                    'customIdentifier'
                                ]
                            },
                            then: {
                                $first: '$station.location.captions.text'
                            },
                            else: {
                                $first: '$children.location.captions.text'
                            }
                        }
                    },
                    status: {
                        $cond: {
                            if: {
                                $eq: [
                                    '$potentialMatches.type',
                                    'customIdentifier'
                                ]
                            },
                            then: '$station.status',
                            else: '$children.status'
                        }
                    },
                    dateRaw: {
                        $cond: {
                            if: {
                                $eq: [
                                    '$potentialMatches.type',
                                    'customIdentifier'
                                ]
                            },
                            then: '$station.timestamp',
                            else: '$children.timestamp'
                        }
                    },
                    type: '$potentialMatches.type',
                    value: '$potentialMatches.value',
                    label: '$potentialMatches.label'
                }
            },
            {
                $group: {
                    _id: {
                        serialNumber: '$serialNumber',
                        type: '$type',
                        value: '$value',
                        label: '$label'
                    },
                    location: {
                        $first: '$location'
                    },
                    status: {
                        $first: '$status'
                    },
                    dateRaw: {
                        $first: '$dateRaw'
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    serialNumber: '$_id.serialNumber',
                    type: '$_id.type',
                    associatedTo: '$_id.value',
                    label: '$_id.label',
                    location: 1,
                    status: 1,
                    date: {
                        $dateToString: {
                            date: '$dateRaw',
                            format: '%m/%d/%Y'
                        }
                    }
                }
            }
        ];
    }
    get tableConfiguration() {
        return {
            columns: [
                {
                    title: this.intl.t('bi.chartLabel.label'),
                    field: 'label',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('type'),
                    field: 'type',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('date'),
                    field: 'date',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('serialNumber'),
                    field: 'serialNumber',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('bi.chartLabel.location'),
                    field: 'location',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('bi.chartLabel.associatedTo'),
                    field: 'associatedTo',
                    formatter: 'textarea',
                    resizable: true,
                    headerWordWrap: true
                },
                {
                    title: this.intl.t('status'),
                    field: 'status',
                    formatter: (cell1)=>this.formatTaskStatusColumnData(cell1),
                    resizable: true,
                    headerWordWrap: true
                }
            ]
        };
    }
    get formattedSearchTerm() {
        return typeof this.params.searchTerm === 'number' || this.params.isNumericSearch ? parseInt(this.params.searchTerm) : Wildcard.convertWildcard(this.params.searchTerm);
    }
    getAssociationData = task({
        restartable: true
    }, waitFor(async (params1 = {})=>{
        const searchTerm1 = this.formattedSearchTerm;
        if (isEmpty(searchTerm1)) {
            return;
        }
        let sortStep1;
        if (params1.sort?.length > 0) {
            const dir1 = params1.sort[0].dir === 'asc' ? 1 : -1;
            const field1 = params1.sort[0].field;
            sortStep1 = {
                [field1]: dir1
            };
        } else {
            sortStep1 = {
                date: -1
            };
        }
        const matchStages1 = this.params.isNumericSearch ? this.numberStages : this.stringStages;
        const getAssociationDataQuery1 = await this.queryRunner.queryWithParams.perform(this.params, [
            {
                $match: {
                    $or: [
                        {
                            'customIdentifierData.value': searchTerm1
                        },
                        {
                            'children.processData.value': searchTerm1
                        },
                        {
                            'children.scannedBarcode': searchTerm1
                        }
                    ]
                }
            },
            {
                $project: {
                    _id: 0,
                    serialNumber: 1,
                    timestamp: 1,
                    status: 1,
                    'location.captions.text': 1,
                    children: {
                        'marriageCaptions.text': 1,
                        scannedBarcode: 1,
                        status: 1,
                        processData: {
                            value: 1,
                            'captions.text': 1
                        },
                        'location.captions.text': 1,
                        endTime: 1,
                        timestamp: 1
                    },
                    customIdentifierData: {
                        value: 1,
                        'captions.text': 1
                    }
                }
            },
            ...matchStages1,
            {
                $sort: sortStep1
            },
            {
                $skip: params1.skip ?? 0
            },
            {
                $limit: params1.size ?? 100_000
            }
        ]);
        const associations1 = await this.queryRunner.runQuery.perform('BuildStatuses', getAssociationDataQuery1, true);
        const data1 = associations1.result?.map((row1)=>({
                label: row1.label,
                type: this.intl.t(row1.type),
                date: row1.date,
                serialNumber: row1.serialNumber,
                location: row1.location,
                associatedTo: row1.associatedTo,
                status: row1.status
            }));
        return {
            count: associations1.meta?.count,
            data: data1,
            exportData: ()=>this.formatExportData(clone(data1))
        };
    }));
    associationData = trackedTask(this, this.getAssociationData, ()=>[
            this.params
        ]);
    formatTaskStatusColumnData = (cell1)=>{
        const { status: status1 } = cell1.getData();
        const statusIsPass1 = status1 >= 251;
        Object.assign(cell1.getElement().style, {
            color: statusIsPass1 ? echartsTheme.colorPalette.taskRowGreen : echartsTheme.colorPalette.taskRowRed
        });
        return statusIsPass1 ? this.intl.t('pass') : this.intl.t('fail');
    };
    formatExportData(data1) {
        return data1.map((row1)=>{
            const { label: label1, type: type1, date: date1, serialNumber: serialNumber1, taskName: taskName1, associatedTo: associatedTo1, taskStatus: taskStatus1 } = row1;
            return {
                label: label1,
                type: type1,
                date: date1,
                serialNumber: serialNumber1,
                taskName: taskName1,
                associatedTo: associatedTo1,
                taskStatus: taskStatus1
            };
        });
    }
    static{
        template(`
    <div class="bi-tabulator component-bi-process-data-lookup-table" ...attributes>
      {{#if this.associationData.isRunning}}
        <Spinner />
      {{else if this.associationData.value.count}}
        <TabulatorTable
          @tableConfiguration={{this.tableConfiguration}}
          @getRowData={{this.getAssociationData.perform}}
          @updateDataAttr={{this.params}}
          @exportFilename={{t 'bi.chartLabel.processDataLookup'}}
          @exportDataFunction={{this.associationData.value.exportData}}
        />
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
