import { attr, belongsTo } from '@ember-data/model';
import SyncBase from 'eflex/models/kinetic-config/sync-base';

export default class KineticConfig extends SyncBase {
  @attr('string') companyId;
  @attr('string') siteId;
  @attr('string') siteName;
  @attr('string') timezone;

  @belongsTo('kineticConfig/bom', { inverse: null, async: false, embedded: true }) bom;
}
