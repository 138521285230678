import Controller from '@ember/controller';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

export default class MissingConfigsController extends Controller {
  @service taskConfigRepo;
  @service taskRepo;
  @service locationRepo;

  repair = task({ drop: true }, waitFor(async () => {
    for (const taskRecord of this.taskRepo.tasks) {
      await this.taskConfigRepo.loadTaskConfigs.perform(taskRecord);

      const { area } = taskRecord;

      if (taskRecord.usesModels) {
        area.models.forEach(model => {
          if (!this.taskRepo.getConfig(taskRecord, model)) {
            this.taskRepo.createConfig(taskRecord, model);
          }
        });
      } else if (area?.usesComponents && area.bomSource != null) {
        const component = taskRecord.component;
        component.options.forEach(option => {
          if (!this.taskRepo.getConfig(taskRecord, option)) {
            this.taskRepo.createConfig(taskRecord, option);
          }
        });
      }
    }

    await this.locationRepo.bulkSave.perform({
      source: 'missing configs repair',
      tasks: this.taskRepo.tasks,
      taskConfigs: this.taskRepo.tasks.flatMap(_task => _task.taskConfigs),
    });
  }));
}
