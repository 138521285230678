import { template } from "@ember/template-compiler";
import { isEmpty } from '@ember/utils';
import { taskGroup, didCancel, task, waitForEvent } from 'ember-concurrency';
import { mergeRight } from 'ramda';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { destroy, associateDestroyableChild, registerDestructor } from '@ember/destroyable';
import { waitFor } from '@ember/test-waiters';
import exportToCsv from 'eflex/util/export-to-csv';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
// eslint-disable-next-line ember/no-at-ember-render-modifiers
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { eventBus } from 'eflex/helpers';
import { t } from 'ember-intl';
const setNested = (datum1)=>{
    if (isEmpty(datum1?.children)) {
        return;
    }
    datum1._children = [];
    datum1.children.forEach((child1)=>{
        setNested(child1);
    });
};
const setTableScroll = (element1, scrollHeight1)=>{
    element1.querySelector('.tabulator-tableholder').scrollTop = scrollHeight1;
};
const getExtraIcon = (element1, name1)=>{
    return element1.querySelector(`.extra-icons .${name1}-icon`);
};
const onDataTreeRowCollapsed = (row1)=>{
    const rowElement1 = row1.getElement();
    rowElement1.classList.remove('opened');
    destroy(rowElement1.destroyChild);
};
export default class TabulatorTable extends Component {
    //  @tableConfiguration expected format:
    //    columns:  []
    //    options:  null
    //    childConfiguration: null
    @taskGroup({
        enqueue: true
    })
    loadingTasks;
    @tracked
    startCount = 0;
    @tracked
    endCount = 0;
    @tracked
    totalCount = 0;
    scrollHeight = 0;
    createTable = task({
        group: 'loadingTasks'
    }, waitFor(async (element1)=>{
        const options1 = mergeRight({
            autoResize: !window.isTesting && !window.isIntegrationTest,
            ajaxURL: 'blank',
            columnHeaderSortMulti: false,
            columns: this.args.tableConfiguration.columns,
            dataTree: true,
            dataTreeBranchElement: false,
            dataTreeChildIndent: 50,
            dataTreeCollapseElement: getExtraIcon(element1, 'collapse'),
            dataTreeExpandElement: getExtraIcon(element1, 'expand'),
            footerElement: '.table-footer',
            height: '100%',
            layout: 'fitColumns',
            layoutColumnsOnNewData: true,
            renderVerticalBuffer: 5000,
            columnDefaults: {
                headerSortTristate: true,
                resizable: false,
                tooltip: true
            },
            rowFormatter: (row1)=>{
                this.scrollHeight = element1.querySelector('.tabulator-tableholder').scrollTop;
                const rowElement1 = row1.getElement();
                const classes1 = [
                    'parent-row'
                ];
                if (row1._row.modules?.dataTree?.children) {
                    classes1.push('has-children');
                }
                rowElement1.classList.add(...classes1);
            }
        }, this.args.tableConfiguration.options ?? {});
        if (this.args.data) {
            options1.data = this.args.data;
        } else {
            Object.assign(options1, {
                sortMode: 'remote',
                pagination: true,
                paginationMode: 'remote',
                paginationButtonCount: 0,
                paginationSize: 30,
                ajaxRequestFunc: async (url1, config1, params1)=>{
                    try {
                        return await this._requestData.perform(element1, params1);
                    } catch (e1) {
                        if (!didCancel(e1)) {
                            throw e1;
                        }
                        return {
                            data: [],
                            exportData: ()=>[],
                            last_page: 0
                        };
                    }
                }
            });
        }
        const { TabulatorFull: TabulatorFull1 } = await import('tabulator-tables');
        let table1 = new TabulatorFull1(element1.querySelector('.tabulator-table'), options1);
        table1.on('dataLoaded', (data1)=>{
            if (!data1) {
                return;
            }
            // hack to use built-in expandable rows with nested tables
            data1?.forEach((datum1)=>{
                setNested(datum1);
            });
        });
        table1.on('dataTreeRowCollapsed', (row1)=>{
            setTableScroll(element1, this.scrollHeight);
            onDataTreeRowCollapsed(row1);
        });
        table1.on('dataTreeRowExpanded', (row1)=>{
            setTableScroll(element1, this.scrollHeight);
            this._onDataTreeRowExpanded.perform(row1, {
                rowData: row1.getData(),
                tableConfiguration: this.args.tableConfiguration.childConfiguration,
                expandElement: getExtraIcon(element1, 'expand'),
                collapseElement: getExtraIcon(element1, 'collapse')
            });
        });
        registerDestructor(this, ()=>{
            table1.destroy();
            table1 = null;
        });
        await this._afterTableRender.perform(table1);
        if (!this.args.data) {
            element1.querySelector('.tabulator-page[data-page="first"]').innerHTML = '<div class="icon icon-previous-double-arrow"/>';
            element1.querySelector('.tabulator-page[data-page="prev"]').innerHTML = '<div class="icon icon-previous-arrow"/>';
            element1.querySelector('.tabulator-page[data-page="next"]').innerHTML = '<div class="icon icon-next-arrow"/>';
            element1.querySelector('.tabulator-page[data-page="last"]').innerHTML = '<div class="icon icon-next-double-arrow"/>';
        }
    }));
    _onDataTreeRowExpanded = task(waitFor(async (parentRow1, args1)=>{
        const parentElement1 = parentRow1.getElement();
        parentElement1.classList.add('opened');
        parentElement1.destroyChild = {};
        associateDestroyableChild(this, parentElement1.destroyChild);
        let childContainer1 = document.createElement('div');
        childContainer1.classList.add('child-container');
        let childElement1 = document.createElement('div');
        if (args1.rowData) {
            childElement1.classList.add('child-table', `child-${args1.rowData.id}`);
        }
        childContainer1.append(childElement1);
        parentElement1.append(childContainer1);
        const { TabulatorFull: TabulatorFull1 } = await import('tabulator-tables');
        let table1 = new TabulatorFull1(childElement1, mergeRight({
            autoResize: !window.isTesting && !window.isIntegrationTest,
            columnHeaderSortMulti: false,
            columns: args1.tableConfiguration.columns,
            data: args1.rowData?.children,
            dataTree: true,
            dataTreeBranchElement: false,
            dataTreeChildIndent: 50,
            dataTreeCollapseElement: args1.collapseElement,
            dataTreeExpandElement: args1.expandElement,
            height: '100%',
            layout: 'fitColumns',
            layoutColumnsOnNewData: true,
            columnDefaults: {
                headerSortTristate: true,
                resizable: false,
                tooltip: true
            },
            rowFormatter: (row1)=>{
                const rowElement1 = row1.getElement();
                const classes1 = [
                    'child-row'
                ];
                if (row1._row.modules?.dataTree?.children) {
                    classes1.push('has-children');
                }
                rowElement1.classList.add(...classes1);
            }
        }, args1.tableConfiguration.options ?? {}));
        registerDestructor(table1, ()=>{
            table1.destroy();
            childElement1.remove();
            childContainer1.remove();
            childElement1 = null;
            childContainer1 = null;
            table1 = null;
        });
        associateDestroyableChild(parentElement1.destroyChild, table1);
        table1.on('dataTreeRowCollapsed', (row1)=>{
            onDataTreeRowCollapsed(row1);
        });
        table1.on('dataTreeRowExpanded', (row1)=>{
            this._onDataTreeRowExpanded.perform(row1, {
                rowData: row1.getData(),
                tableConfiguration: args1.tableConfiguration.childConfiguration,
                expandElement: args1.expandElement,
                collapseElement: args1.collapseElement
            });
        });
        await this._afterTableRender.perform(table1);
    }));
    _afterTableRender = task(waitFor(async (table1)=>{
        table1.on('rowClick', (e1, row1)=>{
            e1.stopPropagation();
            e1.preventDefault();
            const headerClick1 = e1.target.parentElement.className.startsWith('tabulator-col');
            if (!headerClick1 && !isEmpty(row1.getData()?.children)) {
                row1.treeToggle();
            }
        });
        await waitForEvent(table1, 'tableBuilt');
    }));
    _requestData = task(waitFor(async (element1, params1)=>{
        const skip1 = (params1.page - 1) * params1.size;
        params1.skip = skip1;
        const result1 = await this.args.getRowData?.(params1);
        if (result1 == null) {
            return {
                data: [],
                exportData: ()=>[],
                last_page: 0
            };
        }
        const { data: data1, count: count1 } = result1;
        let endCount1 = skip1 + params1.size;
        if (endCount1 > count1) {
            endCount1 = count1;
        }
        Object.assign(this, {
            startCount: skip1 + 1,
            endCount: endCount1,
            totalCount: count1
        });
        const table1 = await this._getTable.perform(element1);
        return {
            data: data1,
            last_page: Math.ceil(count1 / table1.options?.paginationSize)
        };
    }));
    onDidUpdate = task({
        group: 'loadingTasks'
    }, waitFor(async (element1, [data1])=>{
        const table1 = await this._getTable.perform(element1);
        table1?.setData(data1);
    }));
    _getTable = task(waitFor(async (element1)=>{
        const { TabulatorFull: TabulatorFull1 } = await import('tabulator-tables');
        return TabulatorFull1.findTable(element1.querySelector('.tabulator-table'))[0];
    }));
    exportToCsv = task(waitFor(async ()=>{
        await exportToCsv(this.args.exportDataFunction, this.args.exportFilename);
    }));
    static{
        template(`
    {{eventBus exportToCsv=this.exportToCsv.perform}}

    <div
      class="component-tabulator-table"
      {{didInsert this.createTable.perform}}
      {{didUpdate this.onDidUpdate.perform @data @updateDataAttr}}
      ...attributes
    >
      <div class="tabulator-table h-100"></div>
      {{#unless @data}}
        <div class="table-footer tabulator-footer d-flex align-items-baseline">
          <div class="extra-icons d-none">
            <FaIcon @icon="caret-down" @prefix="fas" class="expand-icon me-2" />
            <FaIcon @icon="caret-up" @prefix="fas" class="collapse-icon me-2" />
          </div>
          <div class="page-count ms-auto me-3">
            {{t "table.pageCount" start=this.startCount end=this.endCount total=this.totalCount}}
          </div>
        </div>
      {{/unless}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
