import { template } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import NoRouteTabs from 'eflex/components/no-route-tabs';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { append, findBy } from '@eflexsystems/ember-composable-helpers';
import { add } from 'ramda';
import PowerSelectWithCreate from 'eflex/components/power-select-with-create';
import TrashCan from 'eflex/components/trash-can';
import QuantitySelect from 'eflex/components/quantity-select';
export default class TaskConfigNodeRed extends Component {
    @tracked
    currentVariableTypeTab = `deviceToVariables-${this.args.taskConfig.id}`;
    @service
    variableDefRepo;
    @service
    taskRepo;
    get filteredJemProcessDataDefs() {
        return this.allPreviousTasks?.flatMap((_task1)=>_task1.jemProcessDataDefs).filter((item1)=>!item1.isDeleted) ?? [];
    }
    get showDeviceFrom() {
        return this.currentVariableTypeTab === this.deviceFromId;
    }
    get deviceToId() {
        return `deviceToVariables-${this.args.taskConfig.id}`;
    }
    get deviceFromId() {
        return `deviceFromVariables-${this.args.taskConfig.id}`;
    }
    get filteredVariableDefs() {
        return this.allPreviousTasks?.flatMap((_task1)=>_task1.variableDefs).filter((def1)=>!def1.isDeleted && def1.isFromDevice) ?? [];
    }
    // jscpd:ignore-start
    get displayVariables() {
        return this.args.task.variableDefs.filter((item1)=>!item1.isDeleted).filter((item1)=>item1.isFromDevice === this.showDeviceFrom) ?? [];
    }
    get allPreviousTasks() {
        return this.taskRepo.getAllPreviousTasks(this.args.task);
    }
    setVariableValue = (variableDefConfig1, value1)=>{
        this.variableDefRepo.setVariableValue(variableDefConfig1, value1);
    };
    addMultipleVariables = (variableQuantity1)=>{
        for(let i1 = 0; i1 < variableQuantity1; i1++){
            this.variableDefRepo.createVariableDef({
                task: this.args.task,
                isFromDevice: this.showDeviceFrom
            });
        }
    };
    // jscpd:ignore-end
    deleteVariable = (variableDef1)=>{
        this.variableDefRepo.deleteVariable(variableDef1);
    };
    static{
        template(`
    <NoRouteTabs
      @activeTab={{this.currentVariableTypeTab}}
      @tabBecameActive={{fn (mut this.currentVariableTypeTab)}}
    as |tabs|>
      <tabs.navigation class="mb-3" as |nav|>
        <nav.tab @href={{this.deviceToId}} class="device-to-variables">
          {{t "deviceToVariables"}}
        </nav.tab>
        <nav.tab @href={{this.deviceFromId}} class="device-from-variables">
          {{t "deviceFromVariables"}}
        </nav.tab>
      </tabs.navigation>
      <tabs.panes as |panes|>
        <panes.pane @id={{this.deviceToId}}>
          <div class="bootstrap-table">
            <div class="row form-row header-row">
              <div class="col-icon border-0" />
              <div class="col-icon border-0">
                <FaIcon @icon="hashtag" @prefix="fas" />
              </div>
              <div class="col">
                {{t "variableName"}}
              </div>
              <div class="col model-specific">
                {{t "variableValue"}}
              </div>
              <div class="col-icon border-0" />
            </div>
            {{#each this.displayVariables as |variable index|}}
              {{#let
                (findBy
                  "variableDef" variable @taskConfig.variableDefConfigs
                ) as |variableDefConfig|
              }}
                <div class="variable-row row form-row">
                  <div class="col-icon model-specific">
                    <@form.element
                      @controlType="checkbox"
                      @model={{variableDefConfig}}
                      @property="enabled"
                    />
                  </div>
                  <div class="col-icon">
                    <span>
                      {{add index 1}}
                    </span>
                  </div>
                  <div class="col">
                    <@form.element
                      @controlType="text"
                      @model={{variable}}
                      @property="name"
                      class="variable-name"
                    />
                  </div>
                  <div class="col model-specific">
                    <@form.element>
                      <PowerSelectWithCreate
                        @triggerClass="form-control"
                        @allowClear={{true}}
                        @options={{
                          append
                          this.filteredVariableDefs
                          this.filteredJemProcessDataDefs
                          variableDefConfig.formattedValue
                        }}
                        @selected={{variableDefConfig.selectedValue}}
                        @searchEnabled={{true}}
                        @searchField="name"
                        @disabled={{@disabled}}
                        @onCreate={{
                          fn this.setVariableValue variableDefConfig
                        }}
                        @onChange={{
                          fn this.setVariableValue variableDefConfig
                        }}
                        class="variable-value" as |value|
                      >
                        <span title={{value.task.parent.name}} class="w-100">
                          {{value.name}}
                        </span>
                      </PowerSelectWithCreate>
                    </@form.element>
                  </div>
                  <div class="col-icon">
                    <TrashCan
                      disabled={{@disabled}}
                      @onDelete={{fn this.deleteVariable variable}}
                    />
                  </div>
                </div>
              {{/let}}
            {{/each}}
            <div class="row form-row no-hover-row">
              <div class="col-auto">
                <QuantitySelect
                  class="px-2 text-uppercase"
                  @disabled={{@disabled}}
                  @onSubmit={{this.addMultipleVariables}}
                >
                  {{t "components.addVariable"}}
                </QuantitySelect>
              </div>
            </div>
          </div>
        </panes.pane>
        <panes.pane @id={{this.deviceFromId}}>
          <div class="bootstrap-table">
            <div class="row form-row header-row">
              <div class="col">
                {{t "variableName"}}
              </div>
              <div class="col-icon border-0" />
            </div>
            {{#each this.displayVariables as |variable|}}
              <div class="variable-row row form-row">
                <div class="col">
                  <@form.element
                    @controlType="text"
                    @model={{variable}}
                    @property="name"
                    class="variable-name"
                  />
                </div>
                <div class="col-icon">
                  <TrashCan
                    disabled={{@disabled}}
                    @onDelete={{fn this.deleteVariable variable}}
                  />
                </div>
              </div>
            {{/each}}
            <div class="row form-row no-hover-row">
              <div class="col-auto">
                <QuantitySelect
                  class="px-2 text-uppercase"
                  @disabled={{@disabled}}
                  @onSubmit={{this.addMultipleVariables}}
                >
                  {{t "components.addVariable"}}
                </QuantitySelect>
              </div>
            </div>
          </div>
        </panes.pane>
      </tabs.panes>
    </NoRouteTabs>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
