import { service } from '@ember/service';
import LocationRepoBase from 'eflex/services/location-repo-base';
import { assert } from '@ember/debug';
import { isPresent } from '@ember/utils';
import { tap, filter, without, prop } from 'ramda';
import { capitalize } from '@ember/string';
import { intoArray, maxOf } from '@eflexsystems/ramda-helpers';
import { sortByPaths } from 'ramda-adjunct';

export default class TaskRepoService extends LocationRepoBase {
  @service taskConfigRepo;
  @service buildDataRepo;
  @service systemConfig;
  @service store;
  @service intl;

  tasks = this.store.peekAll('task');

  create(properties = {}) {
    assert('task must have a task type', properties.taskType);
    const station = properties.parent;
    const defaultCamera = this.systemConfig.evision?.fileRetention?.defaultCameraConfiguration;

    if (defaultCamera) {
      properties.cameraConfiguration = defaultCamera.copy();
    } else {
      properties.cameraConfiguration = this.store.createRecord('cameraConfiguration', {
        daysToStorePassedCompressed: 365,
        daysToStoreFailedCompressed: 365,
        daysToStorePassedRaw: 365,
        daysToStoreFailedRaw: 365,
        daysToStoreThumbnails: 365,
        evisionEnabled: true,
      });
    }

    const taskRecord = super.create('task', properties);

    if (isPresent(properties.row)) {
      this.shiftSequenceDown(station, taskRecord);
    } else {
      this.addToEndOfSequence(station, taskRecord);
    }

    const { area } = taskRecord;

    this.applyDefaults(taskRecord);

    if (taskRecord.usesModels) {
      area.models.forEach(model => { this.createConfig(taskRecord, model); });
    } else if (area?.usesComponents && area?.bomSource != null) {
      const alwaysRun = area.alwaysRunComponent;
      assert('need an always run component', alwaysRun);
      taskRecord.component = alwaysRun;
      this.createConfig(taskRecord, alwaysRun.options?.[0]);
    } else if (taskRecord.parent.type === 'kineticOperation') {
      this.createConfig(taskRecord);
    }

    return taskRecord;
  }

  applyDefaults(taskRecord) {
    this.store.createRecord('decisionDef', {
      task: taskRecord,
      name: this.intl.t('pass'),
    });

    this.store.createRecord('decisionDef', {
      task: taskRecord,
      name: this.intl.t('fail'),
    });

    const typeName = capitalize(taskRecord.taskType);
    this[`_setup${typeName}`]?.(taskRecord);
  }

  handleContextChange(taskRecord, context) {
    const typeName = capitalize(taskRecord.taskType);
    this[`_contextChange${typeName}`]?.(taskRecord, context);
  }

  delete(deleted) {
    deleted.taskConfigs.forEach(taskConfig => { taskConfig.deleteRecord(); });
    deleted.deleteRecord();
  }

  addToEndOfSequence(station, taskRecord) {
    const siblings = without([taskRecord], station.children);
    let row = 1;

    if (isPresent(siblings)) {
      row = maxOf(prop('row'), siblings) + 1;
    }

    Object.assign(taskRecord, {
      row,
      column: 1,
    });
  }

  shiftSequenceDown(station, taskRecord) {
    intoArray(
      filter((task) => task.row >= taskRecord.row),
      without([taskRecord]),
      tap(task => { task.row += 1; }),
    )(station.children);
  }

  changeType(taskRecord, type) {
    Object.assign(taskRecord, {
      taskType: type,
      hardware: null,
      hardwareInput: null,
      showOkButton: true,
      decisionRequired: false,
    });

    taskRecord.hardwareOutputs.length = 0;
    taskRecord.hardwareInputDefs.forEach(hardwareInputDef => { hardwareInputDef.deleteRecord(); });
    taskRecord.jemProcessDataDefs.forEach(jemProcessDataDef => { jemProcessDataDef.deleteRecord(); });
    taskRecord.decisionDefs.forEach(decisionDef => { decisionDef.deleteRecord(); });
    taskRecord.variableDefs.forEach(variableDef => { variableDef.deleteRecord(); });
    taskRecord.spindles.forEach(spindle => { spindle.deleteRecord(); });
    taskRecord.strings.forEach(string => { string.deleteRecord(); });

    this.applyDefaults(taskRecord);

    taskRecord.taskConfigs.forEach(taskConfig => {
      this.taskConfigRepo.changeType(taskConfig);
    });
  }

  getWorkInstructionTriggerConfigs(taskRecord, context) {
    return this
      .getConfig(taskRecord, context)?.triggerConfigs
      ?.filter(item => item.constructor.modelName === 'work-instruction-hardware-trigger-config');
  }

  getConfigForModelOrBuildDatum = (taskRecord, model, buildDatum) => {
    if (taskRecord == null) {
      return;
    }

    if (taskRecord.usesOperations) {
      return taskRecord.children[0];
    }

    if (model != null) {
      return this.getConfig(taskRecord, model);
    }

    if (taskRecord.usesComponents && buildDatum?.components != null) {
      return this.buildDataRepo.getTaskConfigForBuildDatum(taskRecord, buildDatum);
    }

    return null;
  };

  getConfig(taskRecord, context) {
    if (context == null) {
      return null;
    }

    assert('context may not be a component. only model or component option.',
      context.constructor.modelName !== 'component');

    if (taskRecord.usesModels) {
      return taskRecord.taskConfigs.find(taskConfig => taskConfig.configModel === context);
    } else {
      return taskRecord.taskConfigs.find(taskConfig => taskConfig.configOption === context);
    }
  }

  createConfig(taskRecord, context) {
    const options = { parent: taskRecord };
    if (taskRecord.usesModels) {
      options.configModel = context;
    } else if (taskRecord.usesComponents) {
      options.configOption = context;
    }

    return this.taskConfigRepo.create(options);
  }

  createComponentOptionConfigs(taskRecord, component) {
    component.options.forEach(option => {
      this.createConfig(taskRecord, option);
    });
  }

  getAllPreviousTasks(treeTask, includeSelf = false) {
    const allTasks = sortByPaths(
      [
        ['area', 'order'],
        ['group', 'order'],
        ['station', 'order'],
        ['row'],
        ['column'],
      ],
      this.tasks,
    );

    let index = allTasks.indexOf(treeTask);

    if (index === -1) {
      return [];
    }

    if (includeSelf) {
      index = index + 1;
    }

    return allTasks.slice(0, index);
  }

  removeEdhrMappingsBelowMaxBoltCount(treeTask) {
    treeTask.triggers
      .flatMap(trigger => trigger.edhrMappings)
      .filter(mapping => mapping?.boltIndex > treeTask.maxBoltCount)
      .forEach(trigger => { trigger.deleteRecord(); });
  }

  removeTaskBasedEdhrMappings(treeTask) {
    treeTask.edhrMappings
      .filter(item => item.dataTask)
      .forEach(edhrMapping => { edhrMapping.deleteRecord(); });
  }

  copyTask(treeTask, parent, keepName = false) {
    const taskCopy = treeTask.copy(true);
    taskCopy.parent = parent;
    if (!keepName) {
      taskCopy.name = `Copy of ${treeTask.name}`;
    }

    this.updateLocationPaths(taskCopy);
    this.addToEndOfSequence(parent, taskCopy);

    treeTask.edhrMappings.forEach(edhrMapping => {
      const edhrMappingCopy = edhrMapping.copy(true);
      edhrMappingCopy.task = taskCopy;
    });

    treeTask.triggers.forEach(trigger => {
      const triggerCopy = trigger.copy(true);
      triggerCopy.task = taskCopy;

      trigger.edhrMappings?.forEach(edhrMapping => {
        triggerCopy.edhrMappings.push(edhrMapping.copy(true));
      });

      trigger.variableMappings?.forEach(variableMapping => {
        triggerCopy.variableMappings.push(variableMapping.copy(true));
      });
    });

    treeTask.taskConfigs.forEach(taskConfig => {
      this.taskConfigRepo.copyTaskConfig(taskConfig, taskCopy);
    });

    return taskCopy;
  }

  _setupImageCapture(taskRecord) {
    taskRecord.isVision = true;
    taskRecord.cameraConfiguration.evisionEnabled = true;
  }

  _setupBarcode(taskRecord) {
    taskRecord.strings.push(this.store.createRecord('barcodeString', {}));
  }

  _setupVision(taskRecord) {
    taskRecord.isVision = true;
    taskRecord.cameraConfiguration.evisionEnabled = true;
  }

  _setupMultispindle(taskRecord) {
    taskRecord.spindles.push(this.store.createRecord('spindle', { task: taskRecord }));
  }

  _contextChangeBarcode(taskRecord, context) {
    if (taskRecord.usesComponents && context.isAlwaysRun) {
      taskRecord.passThrough = false;
    }
  }
}
