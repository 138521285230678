import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import SyncStatuses from 'eflex/constants/integration-sync-statuses';
import { OUTBOX_EVENT_MAPPINGS } from 'eflex/constants/integration-sync-events';

export default class SettingsKineticController extends Controller {
  @service currentUser;
  @service systemConfig;
  @service eflexAjax;

  @tracked selectedEvents;

  events = [
    { name: 'kinetic.sync.reasonCode', value: OUTBOX_EVENT_MAPPINGS.SyncReasonCode },
    { name: 'kinetic.sync.shift', value: OUTBOX_EVENT_MAPPINGS.SyncShift },
    { name: 'kinetic.sync.employee', value: OUTBOX_EVENT_MAPPINGS.SyncEmployee },
    { name: 'kinetic.sync.station', value: OUTBOX_EVENT_MAPPINGS.SyncStation },
  ];

  get systemConfiguration() {
    return this.systemConfig.config;
  }

  get kinetic() {
    return this.systemConfiguration.kinetic;
  }

  get disabled() {
    return this.currentUser.isNotAdmin;
  }

  get syncDisabled() {
    return this.disabled || isEmpty(this.kinetic.siteId) || isEmpty(this.kinetic.companyId);
  }

  sync = task(waitFor(async () => {
    this.kinetic.syncStatus = SyncStatuses.SYNCING;
    await this.systemConfiguration.save();
    await this.eflexAjax.post.perform('kinetic/kineticSync', {
      siteId: this.kinetic.siteId,
      companyId: this.kinetic.companyId,
    });
  }));

  unsync = task(waitFor(async () => {
    this.kinetic.syncStatus = SyncStatuses.NO_SYNC;
    this.kinetic.siteId = null;
    this.kinetic.companyId = null;

    await this.systemConfiguration.save();
  }));

  manualSync = task(waitFor(async () => {
    await Promise.all(this.selectedEvents.map(event => {
      return this.eflexAjax.post.perform('integrationOutboxes', {
        eventName: event.value,
        data: {
          siteId: this.kinetic.siteId,
          company: this.kinetic.companyId,
        },
      });
    }));
  }));
}
