import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import LocaleMap from 'eflex/util/locale-map';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { capitalize } from '@ember/string';
import { toLower } from 'ramda';
import BsDropdown from 'ember-bootstrap/components/bs-dropdown';
import BsButton from 'eflex/components/bs-button';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { eq } from 'ember-truth-helpers';
import { fn, concat } from '@ember/helper';
import { t } from 'ember-intl';
import { transitionTo } from 'eflex/helpers';
import { on } from '@ember/modifier';
import StandardModal from 'eflex/components/standard-modal';
export default class UserDropdown extends Component {
    @service
    currentUser;
    @service
    session;
    languages = LocaleMap;
    @tracked
    showLogoutModal = false;
    get user() {
        return this.currentUser.user;
    }
    get userRole() {
        return this.user?.role;
    }
    get userAbbreviation() {
        if (!this.user) {
            return null;
        }
        return capitalize((this.user.userName.slice(0, 1)));
    }
    noHide = ()=>{
        return false;
    };
    languageChanged = (language1)=>{
        return this.currentUser.changeLanguage.perform(language1.lang);
    };
    logOut = ()=>{
        this.session.invalidate();
    };
    static{
        template(`
    <BsDropdown
      class="user-dropdown-menu"
      @direction="bottom"
      @closeOnMenuClick={{false}}
      ...attributes
    as |dd|>
      <dd.toggle class="user-dropdown">
        <BsButton
          class="fw-bold rounded-circle w-auto {{if (eq this.userRole "Admin") "text-gray-900"}}"
          @size="md"
          @type={{if (eq this.userRole "Admin") "orange" "primary"}}
        >
          {{this.userAbbreviation}}
        </BsButton>
      </dd.toggle>

      <dd.menu class="dropdown-menu-dark overflow-visible fs-3 me-3" as |menu|>
        <menu.item>
          <div class="dropdown-item-text user-option d-flex pt-3">
            <FaIcon
              @icon="user"
              @prefix="fas"
              @size="xl"
              class="me-3"
            />
            <div class="d-flex flex-grow-1 justify-content-between">
              <div class="text-white">
                {{this.user.userName}}
              </div>

              <div
                class="
                  badge
                  rounded-pill
                  fs-4
                  {{if (eq this.userRole "Admin") "bg-orange text-gray-900"}}
                  {{if (eq this.userRole "User") "bg-primary"}}
                  {{if (eq this.userRole "ViewOnly") "bg-secondary text-gray-900"}}"
              >
                {{#if this.user}}
                  {{t (concat "user.menu." (toLower this.userRole))}}
                {{/if}}
              </div>
            </div>
          </div>
        </menu.item>

        <menu.item>
          <hr class="dropdown-divider">
        </menu.item>

        {{#if @showHeaderButtons}}
          <menu.item>
            <div
              class="user-option user-profile dropdown-item"
              {{on "click" (transitionTo 'settings')}}
            >
              <FaIcon
                @icon="user"
                @prefix="fas"
                @size="sm"
                class="me-3"
              /> {{t "user.menu.profile"}}
            </div>
          </menu.item>
        {{/if}}

        <menu.item>
          <div class="user-option dropdown-item">
            <BsDropdown
              class="z-top-menu"
              @direction="bottom"
            as |languageDropdown|>
              <languageDropdown.toggle class="language-dropdown d-flex w-100">
                <FaIcon
                  @icon="earth-americas"
                  @prefix="fas"
                  @size="sm"
                  class="me-3"
                /> {{t "settings.language"}}
              </languageDropdown.toggle>

              <languageDropdown.menu class="dropdown-menu-dark" as |subMenu|>
                {{#each this.languages as |language|}}
                  <subMenu.item>
                    <div
                      class="language-option dropdown-item"
                      {{on "click" (fn this.languageChanged language)}}
                    >
                      {{language.label}}
                    </div>
                  </subMenu.item>
                {{/each}}
              </languageDropdown.menu>
            </BsDropdown>
          </div>
        </menu.item>

        {{#if @showHeaderButtons}}
          <menu.item>
            <div class="user-option dropdown-item">
              {{#if this.session.isAuthenticated}}
                <a
                  class="log-out"
                  {{on "click" (fn (mut this.showLogoutModal) true)}}
                >
                  <FaIcon
                    @icon="right-from-bracket"
                    @prefix="fas"
                    @size="sm"
                    class="me-3"
                  /> {{t "controls.login.logout"}}
                </a>
              {{/if}}

              {{#if this.showLogoutModal}}
                <StandardModal
                  class="confirm-logout"
                  @onCancel={{fn (mut this.showLogoutModal) false}}
                  @onConfirm={{this.logOut}}
                  @size="sm"
                as |Modal|>
                  <Modal.header>
                    <h5>{{t "controls.login.logout"}}</h5>
                  </Modal.header>
                  <Modal.body>
                    <p class="fs-2">{{t "areyousure.logout"}}</p>
                  </Modal.body>
                  <Modal.footer />
                </StandardModal>
              {{/if}}
            </div>
          </menu.item>
        {{/if}}
      </dd.menu>
    </BsDropdown>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
