import { template } from "@ember/template-compiler";
import NavBarMap from 'eflex/util/nav-bar-map';
import Portal from 'ember-stargate/components/portal';
import { serviceHelper } from 'eflex/helpers';
import { concat, get } from '@ember/helper';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
import { prop, indexBy } from 'ramda';
import Link from 'eflex/components/link';
import BsTooltip from 'eflex/components/bs-tooltip';
const navItems = indexBy(prop('key'), NavBarMap);
export default template(`
<Portal @target="breadcrumbs">
  <div
    class="component-breadcrumbs row align-items-start g-0 hidden-print justify-content-between z-title-bar w-100
      {{if (get (serviceHelper 'resizeListener') 'navBarCollapsed') 'collapsed' 'full'}}"
    ...attributes
  >
    <div class="col row gx-0">
      <div class="col d-flex flex-column">
        <div
          class="d-flex align-items-center section-name app-section-name text-gray-900 text-capitalize fs-4 mb-1"
        >
          {{#if @navKey}}
            <div class="d-flex align-items-center text-gray-600">
              {{#let (get navItems @navKey) as |submenu|}}
                {{#if submenu.children}}
                  {{#if submenu.faIcon}}
                    <FaIcon
                      @icon={{submenu.faIcon}}
                      @prefix="fas"
                      @size="sm"
                      class="me-2"
                    />
                  {{else}}
                    <div class='fa {{submenu.icon}} opacity-75 me-2'></div>
                  {{/if}}
                  <span class="submenu-name">{{t (concat 'navigation.' submenu.label)}}</span>
                  <span class="ms-2">/</span>
                {{/if}}
              {{/let}}
            </div>
            {{#if @prevPage}}
              <Link
                class="prev-screen-name link-gray-900 fw-semibold ms-2"
                @route={{@prevPageRoute}}
                @model={{@prevPageModel}}
              >
                {{@prevPage}}
              </Link>
              <span class="text-gray-500 ms-2">/</span>
            {{/if}}
          {{/if}}
        </div>
        <div class="row align-items-center">
          {{#if @page}}
            <h5 class="screen-name col-auto text-gray-900 text-capitalize fw-semibold mb-0">
              {{@page}}
            </h5>
          {{/if}}
          {{#let
            (get (serviceHelper "currentUser") "user")
          as |user|}}
            {{#if @userNotPermitted}}
              {{#if user.isNotUser}}
                <div class="view-only-badge badge rounded-pill text-bg-gray-400 w-auto text-capitalize">
                  {{t 'viewonly'}}
                </div>
              {{else}}
                <div class="no-permission-badge badge rounded-pill text-bg-orange w-auto">
                  {{t 'permissions.configurationLocked'}}
                  <BsTooltip>
                    {{t 'permissions.contactAdmin'}}
                  </BsTooltip>
                </div>
              {{/if}}
            {{/if}}
          {{/let}}
          <div class="col d-flex align-items-center h-100">
            {{yield to="navOptions"}}
          </div>
        </div>
      </div>

    </div>
    <div class="col-auto d-flex align-items-center action-title-strip">
      {{yield}}
    </div>
  </div>
</Portal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
