import { template } from "@ember/template-compiler";
import NavBarMap from 'eflex/util/nav-bar-map';
import Link from 'eflex/components/link';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
import { fn, concat } from '@ember/helper';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { on } from '@ember/modifier';
import { and } from 'ember-truth-helpers';
import BsTooltip from 'eflex/components/bs-tooltip';
import { isPresent } from '@ember/utils';
import { replaceAll } from 'ramda-adjunct';
const getCanNav = (item1, licensing1)=>{
    if (licensing1.noProductsLicensed) {
        return item1.key === 'settings';
    }
    if (item1.lic != null) {
        return licensing1.license?.[`${item1.lic}Enabled`] ?? false;
    } else {
        return true;
    }
};
let NavItem = class NavItem extends Component {
    // eslint-disable-next-line ember/no-tracked-properties-from-args
    @tracked
    expanded = this.args.isExpanded;
    @service
    router;
    @service
    licensing;
    toggleExpanded = (hasChildren1)=>{
        if (!hasChildren1) {
            return;
        }
        this.expanded = !this.expanded;
    };
    static{
        template(`
    {{#let
      (t (concat 'navigation.' @item.label))
      (isPresent @item.children)
    as |label hasChildren|}}
      <li
        class='
          {{if @item.key (replaceAll '.' '-' @item.key)}}
          {{if hasChildren 'has-children'}}
          {{if (and hasChildren this.expanded) 'expanded'}}
        '
      >
        <Link
          class="d-flex align-items-center text-decoration-none fs-4 rounded"
          @route={{unless hasChildren @item.key}}
          @href={{if hasChildren "#" (if @item.href @item.href)}}
          target={{if @item.href "_blank"}}
          rel={{if @item.href "noopener noreferrer"}}
          {{on 'click' (fn this.toggleExpanded hasChildren)}}
        >
          <span class="nav-item-icon fs-5">
            {{#if @item.icon}}
              <div class="fa {{@item.icon}}"></div>
            {{else if @item.svg}}
              <img src='/images/{{@item.svg}}' />
            {{else}}
              <FaIcon @icon={{@item.faIcon}} @prefix="fas"/>
            {{/if}}
          </span>

          {{#if @navBarCollapsed}}
            <BsTooltip @title={{label}} @placement='right' @triggerEvents="hover" />
          {{else}}
            <span class="nav-item-label">{{label}}</span>
          {{/if}}

          {{#if hasChildren}}
            <div
              class="sub-menu-arrow-wrapper d-flex rounded-pill border">
              <FaIcon class="sub-menu-arrow text-gray-600" @icon="chevron-right" @prefix="fas" @size="xs" />
            </div>
          {{/if}}
        </Link>

        {{#if (and hasChildren this.expanded)}}
          <ul class="nav-children mb-2">
            {{#each @item.children as |child|}}
              {{#if (getCanNav child this.licensing)}}
                <NavItem
                  @item={{child}}
                  @navBarCollapsed={{@navBarCollapsed}}
                />
              {{/if}}
            {{/each}}
          </ul>
        {{/if}}
      </li>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
const getIsExpanded = (item1, router1)=>{
    return item1.children?.some((child1)=>router1.isActive(child1.key)) ?? false;
};
export default class NavBar extends Component {
    @service
    router;
    @service
    currentUser;
    @service
    resizeListener;
    @service
    licensing;
    constructor(){
        super(...arguments);
        this.router._router.setupRouter();
    }
    static{
        template(`
    <div
      class="nav-bar hidden-print h-100 overflow-y-auto"
      ...attributes
    >
      <nav
        class="
          eflex-scroll
          bg-white
          border-end
          border-gray-200
          p-2
          position-relative
          h-100
        "
      >
        <ul class="top-links list-unstyled d-flex flex-column h-100 mb-0">
          {{#if this.currentUser.user}}
            {{#each NavBarMap as |nav|}}
              {{#if (getCanNav nav this.licensing)}}
                <NavItem
                  @item={{nav}}
                  @navBarCollapsed={{this.resizeListener.navBarCollapsed}}
                  @isExpanded={{getIsExpanded nav this.router}}
                />
              {{/if}}
            {{/each}}
          {{/if}}

          <li class="mt-auto">
            <Link
              class="text-decoration-none fs-4"
              @href="https://epicweb.epicor.com/products/connected-process-control/documentation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="nav-item-icon fs-5">
                <FaIcon @icon="circle-question" @prefix="far" />
              </span>

              {{#unless this.resizeListener.navBarCollapsed}}
                <span class="nav-item-label">{{t 'support'}}</span>
              {{/unless}}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
