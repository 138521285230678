import { template } from "@ember/template-compiler";
const getIsTesting = ()=>{
    return window.isTesting;
};
export default template(`
{{#unless getIsTesting}}
  <div
    class="component-spinner spinner text-primary"
    ...attributes
  >
    <div class="spinner-border" role="status"></div>
  </div>
{{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
