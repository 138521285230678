/* import __COLOCATED_TEMPLATE__ from './task-device-config.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import RerunStrategies from 'eflex/constants/rerun-strategies';
import RejectStrategies from 'eflex/constants/tasks/reject-strategies';
import RetryStrategies from 'eflex/constants/retry-strategies';
import TaskDeviceConfigBarcode from 'eflex/components/task-device-configuration/barcode';
import TaskDeviceConfigButton from 'eflex/components/task-device-configuration/button';
import TaskDeviceConfigCobot from 'eflex/components/task-device-configuration/cobot';
import TaskDeviceConfigEdhr from 'eflex/components/task-device-configuration/edhr';
import TaskDeviceConfigErrorProofing from 'eflex/components/task-device-configuration/errorproofing';
import TaskDeviceConfigImageCapture from 'eflex/components/task-device-configuration/imagecapture';
import TaskDeviceConfigLightGuide from 'eflex/components/task-device-configuration/lightguide';
import TaskDeviceConfigMultispindle from 'eflex/components/task-device-configuration/multispindle';
import TaskDeviceConfigNodeRed from 'eflex/components/task-device-configuration/nodered';
import TaskDeviceConfigPick from 'eflex/components/task-device-configuration/pick';
import TaskDeviceConfigPrint from 'eflex/components/task-device-configuration/print';
import TaskDeviceConfigSerialNumberTransfer from 'eflex/components/task-device-configuration/serialnumbertransfer';
import TaskDeviceConfigTimer from 'eflex/components/task-device-configuration/timer';
import TaskDeviceConfigTorque from 'eflex/components/task-device-configuration/torque';
import TaskDeviceConfigVision from 'eflex/components/task-device-configuration/vision';
import TaskDeviceConfigPlc from 'eflex/components/task-device-configuration/plc';
import { cached } from '@glimmer/tracking';
import { toTree } from 'eflex/util/tree-helpers';

class TaskDeviceConfig extends Component {
  @service taskRepo;
  @service userRepo;
  @service store;

  get rerunStrategies() {
    return RerunStrategies;
  }

  get rejectStrategies() {
    return [RejectStrategies.advance, RejectStrategies.stop];
  }

  get retryStrategies() {
    return RetryStrategies;
  }

  get taskDeviceConfigComponent() {
    switch (this.args.task.taskType) {
      case 'barcode': {
        return TaskDeviceConfigBarcode;
      }
      case 'button': {
        return TaskDeviceConfigButton;
      }
      case 'cobot': {
        return TaskDeviceConfigCobot;
      }
      case 'edhr': {
        return TaskDeviceConfigEdhr;
      }
      case 'errorProofing': {
        return TaskDeviceConfigErrorProofing;
      }
      case 'imageCapture': {
        return TaskDeviceConfigImageCapture;
      }
      case 'lightGuide': {
        return TaskDeviceConfigLightGuide;
      }
      case 'multispindle': {
        return TaskDeviceConfigMultispindle;
      }
      case 'nodeRed': {
        return TaskDeviceConfigNodeRed;
      }
      case 'pick': {
        return TaskDeviceConfigPick;
      }
      case 'plc': {
        return TaskDeviceConfigPlc;
      }
      case 'print': {
        return TaskDeviceConfigPrint;
      }
      case 'serialNumberTransfer': {
        return TaskDeviceConfigSerialNumberTransfer;
      }
      case 'timer': {
        return TaskDeviceConfigTimer;
      }
      case 'torque': {
        return TaskDeviceConfigTorque;
      }
      case 'vision': {
        return TaskDeviceConfigVision;
      }
      default: {
        return null;
      }
    }
  }

  get repairTaskTree() {
    let parent;

    if (this.args.task.usesOperations) {
      parent = this.args.task.parent;
    } else {
      parent = this.args.task.area;
    }

    return [toTree(parent, {
      lastModel: 'task',
      filter: (task) => {
        if (task.type === 'station' || task.parent !== this.args.taskParent) {
          return true;
        }

        return false;
      },
    })];
  }

  @cached
  get userVisibilityOptions() {
    return [...new Set(this.userRepo.users
      .flatMap(user => user.visibilityTags))]
      .toSorted();
  }

  @cached
  get userAuthorizationOptions() {
    return [...new Set(this.userRepo.users
      .flatMap(user => user.authorizationTags))]
      .toSorted();
  }

  setRerunStrategy = ({ value }) => {
    this.args.task.rerunStrategy = value;
  };

  setRetryStrategy = ({ value }) => {
    this.args.task.retryStrategy = value;
  };

  updateTasksToRepair = (tasksToRepair) => {
    const tasks = tasksToRepair.map(task =>
      this.store.peekRecord('task', task.id),
    );

    this.args.task.tasksToRepair = tasks;
  };
}

export default TaskDeviceConfig;
