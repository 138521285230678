import Base from 'ember-simple-auth/authenticators/base';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';
import { task, timeout } from 'ember-concurrency';
import getDelayTime from 'eflex/util/get-delay-time';
import { getOwner } from '@ember/application';
import { waitFor } from '@ember/test-waiters';
import environment from 'eflex/config/environment';

const config = environment['ember-simple-auth-token'] ?? {};

const ONE_MINUTE = 60_000;

export default class JwtAuthenticator extends Base {
  @service session;
  @service currentUser;

  serverTokenEndpoint = config.serverTokenEndpoint ?? '/api/token-auth/';
  tokenPropertyName = config.tokenPropertyName ?? 'token';
  headers = config.headers ?? {};
  tokenDataPropertyName = config.tokenDataPropertyName ?? 'tokenData';
  refreshAccessTokens = config.refreshAccessTokens !== false;
  tokenExpirationInvalidateSession = config.tokenExpirationInvalidateSession !== false;
  serverTokenRefreshEndpoint = config.serverTokenRefreshEndpoint ?? '/api/token-refresh/';
  refreshTokenPropertyName = config.refreshTokenPropertyName ?? 'refresh_token';
  tokenExpireName = config.tokenExpireName ?? 'exp';
  refreshLeeway = config.refreshLeeway ?? 0;
  tokenRefreshInvalidateSessionResponseCodes = config.tokenRefreshInvalidateSessionResponseCodes ?? [401, 403];
  refreshAccessTokenRetryAttempts = config.refreshAccessTokenRetryAttempts ?? 0;
  refreshAccessTokenRetryTimeout = config.refreshAccessTokenRetryTimeout ?? 1000;
  tokenRefreshFailInvalidateSession = config.tokenRefreshFailInvalidateSession === true;

  async authenticate(credentials, headers) {
    const response = await this.#makeRequest(this.serverTokenEndpoint, credentials, { ...this.headers, ...headers });
    return this.#handleAuthResponse(response.json);
  }

  async refreshAccessToken(refreshToken, attempts) {
    if (window.isTesting) {
      return;
    }

    const data = this.#makeRefreshData(refreshToken);

    try {
      const response = await this.#makeRequest(this.serverTokenRefreshEndpoint, data, this.headers);
      const sessionData = this.#handleAuthResponse(response.json);
      this.trigger('sessionDataUpdated', sessionData);
      return sessionData;
    } catch (error) {
      this.#handleTokenRefreshFail(error.status, refreshToken, attempts);
      throw error;
    }
  }

  async invalidate() {
    await Promise.all([
      this._scheduleRefreshTokenTimeout.cancelAll(),
      this._scheduleTokenExpirationTimeout.cancelAll(),
    ]);
  }

  async restore(data = {}) {
    const token = data[this.tokenPropertyName];

    if (isEmpty(token)) {
      throw new Error('empty token');
    }

    const wait = this._getWaitTime(token);

    if (wait > 0) {
      this._scheduleAccessTokenRefresh(data[this.tokenExpireName], token);
      return data;
    } else {
      await this.session.invalidate();
      throw new Error('token is expired');
    }
  }

  _scheduleRefreshTokenTimeout = task(
    { restartable: true },
    waitFor(async (refreshToken, attempts, refreshAccessTokenRetryTimeout) => {
      await timeout(refreshAccessTokenRetryTimeout);
      await this.refreshAccessToken(refreshToken, attempts);
    }),
  );

  _scheduleTokenExpirationTimeout = task({ restartable: true }, waitFor(async (expiresAt) => {
    const now = Math.floor(Date.now() / 1000);
    const wait = Math.max((expiresAt - now) * 1000, 0);

    if (isEmpty(expiresAt)) {
      return;
    }

    await timeout(wait);
    await this.invalidate();
    this.trigger('sessionDataInvalidated');
  }));

  _handleAutoLogout = task({ restartable: true }, waitFor(async token => {
    const wait = this._getWaitTime(token);

    if (wait <= 0) {
      return;
    }

    const applicationController = getOwner(this).lookup('controller:application');
    applicationController.onCloseLogoutWarningModal();

    await timeout(getDelayTime(wait));

    const showWarning = this.currentUser.user?.timeoutWarning;

    if (showWarning) {
      applicationController.onShowLogoutWarningModal(token);
    }

    if (window.isTesting) {
      return;
    }

    const timeToLogout = showWarning ? ONE_MINUTE : (this.refreshLeeway * 1000);
    await timeout(timeToLogout);
    this.session.invalidate(token);
  }));

  #makeRefreshData(refreshToken) {
    const data = {};
    const nestings = this.refreshTokenPropertyName.split('.');
    const refreshTokenPropertyName = nestings.pop();
    let lastObject = data;

    nestings.forEach(nesting => {
      lastObject[nesting] = {};
      lastObject = lastObject[nesting];
    });

    lastObject[refreshTokenPropertyName] = refreshToken;

    return data;
  }

  _getTokenData(token) {
    const payload = token.split('.')[1];
    const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
    const tokenData = decodeURIComponent(window.escape(decodedPayload));

    try {
      return JSON.parse(tokenData);
    } catch {
      return tokenData;
    }
  }

  async #handleTokenRefreshFail(refreshStatusCode, refreshToken, attempts) {
    if (this.tokenRefreshInvalidateSessionResponseCodes.includes(refreshStatusCode)) {
      await this.invalidate();
      this.trigger('sessionDataInvalidated');
      return;
    }

    if (attempts++ < this.refreshAccessTokenRetryAttempts) {
      this._scheduleRefreshTokenTimeout.perform(refreshToken, attempts, this.refreshAccessTokenRetryTimeout);
      return;
    }

    if (this.tokenRefreshFailInvalidateSession) {
      await this.invalidate();
      this.trigger('sessionDataInvalidated');
    }
  }

  #handleAuthResponse(response) {
    const token = response[this.tokenPropertyName];

    if (isEmpty(token)) {
      throw new Error('Token is empty. Please check your backend response.');
    }

    const tokenData = this._getTokenData(token);
    const expiresAt = tokenData[this.tokenExpireName];
    const tokenExpireData = {};

    tokenExpireData[this.tokenExpireName] = expiresAt;

    if (this.tokenExpirationInvalidateSession) {
      this._scheduleTokenExpirationTimeout.perform(expiresAt);
    }

    if (this.refreshAccessTokens) {
      const refreshToken = response[this.refreshTokenPropertyName];

      if (isEmpty(refreshToken)) {
        throw new Error('Refresh token is empty. Please check your backend response.');
      }

      this._scheduleAccessTokenRefresh(expiresAt, refreshToken);
    }

    return { ...response, ...tokenExpireData, tokenData };
  }

  _getWaitTime(token) {
    const tokenData = this._getTokenData(token);
    const expireTime = moment(tokenData.clientExpiration);
    const timeDiff = expireTime?.diff(moment(), 's');

    return (timeDiff - this.refreshLeeway) * 1000;
  }

  async #makeRequest(url, data, headers) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(data),
    });

    const text = await response.text();

    const res = {
      statusText: response.statusText,
      status: response.status,
      headers: response.headers,
      text,
    };

    try {
      res.json = JSON.parse(text);
    } catch (e) {
      throw new Error(text, { cause: e });
    }

    if (response.ok) {
      return res;
    }

    throw new Error(res.json?.error?.message);
  }

  _scheduleAccessTokenRefresh(expiresAt, refreshToken) {
    if (isEmpty(refreshToken) || isEmpty(expiresAt)) {
      return;
    }

    this._handleAutoLogout.perform(refreshToken);
  }
}
