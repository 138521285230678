import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { isArray } from '@ember/array';
import { task, taskGroup, all } from 'ember-concurrency';
import { forEachObjIndexed } from 'ramda';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import { TrackedArray } from 'tracked-built-ins';
import { PAGE_SIZE } from 'eflex/constants/pagination';
import cleanParams from 'eflex/util/clean-params';

export default class PartsBuildHistoryController extends Controller {
  queryParams = [
    { searchTerm: { as: 'search' } },
    'stationIds',
    'beginDate',
    'endDate',
  ];

  params = {};

  @taskGroup({ drop: true }) loadingTasks;

  @tracked buildHistories = new TrackedArray();
  @tracked buildStatuses = new TrackedArray();
  @tracked images = [];
  @tracked selectedBuildHistory;
  @tracked selectedBuildStatus;
  @tracked jemContainment;
  @tracked selectAll = false;
  @tracked searchTerm;
  @tracked stationIds = [];
  @tracked beginDate;
  @tracked endDate;
  @tracked passed;
  @tracked showFullExportModal = false;

  @service fileDownloader;
  @service store;

  get _baseDimmed() {
    return this.fetchBuildHistoryDetails.isRunning || !this.selectedBuildHistory;
  }

  get dimProcessData() {
    return this.baseDimmed || isEmpty(this.buildStatuses.flatMap(buildStatus => buildStatus.formattedProcessData));
  }

  get dimMarriages() {
    return this.baseDimmed || !this.buildStatuses.some(buildStatus => buildStatus.hasMarriages);
  }

  get jemContainmentStatus() {
    return this.jemContainment?.statusText;
  }

  fetchBuildHistoryDetails = task({ group: 'loadingTasks' }, waitFor(async buildHistory => {
    Object.assign(this, {
      selectedBuildHistory: buildHistory,
      selectedBuildStatus: null,
      buildStatuses: new TrackedArray(),
    });

    const params = cleanParams({
      stationIds: this.stationIds,
      beginDate: this.beginDate,
      endDate: this.endDate,
      passed: this.passed,
      searchTerm: buildHistory?.serialNumber,
      skip: 0,
      take: PAGE_SIZE,
    });

    [this.images] = await all([
      this.store.query('evisionImage', params),
      this.fetchBuildStatuses.perform(params),
    ]);
  }));

  fetchBuildStatuses = task(waitFor(async params => {
    const buildStatuses = await this.store.query('buildStatus', {
      skip: 0,
      ...cleanParams(params),
      take: PAGE_SIZE,
    });

    if (isEmpty(buildStatuses) || !this.selectedBuildHistory) {
      return;
    }

    this.buildStatuses.push(...buildStatuses);

    if (this.selectedBuildStatus != null) {
      return;
    }

    this.selectedBuildStatus = this.buildStatuses[0];
    [this.jemContainment] = await this.store.query('jemContainment', {
      serialNumber: this.selectedBuildStatus.serialNumber,
    });
  }));

  export = task({ group: 'loadingTasks' }, waitFor(async (params = {}) => {
    let queryString = '';

    forEachObjIndexed((val, key) => {
      if (isArray(val)) {
        val.forEach(x => {
          queryString += `${key}[]=${x}&`;
        });
      } else {
        queryString += `${key}=${val}&`;
      }
    }, params);

    if (!this.selectAll) {
      this.buildHistories
        .filter(buildHistory => buildHistory.isSelected)
        .forEach(buildHistory => {
          queryString += `serialNumbers[]=${buildHistory.serialNumber}&`;
        });
    }

    await this.fileDownloader.getFile.perform(
      'buildHistories',
      'text/csv',
      'build-histories.csv',
      queryString,
    );
  }));

  @action
  async handleExport(params) {
    const noneSelected = !this.buildHistories.some(({ isSelected }) => isSelected);

    if (noneSelected) {
      this.params = params;
      this.showFullExportModal = true;
    } else {
      await this.export.perform(params);
    }
  }

  getNextBuildStatusesPage = task({ group: 'loadingTasks' }, waitFor(async pagination => {
    await this.fetchBuildStatuses.perform({
      stationIds: this.stationIds,
      beginDate: this.beginDate,
      endDate: this.endDate,
      passed: this.passed,
      searchTerm: this.selectedBuildHistory?.serialNumber,
      ...pagination,
    });
  }));

  @action
  async performFullExport() {
    await this.export.perform(this.params);
    this.params = {};
    this.showFullExportModal = false;
  }

  @action
  onSelectAll(val) {
    this.selectAll = val;
    this.buildHistories.forEach(buildHistory => {
      buildHistory.isSelected = val;
    });
  }

  @action
  setSelected(buildStatus, isSelected) {
    buildStatus.isSelected = isSelected;

    if (!isSelected) {
      this.selectAll = false;
    }
  }

  @action
  onParamChange(key, value) {
    this[key] = value;
  }
}
