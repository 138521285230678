import { service } from '@ember/service';
import AuthenticatedRoute from 'eflex/util/authenticated-route';
import pProps from 'p-props';
import JobStatuses from 'eflex/constants/kinetic-job-statuses';

class JemStationsRoute extends AuthenticatedRoute {
  @service store;
  @service webCam;
  @service intl;
  @service notifier;
  @service router;
  @service currentUser;
  @service logRepo;
  @service jemRepo;
  @service productionScheduleRepo;

  model({ id: stationId }) {
    const station = stationId != null ? this.store.peekRecord('station', stationId) : null;

    if (!station) {
      if (stationId != null) {
        const error = this.intl.t('errors.locationNotFound', { id: stationId });
        this.notifier.sendError(error);
      }
      this.router.transitionTo('jem');
      return;
    } else if (!this.currentUser.user.hasAuthorization(station.jemAuthorizedTags) && this.currentUser.user.isUser) {
      localStorage.removeItem('jemSelectedTreeItem');
      this.router.transitionTo('jem');
      return;
    }

    return pProps({
      webCams: this.webCam.load.perform(),
      station,
      jemNotes: this.store.query('jemNote', { stationId: station.id }),
      logs: this.logRepo.fetchLogsForJem.perform({ stationId: station.id }),
      liveBuildStatuses: this.store.query('liveBuildStatus', { station: station.id, limit: 1 }),
      productionSchedule: this.productionScheduleRepo.getCurrent(station),
    });
  }

  afterModel({ liveBuildStatuses, station }) {
    const promises = {};

    if (!station.usesModels && !station.usesOperations) {
      promises.componentTaskConfigs = this.jemRepo.loadTaskConfigs.perform(station);
    }

    if (station.usesOperations) {
      promises.jobs = this.store.query('kineticJob', { jobStatus: JobStatuses.released });
    }

    const liveBuildStatus = liveBuildStatuses[0];
    if (liveBuildStatus) {
      promises.runningTaskConfigs = this.jemRepo.loadTaskConfigs.perform(
        station,
        liveBuildStatus.kineticOperation ?? liveBuildStatus.modelRecord,
      );
    }

    return pProps(promises);
  }

  setupController(controller, { liveBuildStatuses, station, logs }) {
    const liveBuildStatus = liveBuildStatuses[0];

    Object.assign(controller, {
      station,
      totalLogs: logs.meta?.count ?? 0,
      buildStatus: liveBuildStatus,
    });

    controller.setInstructionTab();
  }

  resetController(controller, isExiting, transition) {
    Object.assign(controller, {
      partContained: false,
      prereqsNotMet: false,
      tempSerialNumber: '',
      bomSourceLookupValue: null,
      selectedJob: null,
      selectedAssembly: null,
      kineticAssemblyOperation: null,
      currentTask: null,
      model: null,
    });

    if (isExiting) {
      if (transition.to.name !== 'oee.station') {
        this.store.unloadAll('oeeState');
      }
      controller.clearBuildStatus();
    }
  }
}

export default JemStationsRoute;
