import Controller from '@ember/controller';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import eflexFeatures from 'eflex/constants/eflex-features';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';

export default class SettingsLicensingController extends Controller {
  @tracked confirmDisabled = true;
  @tracked licenseFilename;
  @tracked licenseText;

  eflexFeatures = eflexFeatures;

  @service eflexAjax;
  @service notifier;
  @service licensing;
  @service currentUser;

  get license() {
    return this.licensing.license;
  }

  uploadLicense = task(waitFor(async () => {
    try {
      await this.eflexAjax.post.perform('licenses', { licenseText: this.licenseText });
      this.notifier.sendSuccess('licensing.licenseUploaded');
      await this.licensing.loadLicense.perform();
    } catch (error) {
      this.notifier.sendError(error);
    } finally {
      this.removeUpload();
    }
  }));

  selectedLicenseFile = task(waitFor(async files => {
    if (files == null || files[0] == null) {
      return;
    }

    const licenseFile = files[0];

    if (licenseFile.type !== 'text/plain') {
      this.notifier.sendError('logs.licensing.invalid.license');
      Object.assign(this, {
        licenseFilename: null,
        confirmDisabled: true,
      });
      return;
    }

    Object.assign(this, {
      confirmDisabled: false,
      licenseFilename: licenseFile.name,
      licenseText: await licenseFile.text(),
    });
  }));

  @action
  removeUpload() {
    Object.assign(this, {
      licenseFilename: null,
      licenseText: null,
      confirmDisabled: true,
    });
  }
}
