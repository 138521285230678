import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { waitFor } from '@ember/test-waiters';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';
import { t } from 'ember-intl';
import { fn } from '@ember/helper';
import StandardModal from 'eflex/components/standard-modal';
import BsForm from 'eflex/components/bs-form';
import onKey from 'ember-keyboard/modifiers/on-key';
import { on } from '@ember/modifier';
import BsButton from 'eflex/components/bs-button';
import { not } from 'ember-truth-helpers';
let KineticEmployeeModal = @buildValidations({
    employeeId: [
        validator('inline', {
            validate (employeeId1, options1, modal1) {
                if (modal1.isInvalid) {
                    return modal1.intl.t('kinetic.invalidEmployee');
                }
                return true;
            }
        })
    ]
})
class KineticEmployeeModal extends Component {
    @service
    kineticShiftRepo;
    @service
    kineticEmployeeRepo;
    @service
    intl;
    @tracked
    employeeId;
    @tracked
    employee;
    @tracked
    shift;
    @tracked
    isInvalid = false;
    get kineticShifts() {
        return this.kineticShiftRepo.kineticShifts;
    }
    get loginDisabled() {
        return !this.employee || !this.shift;
    }
    get shiftDisabled() {
        return !this.employee || this.employee.currentShift;
    }
    setEmployee = task(waitFor(async ()=>{
        this.employee = await this.kineticEmployeeRepo.getEmployee.perform(this.employeeId);
        if (!this.employee) {
            this.isInvalid = true;
            this.shift = null;
            return;
        }
        this.shift = this.employee.currentShift ?? this.employee.defaultShift;
    }));
    static{
        template(`
    <StandardModal
      @onCancel={{@onCancel}}
      @onConfirm={{fn @onConfirm this.employee this.shift}}
      @size="sm"
      class="employee-login-modal"

    as |modal|>
      <modal.header @text={{t "controls.login.login"}} />
      <modal.body>
        <BsForm
          @model={{this}}
        as |form|>
          <div class="row">
            <div class="col">
              <form.element
                @controlType="text"
                @property="employeeId"
                @label={{t "kinetic.employeeId"}}
                class="employee-id"
                {{onKey "Enter" this.setEmployee.perform}}
                {{on "focusin" (fn (mut this.isInvalid) false)}}
              />
            </div>
            <div class="col-auto align-self-end">
              <BsButton
                @onClick={{this.setEmployee.perform}}
                @disabled={{not this.employeeId}}
                class="submit-id"
              >
                {{t "submit"}}
              </BsButton>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <form.element
                @controlType="text"
                @property="employee.name"
                @label={{t "name"}}
                class="employee-name"
              as |el|>
                <el.control @disabled={{true}}/>
              </form.element>
            </div>
            <div class="col">
              <form.element
                @controlType="power-select"
                @label={{t "kinetic.shiftId"}}
                @options={{this.kineticShifts}}
                @optionLabelPath="displayName"
                @value={{this.shift}}
                @onChange={{fn (mut this.shift)}}
                class="shift-select"
              as |el|>
                <el.control
                  @disabled={{this.shiftDisabled}}
                  @placeholder={{t "kinetic.selectShift"}}
                />
              </form.element>
            </div>
          </div>
        </BsForm>

      </modal.body>
      <modal.footer as |footer|>
        <footer.confirm
          @disabled={{this.loginDisabled}}
        >
          {{t "ok"}}
        </footer.confirm>
        <footer.close />
      </modal.footer>
    </StandardModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default KineticEmployeeModal;
