import { template } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import Component from '@glimmer/component';
let JemTaskPlcDetails = class JemTaskPlcDetails extends Component {
    get vars() {
        return this.args.task.variableDefs.filter((item1)=>item1.isFromDevice).map((def1)=>({
                name: def1.name,
                value: this.args.childStatus?.processData?.find((item1)=>item1.name === def1.name)?.value
            }));
    }
    static{
        template(`
    <div class="jem-process-data" ...attributes>
      <div class="mt-2">
        {{#each this.vars as |variable|}}
          <div class={{@childStatus.taskStatusClass}}>
            <div class="process-data-def-name fs-4 fw-semibold text-gray-800">
              {{variable.name}}
            </div>
            <div class="process-data-def-value detail-text-value fw-bold text-gray-900">
              {{variable.value}}
            </div>
          </div>
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default template(`
{{yield
  (hash
    taskDetails=(component
      JemTaskPlcDetails
      task=@task
      childStatus=@childStatus
    )
  )
}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
