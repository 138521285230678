import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import TaskStatuses from 'eflex/constants/task-statuses';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import echartsTheme from 'eflex/echarts-theme';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import EChart from 'eflex/components/e-chart';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
import Spinner from 'eflex/components/spinner';
export default class RejectCodesByStation extends Component {
    // jscpd:ignore-start
    @service
    queryRunner;
    @service
    intl;
    get params() {
        return {
            stationIds: this.args.stationIds,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const rejectOccurrenceQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $match: {
                    status: TaskStatuses.REJECTED
                }
            },
            {
                $unwind: {
                    path: '$rejectCodes',
                    preserveNullAndEmptyArrays: true
                }
            },
            {
                $unwind: {
                    path: '$rejectCodes.codes',
                    preserveNullAndEmptyArrays: true
                }
            },
            {
                $group: {
                    _id: null,
                    docs: {
                        $push: '$$ROOT'
                    },
                    distinctCodes: {
                        $addToSet: {
                            $ifNull: [
                                '$rejectCodes.codes.code',
                                'other'
                            ]
                        }
                    }
                }
            },
            {
                $unwind: {
                    path: '$docs'
                }
            },
            {
                $addFields: {
                    'docs.distinctCodes': '$distinctCodes'
                }
            },
            {
                $replaceRoot: {
                    newRoot: '$docs'
                }
            },
            {
                $group: {
                    _id: {
                        stationId: '$location._id',
                        stationName: {
                            $first: '$location.captions.text'
                        },
                        distinctCodes: '$distinctCodes',
                        code: {
                            $ifNull: [
                                '$rejectCodes.codes.code',
                                'other'
                            ]
                        }
                    },
                    count: {
                        $sum: 1
                    }
                }
            },
            {
                $group: {
                    _id: {
                        stationId: '$_id.stationId',
                        stationName: '$_id.stationName',
                        distinctCodes: '$_id.distinctCodes'
                    },
                    codes: {
                        $push: {
                            code: '$_id.code',
                            count: '$count'
                        }
                    }
                }
            },
            {
                $addFields: {
                    missingCodes: {
                        $map: {
                            input: '$_id.distinctCodes',
                            in: {
                                $cond: {
                                    if: {
                                        $in: [
                                            '$$this',
                                            '$codes.code'
                                        ]
                                    },
                                    then: '$$REMOVE',
                                    else: {
                                        code: '$$this',
                                        count: 0
                                    }
                                }
                            }
                        }
                    }
                }
            },
            {
                $addFields: {
                    allCodeCounts: {
                        $concatArrays: [
                            '$codes',
                            '$missingCodes'
                        ]
                    }
                }
            },
            {
                $addFields: {
                    allCodeCounts: {
                        $filter: {
                            input: '$allCodeCounts',
                            cond: {
                                $ne: [
                                    '$$this',
                                    null
                                ]
                            }
                        }
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    station: '$_id.stationName',
                    totalRejects: {
                        $sum: {
                            $map: {
                                input: '$allCodeCounts',
                                in: '$$this.count'
                            }
                        }
                    },
                    distinctCodes: {
                        $sortArray: {
                            input: '$_id.distinctCodes',
                            sortBy: 1
                        }
                    },
                    allCodeCounts: {
                        $sortArray: {
                            input: '$allCodeCounts',
                            sortBy: {
                                code: 1
                            }
                        }
                    }
                }
            },
            {
                $project: {
                    station: 1,
                    totalRejects: 1,
                    distinctCodes: 1,
                    allCodeCounts: {
                        $map: {
                            input: '$allCodeCounts',
                            in: '$$this.count'
                        }
                    }
                }
            },
            {
                $sort: {
                    totalRejects: 1
                }
            }
        ]);
        const rejectOccurrenceData1 = await this.queryRunner.runQuery.perform('BuildStatuses', rejectOccurrenceQuery1);
        return {
            chartData: this.getEchartOptions(rejectOccurrenceData1),
            exportData: ()=>this.formatExportData(clone(rejectOccurrenceData1))
        };
    // jscpd:ignore-end
    }));
    rejectOccurrenceData = trackedTask(this, this.getData, ()=>[
            this.params
        ]);
    getEchartOptions(formattedData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.rejectCodeOccurrencesStation')
            },
            legend: {
                show: true,
                bottom: 0
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params1)=>{
                    let result1 = '';
                    params1.forEach(({ marker: marker1, seriesName: seriesName1, value: value1, seriesIndex: seriesIndex1 })=>{
                        const itemValue1 = value1[seriesIndex1 + 1];
                        if (itemValue1 && itemValue1 !== 0) {
                            result1 += `<div>${marker1} ${seriesName1}<span style="padding-right: 10px;">:</span>
              <span style="float:right;font-weight:bold;">${itemValue1}</span></div>`;
                        }
                    });
                    return `<div>${params1[0].name}</div>${result1}`;
                }
            },
            dataset: this.formatDataSet(formattedData1),
            yAxis: {
                type: 'category',
                boundaryGap: true,
                interval: 1
            },
            xAxis: {},
            series: this.formatSeriesData(formattedData1)
        };
    }
    formatDataSet = (rejectOccurrenceData1)=>{
        const headers1 = [
            'station'
        ].concat(rejectOccurrenceData1?.[0]?.distinctCodes);
        const source1 = [
            headers1
        ];
        rejectOccurrenceData1.forEach((station1)=>{
            source1.push([
                station1.station
            ].concat(station1.allCodeCounts));
        });
        return {
            source: source1
        };
    };
    formatSeriesData = (rejectOccurrenceData1)=>{
        const seriesData1 = [];
        rejectOccurrenceData1?.[0]?.distinctCodes?.forEach((code1, index1)=>{
            seriesData1.push({
                name: code1,
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: (params1)=>{
                        return params1?.value[params1.encode.x[0]] !== 0 ? params1?.value[params1.encode.x[0]] : '';
                    }
                },
                color: echartsTheme.excessBiColors[index1]
            });
        });
        return seriesData1;
    };
    formatExportData = (rejectOccurrenceData1)=>{
        rejectOccurrenceData1?.forEach((station1)=>{
            station1?.distinctCodes?.forEach((code1, index1)=>{
                station1[code1] = station1.allCodeCounts[index1];
            });
            delete station1.allCodeCounts;
            delete station1.distinctCodes;
        });
        return rejectOccurrenceData1;
    };
    static{
        template(`
    <div ...attributes>
      {{#if this.rejectOccurrenceData.isRunning}}
        <Spinner />
      {{else if this.rejectOccurrenceData.value.chartData.series.length}}
        <div class="row h-100">
          <div class="col d-flex justify-content-between">
            <EChart
              @option={{this.rejectOccurrenceData.value.chartData}}
              @exportData={{this.rejectOccurrenceData.value.exportData}}
              class="h-100 w-100 rejects-station"
             />
          </div>
        </div>
      {{else}}
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
