import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { task as trackedTask } from 'reactiveweb/ember-concurrency';
import { tracked } from '@glimmer/tracking';
import TaskAndFastenerMatcher from 'eflex/util/bi-task-fastener-matcher';
import EchartsTheme from 'eflex/echarts-theme';
import BiFormatTaskAndBoltAxis from 'eflex/util/bi-task-anb-bolt-axis-formatter';
import { waitFor } from '@ember/test-waiters';
import { clone } from 'ramda';
import Spinner from 'eflex/components/spinner';
import { fn, get } from '@ember/helper';
import PowerSelect from 'eflex/components/power-select';
import EChart from 'eflex/components/e-chart';
import { t } from 'ember-intl';
import BiNoDataPlaceholder from 'eflex/components/bi/no-data-placeholder';
export default class AverageTorque extends Component {
    // jscpd:ignore-start
    @service
    router;
    @service
    queryRunner;
    @service
    intl;
    @tracked
    boltStatus = this.intl.t('bi.chartLabel.allBolts');
    @tracked
    boltStatuses = [
        this.intl.t('bi.chartLabel.passedBolts'),
        this.intl.t('bi.chartLabel.failedBolts'),
        this.intl.t('bi.chartLabel.allBolts')
    ];
    get params() {
        return {
            stationIds: this.args.stationIds,
            fasteners: this.args.fasteners,
            modelIds: this.args.modelIds,
            userIds: this.args.userIds,
            tags: this.args.tags,
            startDate: this.args.startDate,
            endDate: this.args.endDate,
            shiftNames: this.args.shiftNames
        };
    }
    getTorqueData = task({
        drop: true
    }, waitFor(async (params1)=>{
        const torqueDataChartQuery1 = await this.queryRunner.queryWithParams.perform(params1, [
            {
                $limit: 100_000
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $match: {
                    'children.boltProcessData.0': {
                        $exists: true
                    }
                }
            },
            {
                $unwind: {
                    path: '$children'
                }
            },
            {
                $unwind: {
                    path: '$children.boltProcessData'
                }
            },
            this.getBoltStatusMatch(this.boltStatus),
            {
                $group: {
                    _id: {
                        taskId: '$children.location._id',
                        taskName: {
                            $first: '$children.location.captions.text'
                        },
                        boltNumber: '$children.boltProcessData.boltNumber'
                    },
                    avgTorque: {
                        $avg: '$children.boltProcessData.torque'
                    },
                    avgAngle: {
                        $avg: '$children.boltProcessData.angle'
                    }
                }
            },
            {
                $project: {
                    _id: 0,
                    taskId: '$_id.taskId',
                    taskAndBolt: {
                        $concat: [
                            '$_id.taskName',
                            ' ',
                            this.intl.t('fastener'),
                            ' ',
                            {
                                $toString: '$_id.boltNumber'
                            }
                        ]
                    },
                    idAndFastener: {
                        $concat: [
                            {
                                $toString: '$_id.taskId'
                            },
                            {
                                $toString: '$_id.boltNumber'
                            }
                        ]
                    },
                    avgTorque: 1,
                    avgAngle: 1
                }
            },
            TaskAndFastenerMatcher(params1.fasteners),
            {
                $sort: {
                    taskAndBolt: -1
                }
            }
        ]);
        const torqueData1 = await this.queryRunner.runQuery.perform('BuildStatuses', torqueDataChartQuery1);
        return {
            chartData: this.getEchartOptions(torqueData1),
            exportData: ()=>clone(torqueData1).map(({ idAndFastener: idAndFastener1, ...relevantData1 })=>relevantData1)
        };
    }));
    torqueData = trackedTask(this, this.getTorqueData, ()=>[
            this.params,
            this.boltStatus
        ]);
    getEchartOptions(torqueData1) {
        return {
            title: {
                text: this.intl.t('bi.chartLabel.boltAngleAvg')
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                show: true,
                bottom: 5
            },
            yAxis: BiFormatTaskAndBoltAxis(torqueData1),
            xAxis: [
                {
                    type: 'value',
                    data: torqueData1.map((item1)=>item1.avgTorque),
                    name: this.intl.t('averageTorque'),
                    nameTextStyle: {
                        padding: 10
                    },
                    nameLocation: 'center'
                },
                {
                    type: 'value',
                    data: torqueData1.map((item1)=>item1.avgAnlge),
                    name: this.intl.t('averageAngle'),
                    nameTextStyle: {
                        padding: 10
                    },
                    nameLocation: 'center',
                    axisLabel: {
                        formatter: '{value}°'
                    }
                }
            ],
            series: [
                {
                    name: this.intl.t('averageTorque'),
                    type: 'bar',
                    xAxisIndex: 0,
                    data: torqueData1.map((m1)=>{
                        return m1.avgTorque?.toFixed(2).replace(/\.00/, '') ?? 0;
                    }),
                    color: EchartsTheme.colorPalette.warning
                },
                {
                    name: this.intl.t('averageAngle'),
                    type: 'line',
                    xAxisIndex: 1,
                    data: torqueData1.map((m1)=>{
                        return m1.avgAngle?.toFixed(2).replace(/\.00/, '') ?? 0;
                    }),
                    color: EchartsTheme.colorPalette.success
                }
            ],
            taskIds: torqueData1.map((item1)=>item1.taskId),
            names: torqueData1.map((item1)=>item1.taskAndBolt),
            idAndFasteners: torqueData1.map((item1)=>item1.idAndFastener)
        };
    }
    // jscpd:ignore-end
    getBoltStatusMatch(boltStatus1) {
        if (boltStatus1 === this.intl.t('bi.chartLabel.allBolts')) {
            return {
                $match: {}
            };
        } else if (boltStatus1 === this.intl.t('bi.chartLabel.passedBolts')) {
            return {
                $match: {
                    'children.boltProcessData.torqueStatus': 1,
                    'children.boltProcessData.angleStatus': 1,
                    'children.boltProcessData.errorCode': null
                }
            };
        } else {
            return {
                $match: {
                    $expr: {
                        $or: [
                            {
                                $ne: [
                                    '$children.boltProcessData.torqueStatus',
                                    1
                                ]
                            },
                            {
                                $ne: [
                                    '$children.boltProcessData.angleStatus',
                                    1
                                ]
                            },
                            {
                                $ne: [
                                    '$children.boltProcessData.errorCode',
                                    null
                                ]
                            }
                        ]
                    }
                }
            };
        }
    }
    fastenerDrillDown = (individualTorqueData1, dataIndex1)=>{
        this.router.transitionTo('bi.torqueData.singleBoltsChart', {
            queryParams: {
                stationIds: this.args.stationIds,
                fasteners: [
                    {
                        _id: individualTorqueData1.taskIds[dataIndex1],
                        name: individualTorqueData1.names[dataIndex1],
                        idAndFastener: individualTorqueData1.idAndFasteners[dataIndex1]
                    }
                ],
                modelIds: this.args.modelIds,
                userIds: this.args.userIds,
                tags: this.args.tags,
                beginDate: this.args.startDate,
                endDate: this.args.endDate,
                shiftNames: this.args.shiftNames
            }
        });
    };
    static{
        template(`
    {{!-- template-lint-disable no-passed-in-event-handlers --}}
    <div class="component-bi-chart-with-dropdown" ...attributes>
      {{#if this.torqueData.isRunning}}
        <Spinner />
      {{else if (get this.torqueData.value.chartData.series '0.data.length')}}
        <div class="d-flex flex-row-reverse">
          <div class="component-bi-dropdown">
            <PowerSelect
              @options={{this.boltStatuses}}
              @selected={{this.boltStatus}}
              @onChange={{fn (mut this.boltStatus)}}
              as |level|
            >
              {{level}}
            </PowerSelect>
          </div>
        </div>
        <div class="d-flex flex-row h-100 chart-row">
          <div class="col d-flex justify-content-between">
            <EChart
              @option={{this.torqueData.value.chartData}}
              @exportData={{this.torqueData.value.exportData}}
              @exportFilename={{t 'averageTorque'}}
              class="h-100 w-100 avg-torque-angle-chart"
              @onClick={{fn this.fastenerDrillDown this.torqueData.value.chartData}}
            />
          </div>
        </div>
      {{else}}
        <div class="d-flex flex-row-reverse">
          <div class="component-bi-dropdown">
            <PowerSelect
              @options={{this.boltStatuses}}
              @selected={{this.boltStatus}}
              @onChange={{fn (mut this.boltStatus)}}
              as |level|
            >
              {{level}}
            </PowerSelect>
          </div>
        </div>
        <BiNoDataPlaceholder />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
